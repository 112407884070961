import React, { useContext, useState } from 'react';
import { Form, message } from 'antd';
import FetchContext from "src/services/FetchService/FetchContext";
import CreatableSelect from "src/components/shared/CreatableSelect";
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

const TaxPayerItem = ({ currentNotice, taxAuthority, onCreate }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        entitymanagement: { postAddEntity }
    } = useContext(FetchContext);

    const handleCreate = async inputValue => {
        const data = { entity: inputValue };
        setIsSubmitting(true);
    
        try {
            await postAddEntity(data);
            message.success("The entity has been added to the database");
            // addEntityState(data);
            // This is TEMP fix (should update global state ContextProvider, global state should be refactored )

            onCreate(inputValue);
        } catch (err) {
            message.error("Error creating new entity, plase try again");
        }
    
        setIsSubmitting(false);
    };

    return (
        <Form.Item
            label="Taxpayer"
            name="entity"
            key="entity"
            initialValue={currentNotice.entity}
            className="mt-4 taxpayer-form-item"
        >
            <CreatableSelect
                options={useContext(GlobalStateContext).entities}
                taxauthority={taxAuthority}
                loading={isSubmitting}
                onCreate={handleCreate}
            ></CreatableSelect>
        </Form.Item>
    );
};

export default TaxPayerItem;
