import { API } from "aws-amplify";

export const getUsers = () => {
	const apiName = "stream";
    const path = `/admin_get_user_lists`;
    const myInit = {
      headers: {}
    };
	return API.get(apiName, path, myInit);
}

export const deleteUser = (username) => {
	const apiName = "stream";
	const path = `/admin_delete_user`;
	const myInit = {
		headers: {},
		body: {
			Username:username
		  }
	};
	return API.del(apiName, path, myInit)
}

export const updateUser = async (username, name, access) => {
	const apiName = "stream";
	const path = `/admin_update_user`;
	const myInit = {
		headers: {},
		body: {
			Username:username,
			Attributes:[
				{
					"Name": "custom:access",
					"Value": access
				},
				{
					"Name": "name",
					"Value": name
				},
			]
		  }
	};
	return await API.put(apiName, path, myInit);
}
