/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:dd445225-cd28-4480-a697-7014b32d619e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_uZJaasDlx",
    "aws_user_pools_web_client_id": "25uihvean2rsr51n5d76l14eu9",
    "oauth": {
        "domain": "taxology-stream.auth.eu-west-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "uploadbucketstream150510-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
