/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useContext, useEffect, useState } from 'react';
import Add from './Add';
import Show from './Show';
import FetchContext from 'src/services/FetchService/FetchContext';
import { constructCommentData } from 'src/utils/NoticeManagement';

const Remarks = ({ noticeId }) => {
    const [comments, updateComments] = useState([]);

    const { comments: { getComments } } = useContext(FetchContext);

    useEffect(() => {
        const getData = async () => {
            try {
                const results = await getComments(noticeId);
                updateComments(constructCommentData(results));
            } catch (e) {
                console.log({ e });
            }
        };
        getData();
    }, []);

    return (
        <div className="flex w-full flex-col items-start">
            <Add noticeId={noticeId} updateComments={updateComments} />
            <Show noticeId={noticeId} comments={comments} updateComments={updateComments} />
        </div>
    );
};
export default Remarks;
