import React, { useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { useHistory, Link } from "react-router-dom";
import { AuthContext } from "src/services/AuthService/Authentication";
import Paper from "src/components/shared/Paper";
import Logo from "src/components/shared/logo";
import MicrosoftLogo from "src/components/shared/Microsoftlogo";
import { Auth } from "aws-amplify";

const LoginUnplugged = ({ onLogin, onSingleSignOn }) => {
  const [passwordErrorText, setPasswordErrorText] = useState(null);
  const [loading, setLoading] = useState(false);

  const evaluateAdmin = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    return userInfo.attributes["custom:access"];
  };

  const history = useHistory();

  const signIn = (values) => {
    setLoading(true);
    onLogin(values)
      .then(async () => {
        (await evaluateAdmin()) === "SuperAdmin"
          ? history.push("/super-admin")
          : history.push("/noticemanagement");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setPasswordErrorText(err.message);
      });
  };

  const singleSignOn = () => {
    console.log("begin singleSignOn...");
    setLoading(true);
    onSingleSignOn()
      .then((res) => {
        console.log("pushing to noticemanagement...");
        history.push("/noticemanagement");
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
        setPasswordErrorText(err.message);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row align="middle" justify="center" style={{ height: "100vh" }}>
      <Col xs={20} md={20} xl={14} xxl={10}>
        <Paper>
          <Row gutter={15}>
            <Col md={12} xs={24} className="logo-stream">
              <Row align="middle" justify="center" className="h-full">
                <Logo width={163} />
              </Row>
            </Col>
            <Col md={12} xs={24}>
              <Row justify="center" className="mt-6">
                <Button
                  type="primary"
                  size="large"
                  onClick={singleSignOn}
                  className="button-sso"
                >
                  <MicrosoftLogo width={20} /> Continue With Microsoft
                </Button>
              </Row>
              <Row justify="center" className="mt-6 divider">
                <div className="divider-line" />
                <div className="divider-text">OR</div>
              </Row>
              <Form
                name="loginForm"
                layout="vertical"
                hideRequiredMark
                onFinish={signIn}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Email"
                  name="username"
                  rules={[
                    { required: true, message: "Please provide your email!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                  help={passwordErrorText}
                >
                  <Input.Password />
                </Form.Item>

                <Row justify="space-between" align="middle" style={{rowGap: 12}}>
                  <Col xs={{span: 24, order: 2}} md={{span: 14, order: 1}}>
                    <Link to="forgotpassword" className="text-primary">
                      Forgot Password?
                    </Link>
                  </Col>
                  <Col xs={{span: 24, order: 1, }} md={{span:10, order: 2}}>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={loading}
                      style={{ width: '100%'}}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Paper>
      </Col>
    </Row>
  );
};

export default () => (
  <AuthContext.Consumer>
    {({ login, ssoLogin }) => (
      <LoginUnplugged onLogin={login} onSingleSignOn={ssoLogin} />
    )}
  </AuthContext.Consumer>
);
