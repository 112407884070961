import React, { useState, useContext } from "react";
import { GlobalStateContext } from "src/services/GlobalState/GlobalState";
import { Typography, Form, message } from "antd";
import AppSelect from "../../../../components/shared/AppSelect";
import CreatableSelect from "src/components/shared/CreatableSelect";

import FetchContext from "src/services/FetchService/FetchContext";

const { Title } = Typography;

const AddEntityItem2 = ({ currentNotice, onCreate }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    entitymanagement: { postAddEntity }
  } = useContext(FetchContext);

  const { addEntityState } = useContext(GlobalStateContext);

  const handleCreate = async inputValue => {
    const data = { entity: inputValue };
    setIsSubmitting(true);

    try {
      await postAddEntity(data);
      message.success("The entity has been added to the database");
      // addEntityState(data);
      // This is TEMP fix (should update global state ContextProvider, global state should be refactored )

      onCreate(inputValue);
    } catch (err) {
      message.error("Error creating new entity, plase try again");
    }

    setIsSubmitting(false);
  };

  return (
    <div className="flex items-center">
      <div className="w-4/12">
        <Title level={5}>Taxpayer</Title>
      </div>
      <div className="w-8/12">
        <Form.Item
          name="entity"
          key="entity"
          initialValue={currentNotice.entity.entity_options[0].matched_entity}
        >
          <CreatableSelect
            options={useContext(GlobalStateContext).entities}
            taxauthority={currentNotice.taxauthority}
            loading={isSubmitting}
            onCreate={handleCreate}
          ></CreatableSelect>
        </Form.Item>
      </div>
    </div>
  );
};

export default AddEntityItem2;
