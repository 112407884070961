import { API } from 'aws-amplify';
import { axiosGet, axiosPost } from './utils';
import { getApiUrl } from 'src/utils/Helper';

const API_ENDPOINT =  getApiUrl();
export const getPermission = (authContext) => () => {
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosGet(`${API_ENDPOINT}/permission/`, config);
};
