import React, { useState } from 'react';
import {
    Modal, Button, Input, Rate, notification, Form, Tooltip, Select
} from 'antd';
import { Auth, API } from 'aws-amplify';
import { MessageOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const { Option } = Select;

const Feedback = () => {
    const [form] = Form.useForm();

    const [username, setUsername] = useState('None');
    const evaluateUser = async () => {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUsername(userInfo.attributes.name);
    };
    evaluateUser();

    const message = `Hi ${username}!`;

    const [feedbackvisible, setFeedbackvisible] = useState(false);

    const showFeedback = () => {
        setFeedbackvisible(true);
    };

    const submitFeedback = (values) => {
        console.log(values);
        values.user = username;

        setFeedbackvisible(false);
        notification.open({
            message: 'Thank you for thinking with us!',
            description: 'We highly appreciate your feedback. We aim to respond to your comments or questions within a few business days.',
            duration: 10,
        });
        const apiName = 'stream';
        const path = '/submit_feedback';
        const myInit = {
            body: JSON.stringify(values),
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods': 'POST',
            },
        };
        API
            .post(apiName, path, myInit)
            .then((response) => {
                console.log(response, 'success');
            })
            .catch((error) => {
                console.log(error.response);
            });
    };
    return (
        <div>
            <Tooltip
                title="Tell us what you think!"
                placement="left"
            >
                <Button
                    onClick={showFeedback}
                    icon={<MessageOutlined />}
                    shape="round"
                    size="large"
                />
            </Tooltip>

            <Modal
                title={message}
                visible={feedbackvisible}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            submitFeedback(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={() => setFeedbackvisible(false)}
                okText="Submit feedback"
            >
                Ask us anything, or share your feedback.

                <Form
                    form={form}
                    name="submit Feedback"
                    style={{ marginTop: '1em' }}
                >
                    <Form.Item name="topic">
                        <Select>
                            <Option value="noticemanagement"> Notice management </Option>
                            <Option value="import"> Import </Option>
                            <Option value="backlog"> Backlog </Option>
                            <Option value="admin"> Admin </Option>
                        </Select>
                    </Form.Item>
                    Rate this feature
                    <br />
                    <Form.Item
                        name="rate"
                    >
                        <Rate />
                    </Form.Item>

                    <Form.Item
                        name="text"
                    >
                        <TextArea
                            placeholder="Your feedback"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Feedback;
