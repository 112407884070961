import React, { useState, useEffect, useRef } from 'react'
import { Typography } from 'antd';
import UserMenu from './UserMenu';
import AngleDown from 'src/components/shared/AngleDown.jsx';

const { Text } = Typography;

const User = ({ username, customerName }) => {
  const [expanded, setExpanded] = useState(false);

  const clickHandler = (e) => {
    e.preventDefault()
    setExpanded(!expanded)
  }

  let menuRef = useRef();

  // to close UserMenu when clicked outside component
  useEffect(() => {
    let closeHandler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", closeHandler);
    return () => {
      document.removeEventListener("mousedown", closeHandler);
    }
  }, []);

  return (
    <div
      className='pl-6 flex flex-row justify-between items-center cursor-pointer leading-5'
      onClick={clickHandler}
      ref={menuRef}
    >
      <div className='flex flex-col min-width-user-menu'>
        <div className="flex justify-between">
          <div>
            <Text strong className="text-sm text-black mr-3" >
              {username}
            </ Text>
          </div>
          <div>
            <AngleDown />
          </div>
        </div>
        <div className="customer">
          <Text className="text-sm text-black">
            {customerName}
          </Text>
        </div>
      </div>
      <UserMenu expanded={expanded} setExpanded={setExpanded} />
    </div >
  )
}


export default User
