import React from 'react';
import { Row, Form, Col, Button } from 'antd';
import StateItem from './StateItem';
import StateIDItem from './StateIDItem';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

const StateItems = () => {
    return (
        <>
            <Form.List name="states">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, isListField }) => (
                            <Row gutter={15} key={key} align="bottom">
                                <StateItem 
                                    name={[name, 'state']}
                                    fieldKey={[fieldKey, 'state']}
                                    isListField={isListField} 
                                />
                                <StateIDItem
                                    name={[name, 'stateid']}
                                    fieldKey={[fieldKey, 'stateid']}
                                    isListField={isListField}
                                    withRemoveButton={fieldKey > 0 ? true : false}
                                />
                                {fieldKey !== 0 ? (
                                    <Col span={1}>
                                        <MinusCircleOutlined className="pb-3" onClick={() => remove(name)} />
                                    </Col>
                                ) : null}
                            </Row>
                        ))}
                        <Row justify="end" className={fields.length ? '' : 'mt-6'}>
                            <Form.Item className="mb-0">
                                <Button type="link" onClick={() => add()}>Add State <PlusCircleOutlined /></Button>
                            </Form.Item>
                        </Row>
                    </>
                )}
            </Form.List>
        </>
    );
};

export default StateItems;
