import React, { useState, useEffect, useContext } from 'react';

import {
    Typography, Form, Select, Switch, Input, AutoComplete,
} from 'antd';

import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

import { EditOutlined } from '@ant-design/icons';
import { findIndex } from 'lodash';
import Paragraph from 'antd/lib/skeleton/Paragraph';

const { Title } = Typography;
const { Option } = Select;

const ChangeButton = ({ onChange, switchDisabled, staticMode }) => (

    // disabled if entity === 'no entity found'
    // defaultChecekd if entity !== 'no entity found'
    <Switch
        checkedChildren="Auto"
        unCheckedChildren="Search"
        disabled={switchDisabled}
        defaultChecked={staticMode}
        onChange={(status) => onChange(status)}
    />
);

const defaultState = (currentNotice) => {
    if (currentNotice.entity.recognized_entity === 'no entity found') {
        return false;
    }
    return true;
};

const renderDropDownOptions = (entity) => ({
    value: entity.matched_entity,
    label: (
        <div className="w-full">
            <div className="break-words">
                {entity.matched_entity}
            </div>
            <div>
                Federal ID: &nbsp;
                {entity.federal_id || null}
            </div>
            <div>
                Local ID:
                {entity.local_id || null }
                {' '}
            </div>
        </div>
    ),
});

const renderAutoCompleteOptions = (entity, taxauthority) => ({
    value: entity.entity,
    federal_id: entity.fein,
    label: (
        <div>
            <div className="break-words">
                {entity.entity}
            </div>
            <div>
                Federal ID: &nbsp;
                { entity.fein || null}
            </div>
            <div>
                Local ID:
                {' '}
                {entity[taxauthority] || null }
            </div>
        </div>
    ),
});

const AddEntityItem = ({ currentNotice, disabled }) => {
    const [staticMode, setStaticMode] = useState(defaultState(currentNotice)); // true entity has been found
    const switchDisabled = (currentNotice.entity.recognized_entity === 'no entity found') || disabled; // true if no entity has been found

    const { entities } = useContext(GlobalStateContext);

    const getLocalID = (entityData) => {
        const taxAuthority = currentNotice.taxauthority;
        const entityName = entityData.matched_entity;
        const index = findIndex(entities, (o) => o.entity === entityName);
        try {
            return entities[index][taxAuthority];
        } catch (err) {
            return '';
        }
    };
    if (currentNotice.entity.recognized_entity !== 'no entity found') {
        currentNotice.entity.entity_options.map((entity) => {
            entity.local_id = getLocalID(entity);
        });
    }

    const dropdownOptions = [];
    const autocompleteOptions = [];
    entities.map((entity) => {
        autocompleteOptions.push(
            renderAutoCompleteOptions(entity, currentNotice.taxauthority),
        );
    });
    if (currentNotice.entity.recognized_entity !== 'no entity found') {
        currentNotice.entity.entity_options.map((entity) => {
            dropdownOptions.push(
                renderDropDownOptions(entity),
            );
        });
    }

    const filterFunction = (inputValue, option) => {
        let federalBoolean;
        try {
            federalBoolean = option.federal_id.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        } catch (err) {
            federalBoolean = false;
        }
        let localBoolean;
        try {
            localBoolean = option.local_id.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        } catch (err) {
            localBoolean = false;
        }
        return (option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) || federalBoolean || localBoolean;
    };

    return (

        <>
            <div className="flex space-x-4 items-center">
                <div className="w-4/12">
                    <Title level={5}>
                        Taxpayer
                    </Title>
                </div>
                <div className="w-7/12">
                    {
                        staticMode ? (
                            <Form.Item
                                name="entity"
                                key="entity"
                                initialValue={currentNotice.entity.entity_options[0].matched_entity}
                            >
                                <Select
                                    disabled={disabled}
                                    size="large"
                                    options={dropdownOptions}
                                    dropdownMatchSelectWidth={500}

                                />
                            </Form.Item>

                        ) : (
                            <Form.Item
                                name="entity"
                                key="entity"
                            >
                                <AutoComplete
                                    options={autocompleteOptions}
                                    className="w-full"
                                    filterOption={(inputValue, option) => filterFunction(inputValue, option)}
                                    placeholder="Enter Taxpayer or Federal ID"
                                    dropdownMatchSelectWidth={500}

                                >
                                    <Input size="large" />
                                </AutoComplete>
                            </Form.Item>
                        )

                    }

                </div>
                <div className="w-1/12 items-start">
                    <ChangeButton
                        onChange={(status) => setStaticMode(status)}
                        switchDisabled={switchDisabled}
                        staticMode={staticMode}
                    />
                </div>
            </div>

        </>

    );
};

export default AddEntityItem;
