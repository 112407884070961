import React from 'react';
import { Form, Input, Button } from 'antd';

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

const formItemLayout = {
    labelCol: {
        sm: { span: 8 },
    }  
};

const ChangePassword = () => {
    const handlePasswordChange = (values) => {}

    return (
        <Form
            name="changePassword"
            onFinish={handlePasswordChange}
            {...formItemLayout}
        >
            <Form.Item
                label="Username"
                name="username"
                rules={[{required: true, message: 'Please input your username!'}]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Old password"
                name="oldpassword"
                rules={[{required: true, message: 'Please input the code!'}]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                label="New password"
                name="newpassword"
                rules={[{required: true, message: 'Please input your new password!'}]}
            >
                <Input.Password/>
            </Form.Item>
    
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit new password
                </Button>
            </Form.Item>
        </Form>
    )
};

export default ChangePassword;
