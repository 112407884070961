import { getApiUrl } from 'src/utils/Helper';
import { axiosGet, axiosPost } from './utils';

const API_ENDPOINT = getApiUrl();

export const updateNotice = (authContext) => (values) => {
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosPost(`${API_ENDPOINT}/update_notice`, values, config);
};

export const updateNoticeCustom = (authContext) => () => {
    const data = {
        values: {
            noticeaction: 'done'
        }
    }
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosPost(`${API_ENDPOINT}/update_notice_custom`, data, config);
};
