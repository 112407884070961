import React, { useContext } from "react";
import { Select } from "antd";

import { GlobalStateContext } from "src/services/GlobalState/GlobalState";

const { Option } = Select;

const Entity = ({ cachedValues, handleEntityChange }) => {
  	const { entities } = useContext(GlobalStateContext);

	const entitychildren = [];
	entities.map(entity => {
		entitychildren.push(<Option key={entity}>{entity}</Option>);
	});

	return (
		<Select
			key="entity"
			mode="multiple"
			placeholder="Entities"
			maxTagCount="responsive"
			className="w-full"
			onChange={handleEntityChange}
			defaultValue={cachedValues}
		>
			{entities.map(entity => (
				<Option key={entity.entity}>{entity.entity}</Option>
			))}
		</Select>
	);
};

export default Entity;
