import React from 'react';
import Public from 'src/routes/PublicRoutes';

export const AuthContext = React.createContext({});

const Authentication = (props) => (
    <AuthContext.Consumer>
        { ({ isAuthenticated, loading }) => {
            if (loading)
                return <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '50vh'
                    }}>
                    Loading
                </div>;
            if (isAuthenticated) return props.children;
            return <Public />;
        }}
    </AuthContext.Consumer>
);

export default Authentication;
