import React, { useState } from 'react';
import { Comment, Divider, Tooltip, Typography } from 'antd';
import { convertTimeStamp } from 'src/utils/Date';
import CommentInput from 'src/components/Comments/CommentInput';
import CommentReply from 'src/components/Comments/CommentReply';
import { get } from 'lodash';
import { COLORS } from 'src/theme/colors';
import { PaperClipOutlined } from '@ant-design/icons';
import { Storage } from 'aws-amplify';
import { downloadBlob } from 'src/utils/DownloadNotice';

const { Text } = Typography;

export const parseAttachmentFilename = (raw) => {
    if (!raw || raw.length < 3) return 'no file name';
    const sections = raw.split('/');
    return sections[3] || 'filename_notfound';
};

export default ({
    author, content, replyAction, onReplyUpdate, replyComments = [], date, attachments,
}) => {
    const [children, setChildren] = useState(null);
    const renderReplyCommentInput = () => (
        <CommentInput
            submit={async (data) => {
                try {
                    onReplyUpdate(data);
                    setChildren(null);
                } catch (e) {

                }
            }}
            theme="dark"
        />
    );
    const renderCommentAction = () => (
        replyAction && [
            <span onClick={() => { setChildren(() => renderReplyCommentInput()); }} key="comment-list-reply-to-0">
                Reply to
            </span>,
        ]
    );

    const moment = convertTimeStamp(date);
    function handleDownload(filePath) {
        Storage.get(filePath, { download: true, level: 'public' })
            .then((res) => downloadBlob(res.Body, parseAttachmentFilename(filePath)))
            .catch((err) => console.log(err));
    }

    function renderAttachedFiles() {
        if (!attachments || attachments.length === 0) return null;
        return attachments.map((attach, index) => (
            <Tooltip
                placement="top"
                key={`Download ${parseAttachmentFilename(attach)}`}
                title={`Download ${parseAttachmentFilename(attach)}`}
            >
                <PaperClipOutlined
                    about="ass"
                    style={{
                        fontSize: 16,
                        verticalAlign: 'top',
                        color: 'black'
                    }}
                onClick={() => handleDownload(attach)}
                />
            </Tooltip>
        ));
    }

    return (
        <div className="flex flex-row content-center">
            <Comment
                className="text-black w-full"
                style={{
                    padding: 31,
                    backgroundColor: COLORS.backgroundLight,
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                }}
                actions={renderCommentAction()}
                author={
                    <Text style={{ color: COLORS.secondary }}>
                        {get(author, 'name', '')} | <Tooltip title={moment.format('YYYY-MM-DD HH:mm:ss')}> {moment.fromNow()} </Tooltip> {renderAttachedFiles()}
                    </Text>
                }
                content={(
                    <>
                        <Divider className="my-1 bg-white" />
                        <div className="flex items-start">
                            <p>{content}</p>
                        </div>
                    </>
                )}

            >
                {children}
                {replyComments.map((comment, index) => (
                    <CommentReply
                        key={`LIST_REPLY_COMMENT_${index}`}
                        handleDownload={handleDownload}
                        {...comment}
                    />
                ))}
            </Comment>
        </div>
    );
};
