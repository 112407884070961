import React, { useEffect } from 'react';
import {
    Form, Row, Button, message
} from 'antd';
import CustomDrawer from 'src/components/shared/Drawer';
import AccessLevelItem from './FormItems/AccessLevelItem';
import { updateUser } from '../../../../utils/getUserList';
import NameItem from './FormItems/NameItem';

const Update = (props) => {
    const {
        visibility, setVisibility, userData, onDone
    } = props;

    const formRef = React.createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldsValue(userData);
        }
    }, [formRef]);

    const handleUpdate = async (values) => {
        const {username, access, name} = values;
        try {
            await updateUser(username, name , access);
            message.success(
                "User has been updated successfully"
            );
            onDone();
        } catch (error) {
            message.error(
                "Failed to update user"
              );
            console.log(error)
        }
    };

    const onSubmit = () => {
        form
        .validateFields()
        .then((values) => {
            values.username = userData.key;
            // handleUpdate(values);
            setVisibility(false);
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });
    };

    return (
        <>
            <CustomDrawer
                width={720}
                visibility={visibility}
                handleOnClose={() => setVisibility(false)}
            >
                <div className="p-20">
                    <Form
                        className="compact pt-8 px-6 pb-0"
                        layout="vertical"
                        form={form}
                        ref={formRef}
                        onFinish={onSubmit}
                        hideRequiredMark
                    >
                        <Row gutter={15}>
                            <NameItem />
                            <AccessLevelItem />
                        </Row>
                        <Row justify="end" className="mt-8">
                            <Button type="primary" htmlType="submit" size="large">
                                Save
                            </Button>
                        </Row>
                    </Form>
                </div>
            </CustomDrawer>
        </>
    );
};

export default Update;
