import React from "react";
import { Select } from "antd";

import { states } from "src/utils/States";

const { Option } = Select;

const stateChildren = [];
states.map(state => stateChildren.push(<Option key={state}>{state}</Option>));

const TaxAuthority = ({ cachedValues, handleStateChange }) => (
  	<Select
		mode="multiple"
		placeholder="Tax Authority"
		maxTagCount="responsive"
		className="w-full"
		onChange={handleStateChange}
		defaultValue={cachedValues}
  	>
    	{stateChildren}
  	</Select>
);

export default TaxAuthority;
