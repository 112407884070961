import React, { useContext } from 'react';
import { Col, Form, Select } from 'antd';
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

const { Option } = Select;

const AssignedUserItem = ({ currentNotice }) => {
    const { users } = useContext(GlobalStateContext);

    return (
        <Col span={12}>
            <Form.Item
                label="Assigned User"
                name="assigneduser"
                key="assigneduser"
                initialValue={currentNotice.assigneduser}
            >
                <Select>
                    {users.map((user) => {
                        const nameIndex = user.attributes.findIndex((attribute) => attribute.Name === 'name');
                        const name = user.attributes[nameIndex].Value;
                        return (
                            <Option value={name} key={name}>
                                {' '}
                                { name }
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </Col>
    )
};

export default AssignedUserItem;
