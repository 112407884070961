import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
// Auth flow components
import Login from 'src/Auth/Login';
import ConfirmSignup from 'src/Auth/ConfirmSignup';
import ForgotPassword from 'src/Auth/ForgotPassword';
import ForgotPasswordSubmit from 'src/Auth/ForgotPasswordSubmit';
import ChangePassword from 'src/Auth/ChangePassword';
import SuperAdmin from "src/ProtectedComponents/Settings/SuperAdmin";
import { PublicRouteWrapper as PublicRoute , ProtectedAdminRoute} from './utils';

export default () => (
    <Switch>
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/forgotpassword" component={ForgotPassword} />
        <PublicRoute path="/forgotpasswordsubmit" component={ForgotPasswordSubmit} />
        <PublicRoute path="/confirmsignup" component={ConfirmSignup} />
        <PublicRoute exact path="/changepassword" component={ChangePassword} />
        <ProtectedAdminRoute exact path="/super-admin" component={SuperAdmin} />
        <Redirect to="/login" />
    </Switch>
);
