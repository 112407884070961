import React from 'react';
import { Modal, Typography, Steps } from 'antd';
import { SolutionOutlined, CloudUploadOutlined, FileTextOutlined } from '@ant-design/icons';

const { Paragraph, Title, Text } = Typography;
const { Step } = Steps;

const InfoProgress = (props) => {
    const { infoModalVisbility, setInfoModalVisibility } = props;
    const test = true;

    return (
        <Modal
            visible={infoModalVisbility}

            title="The Notice Journey"
            width="75%"
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={() => setInfoModalVisibility(false)}
            onCancel={() => setInfoModalVisibility(false)}
        >
            <Steps current={1}>
                <Step title="Notice Import" description="Uploading + Machine Learning" icon={<CloudUploadOutlined />} />
                <Step title="Backlog" description="User Validation" icon={<SolutionOutlined />} />
                <Step title="Notice Management" description="Workflow Management" icon={<FileTextOutlined />} />
            </Steps>
            <Title level={3} className="pt-3">
                Backlog
            </Title>
            <Paragraph>
                Once a notice has made it's way through our pipeline, it arrives here in the backlog.
            </Paragraph>
            <Paragraph>
                In our pipeline, we have extracted a range of data elements, or keys, that are relevant to your tax compliance process.
            </Paragraph>
            <Paragraph>
                Before a notice and it's data can move on to notice management, it's necessary for you to validate our predictions.
                This way we get better at extracting notice data and showing you the correct notice data.
            </Paragraph>

            <Paragraph>
                Every notice has a number of recurring data elements:

                <ul>
                    <li>Tax Authority</li>
                    <li>Taxpayer</li>
                    <li>Tax Period</li>
                    <li>Notice Type</li>
                    <li>Notice Date</li>
                    <li>
                        Notice ID
                    </li>
                    <li>
                        Due Date
                    </li>
                    <li>
                        Amount

                    </li>
                </ul>
            </Paragraph>
        </Modal>
    );
};

export default InfoProgress;
