import React, { useContext } from 'react';
import { Col, Form, Select } from 'antd';
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

const { Option } = Select;

const AssignedUserItem = ({ currentBacklog }) => {
    const { users } = useContext(GlobalStateContext);
    const cognitoGeneratedUserName = currentBacklog.unique_id.split('_')[0];
    const index = users.findIndex((user) => user.username === cognitoGeneratedUserName);
    let username = '';

    if (index !== -1) {
        const usernameIndex = users[index].attributes.findIndex((attribute) => attribute.Name === 'name');
        username = users[index].attributes[usernameIndex].Value;
    } else {
        username = null;
    }

    return (
        <Col span={12}>
            <Form.Item
                label="Assigned User"
                name="assigneduser"
                key="assigneduser"
                initialValue={username}
            >
                <Select>
                    {users.map((user) => {
                        const nameIndex = user.attributes.findIndex((attribute) => attribute.Name === 'name');
                        const name = user.attributes[nameIndex].Value;
                        return (
                            <Option value={name} key={name}>
                                {' '}
                                { name }
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </Col>
    )
};

export default AssignedUserItem;
