import React from 'react';
import {
    Form, Input
} from 'antd';

const TemporaryPasswordItem = () => {
    return (
        <Form.Item name="password" label="Temporary password" rules={[{ required: true, message: 'Please input a temporary password' }]}>
            <Input.Password></Input.Password>
        </Form.Item>
    );
};

export default TemporaryPasswordItem;
