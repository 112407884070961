import React from "react";
import { Select } from "antd";
import { status } from "src/utils/Status";

const { Option } = Select;

const statusChildren = [];
status.map(status => statusChildren.push(<Option key={status.value}>{status.label}</Option>));

const Status = ({ cachedValues, handleStatusChange }) => (
  <Select
    mode="multiple"
    placeholder="Status"
    maxTagCount="responsive"
    className="w-full"
    onChange={handleStatusChange}
    defaultValue={cachedValues}
  >
    {statusChildren}
  </Select>
);

export default Status;
