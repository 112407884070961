import React from 'react';
import {
    Form, Input, Col
} from 'antd';

const FEINItem = () => {
    return (
        <Col span={12}>
            <Form.Item
                name="fein"
                label="FEIN"
                className="mt-4"
            >
                <Input placeholder="Please enter FEIN" />
            </Form.Item>
        </Col>
    );
};

export default FEINItem;
