import React from "react";
import logo from "../../assets/img/logo.png";

const Logo = ({ width, ...rest }) => {
  return (
    <img src={logo} alt="stream-logo" {...rest} width={width ? width : 120} />
  );
};

export default Logo;
