/** @jsx jsx */

import { jsx } from '@emotion/core';
import React, { useState, useContext } from 'react';
import { Row, Col, Modal, Button } from "antd";

import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';
import { formatBacklogs } from "src/utils/NoticeManagement";
import PermissionService, { DownloadNoticeScope } from 'src/services/PermissionService';

import DownloadNotice from 'src/utils/DownloadNotice';

import LeftPane from './LeftPane';
import Edit from './Edit';

const BacklogViewer = (props) => {
    const [ visible, setVisible ] = useState(false);
    const { backlogs } = useContext(GlobalStateContext);

    const index = backlogs.findIndex((notice) => notice.unique_id === props.location.pathname.split('/')[2]);
    const currentBacklog = formatBacklogs([backlogs[index]])[0];

    const generateFileName = (raw) => {
        if (!raw) return '';
        const rawName = raw.split('/')[2];
        if (!rawName) return '';
        return decodeURI(rawName);
    };

    return (
        <>
        {currentBacklog &&
            <Row className="notice-view-container w-full">
                <Col lg={12} md={24} xs={24} className="notice-img-container">
                    <LeftPane
                        onClick={() => {
                            setVisible(true);
                        }}
                        currentBacklog={currentBacklog}
                    />
                </Col>
                <Col lg={12} md={24} xs={24} className="notice-view-details dark-theme">
                    <Row justify="center">
                        <Edit
                            currentBacklog={currentBacklog}
                        />
                    </Row>
                </Col>
                <Modal
                    title={generateFileName(currentBacklog.original_filename)}
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    width="100vh"
                    cancelButtonProps={{ style: { display: 'none' } }}
                    maskClosable
                    footer={[
                        <PermissionService scope={DownloadNoticeScope}>
                            <DownloadNotice currentNotice={currentBacklog} />
                        </PermissionService>,
                        <Button
                            key="done"
                            type="primary"
                            onClick={() => setVisible(false)}
                        >
                            Done
                        </Button>,
                    ]}
                >
                    <LeftPane
                        onClick={() => {
                            setVisible(true);
                        }}
                        currentBacklog={currentBacklog}
                    />
                </Modal>
            </Row>
        }
        </>
    );
};

export default BacklogViewer;
