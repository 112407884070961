import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Row, Button, message } from 'antd';

import FetchContext from 'src/services/FetchService/FetchContext';
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

import TaxAuthorityItem from './FormItems/TaxAuthorityItem';
import TaxPayerItem from './FormItems/TaxPayerItem';
import TaxPeriodItem from './FormItems/TaxPeriodItem';
import NoticeDateItem from './FormItems/NoticeDateItem';
import NoticeTypeItem from './FormItems/NoticeTypeItem';
import NoticeIDItem from './FormItems/NoticeIDItem';
import DueDateItem from './FormItems/DueDateItem';
import StatusItem from './FormItems/StatusItem';
import AmountItem from './FormItems/AmountItem';
import AssignedUserItem from './FormItems/AssignedUserItem';
import ActionItem from './FormItems/ActionItem';

const Details = ({ currentNotice }) => {
    const [ taxAuthority, setTaxAuthority ] = useState(currentNotice.taxauthority);
    const { noticemanagement: { updateNotice, updateNoticeCustom } } = useContext(FetchContext);
    const { reFetch } = useContext(GlobalStateContext);
    const [ loading, setLoading ] = useState(false);
    const [ form ] = Form.useForm();
    const history = useHistory();

    const processForm = async (values) => {
        setLoading(true);
        const stringOnly = {};

        for (const key of Object.keys(values)) {
            if (values[key] === '') {
                stringOnly[key] = ' ';
            }
            try {
                stringOnly[key] = values[key].toString();
            } catch (err) {
                stringOnly[key] = null;
            }
        }

        const body = {
            values: stringOnly,
            old_values: currentNotice,
        };

        try {
            await updateNotice(body);
            await reFetch();
            setLoading(false);
            message.success(
                "Notice data has been updated successfully"
            );
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };

    const handleEntityCreate = data => {
        form.setFieldsValue({ entity: data });
    };

    const handleTaxAuthorityChange = data => {
        setTaxAuthority(data);
    };

    return (
        <>
            <Form form={form} onFinish={processForm} className="compact px-4 notice-edit" layout="vertical">
                <Row gutter={15} align="bottom">
                    <TaxAuthorityItem currentNotice={currentNotice} onChange={handleTaxAuthorityChange} />
                </Row>
                <TaxPayerItem currentNotice={currentNotice} taxAuthority={taxAuthority} onCreate={handleEntityCreate} />
                <Row gutter={15} className="my-4">
                    <TaxPeriodItem currentNotice={currentNotice} />
                    <NoticeDateItem currentNotice={currentNotice} />
                </Row>
                <Row gutter={15}>
                    <NoticeTypeItem currentNotice={currentNotice} />
                    <NoticeIDItem currentNotice={currentNotice} />
                </Row>
                <Row gutter={15} className="mt-4">
                    <DueDateItem currentNotice={currentNotice} />
                    <StatusItem currentNotice={currentNotice} />
                </Row>
                <AmountItem currentNotice={currentNotice} />
                <Row gutter={15} className="mt-6">
                    <AssignedUserItem currentNotice={currentNotice} />
                    <ActionItem currentNotice={currentNotice} />
                </Row>

                <Row justify="end" className="mt-12">
                    <Button htmlType="button" className="hidden" onClick={() => updateNoticeCustom()}>Update All</Button>
                    <Button type="link" size="large" htmlType="button" onClick={history.goBack}>Cancel</Button>
                    <Button type="primary" size="large" htmlType="submit" loading={loading} style={{ minWidth: 120 }}>Save</Button>
                </Row>
            </Form>
        </>
    );
};

export default Details;
