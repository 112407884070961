import React from 'react';
import {
    Form, Col, Select
} from 'antd';
import { states } from 'src/utils/States';

const { Option } = Select;

const stateChildren = [];
states.map(state => stateChildren.push(<Option key={state}>{state}</Option>));

const StateItem = ({ name, fieldKey, isListField }) => {
    return (
        <Col span={12}>
            <Form.Item
                name={name}
                fieldKey={fieldKey}
                isListField={isListField}
                label="State"
                className="mt-4"
                rules={[{ required: true, message: 'Please select state' }]}
            >
                <Select placeholder="State">
                    {stateChildren}
                </Select>
            </Form.Item>
        </Col>
    );
};

export default StateItem;
