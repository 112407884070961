import React from 'react';
import LogoImage from 'src/assets/img/logo.svg';
import BackImage from 'src/assets/img/back.svg';
import { useHistory, useLocation } from 'react-router-dom';

function StreamLogo() {
    const handleDragStart = (e) => {
        e.preventDefault();
    };
    return <img src={LogoImage} alt="website logo" className="streamlogo inline-flex pb-1" onDragStart={handleDragStart} />;
}

function BackButtonLogo() {
    return <img src={BackImage} alt="back" className="inline-flex" />;
}

const Logo = () => {
    const history = useHistory();
    const location = useLocation();
    const currentPath = window.location.pathname;
    const isHomePage = location.pathname === '/noticemanagement';

    const handleGoBack = () => {
        if (currentPath.includes('/noticemanagement') || history.action === 'POP') {
            history.push('/noticemanagement');
        } else {
            history.goBack();
        }
    };

    return (
        <>
            <a onClick={handleGoBack}>
                {!isHomePage && <BackButtonLogo />}
            </a>
            <StreamLogo />
        </>
    );
};

export default Logo;
