import React from 'react';
import {
    Typography, Form, Select, Popover, Row, Col,
} from 'antd';

const { Title } = Typography;
const { Option } = Select;

const content = (
    <div>
        <p>
            The assigned user defaults to the user who uploaded the notice.
        </p>
        <p>
            It's possible to change this notice's assignee throughout the application
        </p>
    </div>
);

const AddAssignedUserItem = ({ users, disabled, currentNotice }) => {
    const cognitoGeneratedUserName = currentNotice.unique_id.split('_')[0];
    const index = users.findIndex((user) => user.username === cognitoGeneratedUserName);
    let username = '';
    if (index !== -1) {
        const usernameIndex = users[index].attributes.findIndex((attribute) => attribute.Name === 'name');
        username = users[index].attributes[usernameIndex].Value;
    } else {
        username = null;
    }

    return (
        <>
            <div className="flex items-center">
                <div className="w-4/12">
                    <Title level={5}>
                        Assigned User
                    </Title>
                </div>
                <div className="w-8/12">
                    <Popover content={content} title="Assigned User">
                        <Form.Item
                            name="assigneduser"
                            key="assigneduser"
                            initialValue={username}
                        >
                            <Select
                                disabled={disabled}
                                size="large"
                            >
                                {users.map((user, index) => {
                                    const nameIndex = user.attributes.findIndex((attribute) => attribute.Name === 'name');
                                    const name = user.attributes[nameIndex].Value;
                                    return (
                                        <Option key={`AddAssignedUserItem_${index}`} value={name}>
                                            {' '}
                                            { name }
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Popover>
                </div>
            </div>
        </>
    );
};
export default AddAssignedUserItem;
