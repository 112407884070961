import * as JWT from 'jwt-decode';

const validateToken = (token) => {
    if (!token) {
        return false;
    }
    try {
        const decodedJwt = JWT(token);

        const returnBoolean = decodedJwt.exp >= Date.now() / 1000;
        return returnBoolean;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const getApiUrl = () => {
    const { REACT_APP_USER_BRANCH, NODE_ENV } = process.env;
    console.log('REACT_APP_USER_BRANCH',REACT_APP_USER_BRANCH, 'NODE_ENV', NODE_ENV);
    if (REACT_APP_USER_BRANCH === 'develop' || NODE_ENV === 'development') {
        return 'https://yrtzp5sn7i.execute-api.eu-west-1.amazonaws.com/api';
        // return 'http://127.0.0.1:8000';
    }
    // return 'https://ip3tot6dii.execute-api.eu-west-1.amazonaws.com/api';
    return 'https://2dobn4c0mg.execute-api.eu-west-1.amazonaws.com/api';
};

export { validateToken, getApiUrl };
