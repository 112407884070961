import React, { useContext } from 'react';
import {
    Button, Result, Input, Form,
} from 'antd';
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';
import { StepBackwardOutlined, StepForwardOutlined, FileTextOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const ButtonsForwardBackward = () => {
    const { count, increaseCount, decreaseCount } = useContext(GlobalStateContext);
    const buttonIncreaseCount = () => {
        increaseCount();
    };

    const buttonDecreaseCount = () => {
        decreaseCount();
    };

    if (count === 0) {
        return (
            <>
                <Button
                    style={{ float: 'left', margin: '1em' }}
                    onClick={buttonDecreaseCount}
                    icon={<StepBackwardOutlined />}
                    disabled
                />
                <Button
                    style={{ margin: '1em', float: 'right' }}
                    onClick={buttonIncreaseCount}
                    icon={<StepForwardOutlined />}
                />
            </>
        );
    }
    return (
        <>
            <Button
                style={{ float: 'left', margin: '1em' }}
                onClick={buttonDecreaseCount}
                icon={<StepBackwardOutlined />}
            />
            <Button
                style={{ margin: '1em', float: 'right' }}
                onClick={buttonIncreaseCount}
                icon={<StepForwardOutlined />}
            />
        </>
    );
};

const formItemLayout = {
    labelCol: {
        sm: { span: 8 },
    },

};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const states = ['Alabama', 'Alaska', 'Arkansas', 'Arizona', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Iowa', 'Idaho', 'Illinois', 'Indiana', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Missouri', 'Mississippi', 'Montana', 'North Carolina', 'North Dakota', 'Nebraska', 'New Hampshire', 'New Jersey', 'New Mexico', 'Nevada', 'New York State', 'New York City', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Virginia', 'Vermont', 'Washington', 'Wisconsin', 'West Virginia', 'Wyoming', 'IRS'];

const states_json = [];
states.map((state) => {
    states_json.push({ value: state });
});

const showIMG = (image) => (
    <>
        <img
            src={image}
            alt="new"
            width={600}
        />
    </>
);

const ResultBacklogCompleted = (props) => (
    <Result
        status="success"
        title="You have completed confirmation of all notices in the backlog"
        subTitle="The notices are now in Notice Management"
        extra={[
            <Button
                type="primary"
                key="console"
                onClick={() => { props.history.push('/noticemanagement'); }}
            >
                <FileTextOutlined />
                {' '}
                Notice management
            </Button>,
        ]}
    />
);

const remarksField = (
    <Form.Item
        name="remarks"
        label="Remarks"
        key="remarks"
    >
        <TextArea
            placeholder="These remarks will be addressed by our DevOps team"
            autoSize={{ minRows: 2, maxRows: 6 }}
        />
    </Form.Item>
);
const notesField = (
    <Form.Item
        name="notes"
        label="Notes"
        key="notes"
    >
        <TextArea
            placeholder="Notes for internal use"
            autoSize={{ minRows: 2, maxRows: 6 }}
        />
    </Form.Item>
);

export {
    ButtonsForwardBackward, formItemLayout, tailLayout, states_json, showIMG, ResultBacklogCompleted, remarksField, notesField,
};
