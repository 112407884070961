import React from 'react';
import moment from 'moment';
import * as chrono from "chrono-node";
import { Col, Form, DatePicker } from 'antd';
import { mappingTable } from "src/utils/mappingTable";

const DueDateItem = ({ currentBacklog }) => {
    const keys = mappingTable.dueDate;
    const candidates = [];

    Object.keys(currentBacklog).map(key => {
        if (keys.includes(key.slice(0, -1))) {
            candidates.push(currentBacklog[key].options[0]);
        }
    });

    const proposedNoticeDate = moment(chrono.parseDate(candidates[0]));

    return (
        <Col span={12}>
            <Form.Item
                label="Due Date"
                name="dueDate"
                key="dueDate"
                initialValue={proposedNoticeDate.isValid() ? proposedNoticeDate : null}
            >
                <DatePicker
                    style={{ width: 180 }}
                />
            </Form.Item>
        </Col>
    );
};

export default DueDateItem;
