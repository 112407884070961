import React, { useState } from "react";
import * as chrono from "chrono-node";
import moment from "moment";
import { Typography, Form, DatePicker, Switch } from "antd";
import { mappingTable } from "src/utils/mappingTable";

const { Title } = Typography;

const NoticeDateItem = props => {
  const { currentNotice } = props;
  const keys = mappingTable.noticeDate;
  const options = [];

  Object.keys(currentNotice).map(key => {
    if (keys.includes(key.slice(0, -1))) {
      options.push(currentNotice[key].options[0]);
    }
  });
  const proposedNoticeDate = moment(chrono.parseDate(options[0]));
  return (
    <div className="flex items-center">
      <div className="w-4/12">
        <Title level={5}>Notice Date</Title>
      </div>
      <div className="w-8/12">
        <Form.Item
          name="noticeDate"
          key="noticeDate"
          initialValue={
            proposedNoticeDate.isValid() ? proposedNoticeDate : null
          }
        >
           <DatePicker
            style={{ width: 180 }}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default NoticeDateItem;
