import React, { useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import { SettingOutlined, LogoutOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { AuthContext } from 'src/services/AuthService/Authentication';


const UserMenu = ({ expanded, setExpanded }) => {
  const { logout, userInfo } = useContext(AuthContext);
  const { pathname } = useLocation();

  return (
    <div
      className="relative"
      style={{ marginTop: "2.5rem" }}
    >
      {expanded && (
        <div className="p-5 z-50 w-full bg-white userdropdown">
          <svg className='polygon-icon' xmlns="http://www.w3.org/2000/svg" width="39" height="15" viewBox="0 0 39 15" fill="none">
            <path d="M19.8182 0L38.7133 15H0.923082L19.8182 0Z" fill="white" />
          </svg>
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <ul>
              {
                (userInfo && ((userInfo.attributes['custom:access'] !== "SuperAdmin") || (userInfo.attributes['custom:access'] === "SuperAdmin" && pathname !== '/super-admin'))) && (
                  <li className='drop-down-link'>
                    <Link to="/settings" onClick={() => setExpanded(false)}>
                      <div className="p-1 flex justify-between">
                        <div className='normal-case'>
                          Settings
                        </div>
                        <div>
                          <SettingOutlined className="ml-2 text-xl" style={{ color: "#5FC8BF" }} />
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              }
              {
                (userInfo && (userInfo.attributes['custom:access'] == "SuperAdmin" && pathname != '/super-admin')) && (
                  <li className='drop-down-link'>
                    <Link to="/super-admin" onClick={() => setExpanded(false)}>
                      <div className="p-1 flex justify-between">
                        <div className='normal-case'>
                          Dashboard
                        </div>
                        <div className=''>
                          <ArrowLeftOutlined className="ml-2 text-xl" style={{ color: "#5FC8BF" }} />
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              }
              <li className='drop-down-link'>
                <Link className="" to="/login" onClick={() => logout()}>
                  <div className="p-1 flex justify-between">
                    <div className='normal-case'>
                      Sign out
                    </div>
                    <div className=''>
                      <LogoutOutlined className="ml-2 text-xl" style={{ color: "#5FC8BF" }} />
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
