import React from "react";
import { Row, Tabs } from "antd";
import { UsersOutlined } from 'src/components/shared/Icons';
import Users from './Users/Users';
const { TabPane } = Tabs;
const SuperAdmin = () => {
	return(
	<div className="container">
		<Row className="page">
			<Tabs style={{ width: "100%" }}>
				<TabPane
					key="users"
					tab={
					<span>
						<UsersOutlined className="mr-1" />
						Users
					</span>
					}
				>
					<Users />
				</TabPane>
			</Tabs>
		</Row>
	</div>
)};
export default SuperAdmin;