import React from 'react';
import {
    Col, Form, Select
} from 'antd';

const { Option } = Select;

const ActionItem = ({ currentBacklog }) => (
    <Col span={12}>
        <Form.Item
            label="Action"
            name="noticeaction"
            key="noticeaction"
            initialValue={currentBacklog.noticeaction ? currentBacklog.noticeaction : 'confirm'}
        >
            <Select>
                <Option value="confirm" key="confirm">Confirm data               </Option>
                <Option value="review"  key="review"> Confirm and move to review </Option>
                <Option value="deposit" key="deposit">Confirm and move to deposit</Option>
                <Option value="payment" key="payment">Confirm and move to payment</Option>
                <Option value="done"    key="done">   Confirm and move to done   </Option>
            </Select>
        </Form.Item>
    </Col>
);

export default ActionItem;
