import { useContext } from 'react';
import { AuthContext } from 'src/services/AuthService/AuthProvider';

function PermissionService(props) {
    const { permissions } = useContext(AuthContext);
    const { scope, replace = null } = props;

    function render() {
        if (permissions.has(scope)) {
            return props.children;
        }
        return replace;
    }

    return render();
}

export default PermissionService;
