import React, { useState, useContext, useEffect } from "react";
import { get, filter } from "lodash";
import { Row, Col } from "antd";

// Components
import Tabs from './Tabs';
import Export from './Export';
import Filters from './Filters';
import NoticeTable from "./Table";

// Util imports
import { states } from "src/utils/States";
import { years } from "src/utils/Years";
import { status } from "src/utils/Status";
import { noticeTypesConversion } from "src/utils/Categories";
import { GlobalStateContext } from "src/services/GlobalState/GlobalState";
import { localStorageReadItem, localStorageSetItem, formatBacklogs, formatNotices } from "src/utils/NoticeManagement";

const STORAGE_KEY_STATES = "filter_states";
const STORAGE_KEY_USERS = "filter_users";
const STORAGE_KEY_TYPES = "filter_noticeTypes";
const STORAGE_KEY_ENTITIES = "filter_entities";
const STORAGE_KEY_YEARS = "filter_years";
const STORAGE_KEY_STATUS = "filter_status";
const STORAGE_KEYS = [
	STORAGE_KEY_STATES,
	STORAGE_KEY_USERS,
	STORAGE_KEY_TYPES,
	STORAGE_KEY_ENTITIES,
	STORAGE_KEY_YEARS,
	STORAGE_KEY_STATUS
];

const setFilterLabels = (key, value) => {
	localStorageSetItem(key, JSON.stringify(value));
};

const readFilterLabels = () =>
	STORAGE_KEYS.map(key => ({
		key,
		value: JSON.parse(localStorageReadItem(key))
	}));

const getCachedFilter = (defaultData, cachedKey) => {
	const cachedData = readFilterLabels();
	const cached = get(
		cachedData.find(item => item.key === cachedKey),
		"value",
		[]
	);
	if (!cached || cached.length === 0) {
		return defaultData;
	}
	return cached;
};

const NoticeManagement = () => {
	const { users, entities, backlogs, notices } = useContext(GlobalStateContext);
	const defaultTab = backlogs.length ? 'backlog' : 'confirm';
	const [tableTab, setTableTab] = useState(defaultTab);
	const [backlogsData, setBacklogsData] = useState([]);
	const [noticesData, setNoticesData] = useState(null);
	const [tableData, setTableData] = useState(null);

	// FILTERS ---------------------------
	const cachedData = readFilterLabels();

	const getCachedByKey = key => get(cachedData.find(item => item.key === key), "value", []) || [];

	const usersList = users.map(user => {
		const nameIndex = user.attributes.findIndex(
			attribute => attribute.Name === "name"
		);
		return user.attributes[nameIndex].Value;
	});

	const [selectedStates, setSelectedStates] = useState(
		getCachedFilter(states, STORAGE_KEY_STATES)
	);

	const [selectedEntities, setSelectedEntities] = useState(
		getCachedFilter(entities, STORAGE_KEY_ENTITIES)
	);

	const [selectedUsers, setSelectedUsers] = useState(
		getCachedFilter(usersList, STORAGE_KEY_USERS)
	);

	const noticeTypes = noticeTypesConversion.map(item => item.frontendName);
	const [selectedTypes, setSelectedTypes] = useState(
		getCachedFilter(noticeTypes, STORAGE_KEY_TYPES)
	);

	const [selectedYears, setSelectedYears] = useState(
		getCachedFilter(years, STORAGE_KEY_YEARS)
	);

	const [selectedStatuses, setSelectedStatuses] = useState(
		getCachedFilter(status, STORAGE_KEY_STATUS)
	);

	// METHODS -------------------------
	const handleStateChange = event => {
		if (event.length === 0) {
			setFilterLabels(STORAGE_KEY_STATES, []);
			setSelectedStates(states);
		} else {
			setFilterLabels(STORAGE_KEY_STATES, event);
			setSelectedStates(event);
		}
	};

	const handleEntityChange = event => {
		const eventNumeric = event.map(x => +x);
		if (eventNumeric.length === 0) {
			setFilterLabels(STORAGE_KEY_ENTITIES, []);
			setSelectedEntities(entities);
		} else {
			setFilterLabels(STORAGE_KEY_ENTITIES, event);
			setSelectedEntities(event);
		}
	};

	const handleUserChange = event => {
		if (event.length === 0) {
			setFilterLabels(STORAGE_KEY_USERS, []);
			setSelectedUsers(usersList);
		} else {
			setFilterLabels(STORAGE_KEY_USERS, event);
			setSelectedUsers(event);
		}
	};

	const handleNoticetypeChange = event => {
		const eventNumeric = event.map(x => +x);
		if (eventNumeric.length === 0) {
			setFilterLabels(STORAGE_KEY_TYPES, []);
			setSelectedTypes(noticeTypes);
		} else {
			setFilterLabels(STORAGE_KEY_TYPES, event);
			setSelectedTypes(event);
		}
	};

	const handleYearChange = event => {
		if (event.length === 0) {
			setFilterLabels(STORAGE_KEY_YEARS, []);
			setSelectedYears(years);
		} else {
			setFilterLabels(STORAGE_KEY_YEARS, event);
			setSelectedYears(event);
		}
	};

	const handleStatusChange = event => {
		if (event.length === 0) {
			setFilterLabels(STORAGE_KEY_STATUS, []);
			setSelectedStatuses(status);
		} else {
			setFilterLabels(STORAGE_KEY_STATUS, event);
			setSelectedStatuses(event);
		}
	};

	const filterBasedOnStatus = action => {
		switch (tableTab) {
			case 'confirm':
				return (
					!action || action === 'confirm' || action === 'review' || action === 'deposit' || action === 'payment'
				) ? true : false;
			case 'review':
				return action === 'review' ? true : false;
			case 'deposit':
				return action === 'deposit' ? true : false;
			case 'payment':
				return action === 'payment' ? true : false;
			case 'done':
				return action === 'done' ? true : false;
			default:
				return true;
		}
	}

	// Applying the tab parameter from the url to the table
	useEffect(() => {
		const url = new URL(window.location.href)
		const params = new URLSearchParams(url.searchParams)
		const tabFromParams = params.get('tab')

		if (tabFromParams) {
			try {
				//atob is used to decode the base64 string to an object
				setTableTab(window.atob(tabFromParams));
			}
			catch (err) {
				console.warn(`Invalid tab value: "${tabFromParams}": ${err}`)
			}
		}
	}, []);

	// Adding the tab parameter active on the page to the url
	useEffect(() => {
		const currentURL = new URL(window.location.href);
		//btoa is used to encode the object to base64
		currentURL.searchParams.set('tab', window.btoa(tableTab));

		window.history.replaceState(null, '', currentURL);
	}, [tableTab]);

	// Seprately filter Notices by status because some notices don't have a status property
	// therefore we have to include them if the user selects "none"
	const filteredByStatus = (notices) => filter(notices, notice => {
		const { noticestatus } = notice;
		const allStatusesSelected = selectedStatuses.length === status.length;
		const isStatusSelected = selectedStatuses.includes(noticestatus);
		const undefinedNoticeStatus = selectedStatuses.includes("none") && noticestatus !== "inprogress" && noticestatus !== "completed";
		return allStatusesSelected || isStatusSelected || undefinedNoticeStatus;
	});

	// First useEffect for setting backlogs data
	useEffect(() => {
		const formattedBacklogs = formatBacklogs(backlogs);

		// Applying the filters for backlogs
		const backlogResults = filter(
			formattedBacklogs,
			item =>
				(selectedStates.length === states.length
					? true
					: selectedStates.includes(item.taxauthority)) &&
				(selectedTypes.length === noticeTypesConversion.length
					? true
					: selectedTypes.includes(item.noticetype)) &&
				(selectedEntities.length === entities.length
					? true
					: selectedEntities.includes(item.taxpayer)) &&
				(selectedYears.length === years.length
					? true
					: selectedYears.map(year => parseInt(year)).includes(parseInt(item.taxyear_selected))) &&
				(selectedUsers.length === usersList.length
					? true
					: selectedUsers.includes(item.assigneduser))
		);

		setBacklogsData(filteredByStatus(backlogResults));
	}, [selectedStates, selectedTypes, selectedEntities, selectedYears, selectedUsers, selectedStatuses,  backlogs]);

	// Second useEffect setting notices data
	useEffect(() => {
		const formattedNotices = formatNotices(notices, entities);

		// Applying the filters for notices
		const noticeResults = filter(
			formattedNotices,
			notice =>
				(selectedStates.length === states.length
					? true
					: selectedStates.includes(notice.taxauthority)) &&
				(selectedTypes.length === noticeTypesConversion.length
					? true
					: selectedTypes.includes(notice.noticetype)) &&
				(selectedEntities.length === entities.length
					? true
					: selectedEntities.includes(notice.taxpayer)) &&
				(selectedYears.length === years.length
					? true
					: selectedYears.map(year => parseInt(year)).includes(parseInt(notice.taxyear_selected))) &&
				(selectedUsers.length === usersList.length
					? true
					: selectedUsers.includes(notice.assigneduser))
		);

		setNoticesData(filteredByStatus(noticeResults));
	}, [selectedStates, selectedTypes, selectedEntities, selectedYears, selectedUsers, selectedStatuses, notices]);

	// Third useEffect for sorting and setting table data
	useEffect(() => {
		if (tableTab === 'backlog') {
			setTableData(backlogsData.sort((a, b) => new Date(b.sortTime) - new Date(a.sortTime)));
		} else {
			setTableData(
				filter(noticesData, notice => filterBasedOnStatus(notice.noticeaction))
					.sort((a, b) => new Date(b.sortTime) - new Date(a.sortTime))
			);
		}
	}, [tableTab, backlogsData, noticesData]);


	const getCount = key => {
		if (!backlogsData || !noticesData) {
			return null;
		}

		switch (key) {
			case 'backlog':
				return backlogsData.length;
			case 'confirm':
				return noticesData.filter(
					a => !a.noticeaction || a.noticeaction === "confirm" || a.noticeaction === "review" || a.noticeaction === "deposit" || a.noticeaction === "payment"
				).length;
			case 'review':
				return noticesData.filter(a => a.noticeaction === "review").length;
			case 'deposit':
				return noticesData.filter(a => a.noticeaction === "deposit").length;
			case 'payment':
				return noticesData.filter(a => a.noticeaction === "payment").length;
			case 'done':
				return noticesData.filter(a => a.noticeaction === "done").length;
			default:
				return noticesData.length;
		}
	};

	return (
		<>
			<Row justify="center" className="notice-top-bar">
				<Col xs={24} sm={23}>
					<Filters
						users={users}
						noticeTypes={noticeTypesConversion}
						statesCachedValues={getCachedByKey(STORAGE_KEY_STATES)}
						handleStateChange={handleStateChange}
						entitiesCachedValues={getCachedByKey(STORAGE_KEY_ENTITIES)}
						handleEntityChange={handleEntityChange}
						typesCachedValues={getCachedByKey(STORAGE_KEY_TYPES)}
						handleNoticetypeChange={handleNoticetypeChange}
						usersCachedValues={getCachedByKey(STORAGE_KEY_USERS)}
						handleUserChange={handleUserChange}
						yearsCachedValues={getCachedByKey(STORAGE_KEY_YEARS)}
						handleYearChange={handleYearChange}
						statusesCachedValues={getCachedByKey(STORAGE_KEY_STATUS)}
						handleStatusChange={handleStatusChange}
					/>
				</Col>
			</Row>
			<div className="px-8">
				<Row justify="space-between" className="items-center pt-6">
					<Col span={22}>
						<Tabs tableTab={tableTab} setTableTab={setTableTab} getCount={getCount} />
					</Col>
					<Col span={2}>
						<Export tableData={tableData} entities={entities} />
					</Col>
				</Row>
				{tableData && (
					<NoticeTable
						items={tableData}
					></NoticeTable>
				)}
			</div>
		</>
	);
};

export default NoticeManagement;
