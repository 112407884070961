import React, { useState } from 'react';
import {
    Table,
} from 'antd';
import Paper from '../../../components/shared/Paper';
import moment from 'moment';

import { setStatuscolumn } from './setStatusColumn';
import DocumentViewer from '../DocumentViewer';

const convertTimeStamp = (timestamp) => {
    const test = Date.parse(timestamp);
    const offset = new Date().getTimezoneOffset();
    const currentTimestamp = moment(test).subtract(offset, 'minutes');
    return currentTimestamp.format('LLL');
};

const columns = [
    {
        title: 'Status',
        dataIndex: 'notice_status',
        key: 'notice_status',
        render: (status) => setStatuscolumn(status),
        sorter: (a, b) => a.notice_status.localeCompare(b.notice_status),
    },
    {
        title: 'File Name',
        dataIndex: 'original_filename',
        key: 'original_filename',
        render: (file) => decodeURI(file.split('/')[2]),
    },
    {
        title: 'Upload time',
        dataIndex: 'upload_time',
        key: 'upload_time',
        defaultSortOrder: 'descend',
        sorter: (a, b) => new Date(a.upload_time) - new Date(b.upload_time),
        render: (timestamp) => convertTimeStamp(timestamp),
    }
];

const StatusTable = ({ tableData }) => {
    const [noticeVisibility, setNoticeVisibility] = useState(false);
    const [imagePath, setImagePath] = useState(null);

    return (
        <Paper style={{ minWidth: 830, maxWidth: 830 }} className="notice-status">
            <Table
                style={{ paddingTop: '1em' }}
                dataSource={tableData}
                columns={columns}
                rowClassName={() => 'editable-row'}
                onRow={(record) => ({
                    onClick: () => {
                        setNoticeVisibility(true);
                        setImagePath(`${record.original_filename}&&${record.unique_id}.pdf`.slice(7));
                    },
                })}
            />

            <DocumentViewer
                imagePath={imagePath}
                setNoticeVisibility={setNoticeVisibility}
                noticeVisibility={noticeVisibility}
            />
        </Paper>
    );
};

export default StatusTable;
