import React from "react";

import { Typography, Form, Select } from "antd";
import { noticeTypesConversion } from "src/utils/Categories";

const { Option } = Select;
const { Title } = Typography;

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

const AddNoticeCategoryItem = ({ noticeType, disabled }) => {
  const defaultValue =
    noticeType !== "announcement" ? capitalize(noticeType) : null;
  const placeHolder =
    noticeType === "announcement" ? "Select Notice Type" : null;

  return (
    <div className="flex items-center">
      <div className="w-4/12">
        <Title level={5}>Notice Type</Title>
      </div>
      <div className="w-8/12">
        <Form.Item
          name="noticetype"
          key="noticetype"
          initialValue={defaultValue}
        >
          <Select showSearch>
            {noticeTypesConversion.map((item, index) => (
              <Option key={index} value={item.frontendName}>
                {item.frontendName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};

export default AddNoticeCategoryItem;
