import moment from "moment";

const postConfirmation = (values, currentNotice) => {
  try {
    values.amount = values.amount.toString();
    values.taxPeriod = values.taxPeriod.toString();
  } catch (err) {
    console.log(err);
  }
  values.confirm_time = moment().format();

  return {
    backlog_items: currentNotice,
    confirmed_values: values
  };
};

export default postConfirmation;
