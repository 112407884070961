import React from 'react';
import {
    Spin, Space, Typography
} from 'antd';
import {
    FileTextOutlined,
    Loading3QuartersOutlined,
    LoadingCompleteOutlined,
    ScanOutlined
} from "../../../components/shared/Icons";
import { COLORS } from "../../../theme/colors";

export const setStatuscolumn = (status) => {
    const { Text } = Typography;

    if (status === 'uploaded') {
        return (
            <Space>
                <div className="notice-status-icon">
                    <LoadingCompleteOutlined style={{ color: COLORS.warning }} />
                    <FileTextOutlined />
                </div>
                <Text type="warning">Notice uploaded</Text>
            </Space>
        );
    } if (status === 'ocr_started') {
        return (
            <Space>
                <div className="notice-status-icon">
                    <Spin
                        indicator={
                        <Loading3QuartersOutlined
                            spin
                            style={{ color: COLORS.secondary }}
                        />
                        }
                    ></Spin>
                    <ScanOutlined />
                </div>
                <Text type="secondary">OCR in Progress</Text>
            </Space>
        );
    } if (status === 'ocr_finished') {
        return (
            <Space>
                <div className="notice-status-icon">
                    <LoadingCompleteOutlined style={{ color: COLORS.success }} />
                    <ScanOutlined />
                </div>
                <Text className="text-success">Machine Learning in Progress</Text>
            </Space>

        );
    } if (status === 'notice parsed') {
        return (
            <Space>
                <div className="notice-status-icon">
                    <LoadingCompleteOutlined style={{ color: COLORS.primary }} />
                    <FileTextOutlined />
                </div>
                <Text style={{ color: COLORS.primary }}>Notice Parsed</Text>
            </Space>
        );
    }
};
