import moment from 'moment';
import * as chrono from 'chrono-node';
import { has, findIndex, sortBy } from 'lodash';
import { mappingTable } from "src/utils/mappingTable";
import { stringify } from 'query-string';
const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const processFEIN = (fein) => {
    try {
        const onlyNumeric = fein.replace(/[^\d.-]/g, '');
        if (onlyNumeric.includes('-')) {
            return onlyNumeric;
        }
        return `${onlyNumeric.slice(0, 2)}-${onlyNumeric.slice(2)}`;
    } catch (err) {
        return ' ';
    }
};

const originalAmountkeys = ['overpayment_', 'total_', 'totals/balance_', 'amountdueuponreceipt_', 'totalfees_', 'amountoflien_', 'warrantamount_', 'totalperiodbalance_', 'accountbalance_', 'amountyouowe_',
    'currentbalancedue_', 'checkamount_', 'totals_', 'amount_', 'amountofoverpayment_', 'amountofrefund_', 'balance_', 'balancedue_', 'paythisamount_', 'paymentamount_',
    'amtdue_', 'totaldue_', 'totalamountdue_', 'amountdueimmediately_', "totalyouoweasof_, 'amountnowdue_', adjusted_", 'amountdue_', 'amount'];

const originalFEINkeys = ['corp', 'id_', 'entityid_', 'fein_', 'ein_', 'taxpayerid_', 'corporationnumber_', 'identificationnumber_'];
const originalDueDatekeys = ['finaldateforpayment_', 'duedate_', 'datedue_', 'replyduedate_', 'paymentdue_', 'taxduedate_', 'effectivedate_', 'dueDate'];

const selectAmount = (item, currentKeys) => {
    let amount;

    for (const key of originalAmountkeys) {
        if (currentKeys.includes(key)) {
            try {
                amount = parseFloat(item[key].replace('$', '').replace(',', '').replace('(', '').replace(')', '')).toFixed(2);
                amount = !isNaN(amount) ? amount : '-';
            } catch (err) {
                amount = '-';
            }
        }
    }

    return amount;
};

const selectBacklogAmount = (item, currentKeys) => {
    const amount = [];

    for (const key of currentKeys) {
        if (mappingTable.amount.includes(key.slice(0, -1))) {
            item[key].options.map(candidate => {
                const parsedCandidate = parseFloat(
                    candidate
                    .replace("$", "")
                    .replace(",", "")
                    .replace("(", "")
                    .replace(")", "")
                ).toFixed(2);
                if (!amount.includes(parsedCandidate) && !isNaN(parsedCandidate)) {
                    amount.push(parsedCandidate);
                }
            });
        }
    }

    return amount[0] || '-';
};

const selectDueDate = (item, currentKeys) => {
    let dueDate;

    for (const key of originalDueDatekeys) {
        if (currentKeys.includes(key)) {
            try {
                dueDate = item[key];
            } catch (err) {
                dueDate = '';
            }
        }
    }

    if (dueDate && dueDate !== 'None') {
        const timeObject = chrono.parseDate(dueDate);
        return moment(timeObject).format('MM-DD-YYYY');
    }

    return 'Not Specified';
};

const selectBacklogDueDate = (item, currentKeys) => {
    const dueDate = [];

    for (const key of currentKeys) {
        if (mappingTable.dueDate.includes(key.slice(0, -1))) {
            item[key].options.map(candidate => {
                const parsedCandidate = chrono.parseDate(candidate);
                if (!dueDate.includes(parsedCandidate)) {
                    dueDate.push(parsedCandidate);
                }
            });
        }
    }

    return dueDate[0] ? moment(dueDate[0]) : 'Not Specified';
    // return dueDate[0] ? moment(dueDate[0]).format('MM-DD-YYYY') : 'Not Specified';
}

const selectFEIN = (item, currentKeys, entity_data) => {
    if (Object.keys(item).includes('fein_')) {
        let fein;

        for (const key of originalFEINkeys) {
            if (currentKeys.includes(key)) {
                try {
                    fein = item[key];
                } catch (err) {
                    fein = '';
                }
            }
        }

        return processFEIN(fein);
    }
    try {
        const item_entity = item.entity;
        const entityIndex = entity_data.findIndex((entity) => entity.entity.toLowerCase() === item_entity.toLowerCase());
        return processFEIN(entity_data[entityIndex].fein);
    } catch (err) {
        return ' ';
    }
};

const localStorageReadItem = (key) => localStorage.getItem(key);

const localStorageSetItem = (key, value) => {
    console.log("ljsdlsajdlsal ")
    localStorage.setItem(key, value);
};

const localStorageRemoveItems = (items) => {
    console.log("items ", items)
    try {
        // items.forEach((item) => localStorage.removeItem(item));
    } catch (e) {
        console.log(e);
    }
};

const taxPeriodKey = [
    'tax year', 'tax year ending', 'taxable year(s)', 'tax years', 'tax yr(s) end', 'tax period', 'tax period end', 'tax period begin date', 'tax period began', 'determination period', 'tax period end date', 'tax account period', 'tax period ended', 'account period', 'account period beginning', 'account period ending', 'period end date', 'period ended', 'period ending', 'period', 'year ending', 'end date', 'liability period', 'filing period', 'reporting period', 'fiscal year end', 'income period', 'income year ending', 'calculated through', 'period covered by claim', 'taxPeriod',
];

const complyDynamoDB = (key) => {
    const non_alpha_chars = key.replace(/\W/g, '');
    return `${non_alpha_chars}_`;
};
const extractTaxYear = (noticeData) => {
    if (has(noticeData, 'taxPeriod')) {
        return noticeData.taxPeriod;
    }

    let returnYear;

    for (const key of taxPeriodKey) {
        if (has(noticeData, complyDynamoDB(key))) {
            try {
                if (noticeData[complyDynamoDB(key)].trim().length === 4) {
                    returnYear = noticeData[complyDynamoDB(key)];
                } else {
                    returnYear = chrono.parseDate(noticeData[complyDynamoDB(key)]).getFullYear();
                }
            } catch (err) {
                returnYear = 'Not Specified';
            }
        }
    }

    return returnYear;
};

const extractBacklogTaxYear = (item) => {
    const candidates = [];
    const oldShit = ["Value not found", "Not a data element"];

    Object.keys(item).forEach(key => {
        if (mappingTable.taxPeriod.includes(key.slice(0, -1))) {
            item[key].options.forEach(candidate => {
                try {
                    const parsedDate = chrono.parseDate(candidate);
                    if (!parsedDate) return;

                    const parsedCandidate = parsedDate.getFullYear();

                    if (
                    !candidates.includes(parsedCandidate) &&
                    !oldShit.includes(candidate)
                    ) {
                        candidates.push(parsedCandidate);
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
    });

    return candidates[0];
};

const formatBacklogs = backlogs => {
    for (const backlog of backlogs) {
        if (!backlog) continue;
        const currentKeys = Object.keys(backlog);
        backlog.amount_selected = selectBacklogAmount(backlog, currentKeys);
        backlog.duedate_selected = selectBacklogDueDate(backlog, currentKeys);
        backlog.taxyear_selected = extractBacklogTaxYear(backlog) || "Not Specified";
        backlog.taxpayer = (backlog.entity.recognized_entity || backlog.entity) || "Not Specified";
        backlog.sortTime = backlog.confirm_time || backlog.upload_time;
        backlog.assigneduser = "unassigned";
        backlog.noticetype = backlog.noticetype.charAt(0).toUpperCase() + backlog.noticetype.slice(1);
        backlog.backlog = true;
    }

    return backlogs;
};

const formatNotices = (notices, entities) => {
    for (const notice of notices) {
        const currentKeys = Object.keys(notice);
		notice.amount_selected = notice.amount || selectAmount(notice, currentKeys);
        notice.duedate_selected = selectDueDate(notice, currentKeys);
        notice.fein_selected = selectFEIN(notice, currentKeys, entities);
        notice.taxyear_selected = extractTaxYear(notice) || 'Not Specified';
        notice.taxpayer = notice.entity || "";
        notice.sortTime = notice.confirm_time || notice.upload_time;
        notice.backlog = false;

        if (!has(notice, "assigneduser")) {
            notice.assigneduser = "unassigned";
        }
    }

    return notices;
};

const constructCommentData = (raw) => {
    let comments = raw.filter((item) => !item.reply);
    const replies = raw.filter((item) => item.reply);
    replies.forEach((replyComment) => {
        const itemIndex = findIndex(comments, (comment) => comment.unique_id === replyComment.reply);
        comments[itemIndex].replyComments = [replyComment, ...(comments[itemIndex].replyComments || [])];
    });
    comments = sortBy(comments, ((item) => new Date(item.date).getTime())).reverse();
    comments = comments.map((item) => {
        item.replace = sortBy(item.replace, (reply) => new Date(reply.date).getTime()).reverse();
        return item;
    });
    return comments;
};

export {
    capitalize, processFEIN, selectAmount, selectBacklogAmount, selectDueDate, selectBacklogDueDate,
    selectFEIN, localStorageReadItem, localStorageSetItem, localStorageRemoveItems, extractTaxYear,
    extractBacklogTaxYear, formatBacklogs, formatNotices, constructCommentData
};
