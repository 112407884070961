import React from 'react';
import {
    Col, Form, Select
} from 'antd';

const { Option } = Select;

const StatusItem = ({ currentNotice }) => (
    <Col span={12}>
        <Form.Item
            label="Notice Status"
            name="noticestatus"
            key="noticestatus"
            initialValue={currentNotice.noticestatus}
        >
            <Select>
                <Option value="none">      None       </Option>
                <Option value="inprogress">In Progress</Option>
                <Option value="completed"> Completed  </Option>
            </Select>
        </Form.Item>
    </Col>
);

export default StatusItem;
