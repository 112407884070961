import React, { useContext } from 'react';
import { get } from 'lodash';
import { Layout, Row } from 'antd';
import { AuthContext } from 'src/services/AuthService/Authentication';
import Logo from './Logo';
import Import from '../Import';
import 'src/App.less';
import User from './User/User';

const { Header } = Layout;

const ProtectedHeader = () => {
    const { userInfo } = useContext(AuthContext);
    const userName = get(userInfo, 'attributes.name', 'User');
    const customerName = get(userInfo, 'attributes.custom:customer', 'User');
    let access = get(userInfo, 'attributes.custom:access', 'Normal');
    access = access.charAt(0).toUpperCase() + access.slice(1);

    return (
        <Header className="px-8 h-fit w-fit flex items-center bg-white ">
            <Logo className="mr-auto h-fit w-fit" />
            <div className="flex ml-auto">
                <Import />
                <User username={userName} customerName={customerName} />
            </div>
        </Header>
    );
};

export default ProtectedHeader;
