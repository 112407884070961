import React from 'react';
import { Comment, Divider, Tooltip, Typography } from 'antd';
import { convertTimeStamp } from 'src/utils/Date';
import { parseAttachmentFilename } from 'src/components/Comments/Comments';

import { PaperClipOutlined } from '@ant-design/icons';
import { COLORS } from 'src/theme/colors';

const { Text } = Typography;

export default ({
    author, content, date, attachments, handleDownload,
}) => {
    function renderAttachedFiles() {
        if (!attachments || attachments.length === 0) return null;
        return attachments.map((attach, index) => (
            <Tooltip
                placement="top"
                key={`Download ${index}`}
                title={`Download ${parseAttachmentFilename(attach)}`}
            >
                <PaperClipOutlined
                    about="ass"
                    style={{
                        fontSize: 16,
                        verticalAlign: 'top',
                        color: 'black'
                    }}
                    onClick={() => handleDownload(attach)}
                />
            </Tooltip>
        ));
    }
    return (
        <div className="flex flex-row content-center">
            <Comment
                className="text-black w-full"
                style={{
                    backgroundColor: COLORS.backgroundLight,
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-center'
                }}
                author={
                    <Text
                        style={{ color: COLORS.secondary }}>
                        {author.name} | <Tooltip title={convertTimeStamp(date).format('YYYY-MM-DD HH:mm:ss')}> {convertTimeStamp(date).fromNow()} </Tooltip> {renderAttachedFiles()}
                    </Text>}
                content={(
                    <>
                        <Divider className="my-1 bg-white" />
                        <div className="flex items-start">
                            <p>{content}</p>
                        </div>
                    </>
                )}
            />
        </div>
    );
};
