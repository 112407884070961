import React, { useState } from "react";
import { Typography, Form, Select, Input, Divider, Button } from "antd";
import { mappingTable } from "src/utils/mappingTable";

const { Option } = Select;
const { Title } = Typography;

const AmountItem = props => {
  const { currentNotice } = props;
  const [name, setName] = useState("");
  const noticeDateKeys = mappingTable.amount;
  const candidates = [];

  const addItem = () => {
    setName("");
    props.onCreate(name);
  };

  const onNameChange = event => {
    const re = /^(?!,$)[\d,.]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setName(event.target.value);
    }
  };

  Object.keys(currentNotice).map(key => {
    if (noticeDateKeys.includes(key.slice(0, -1))) {
      currentNotice[key].options.map(candidate => {
        const parsedCandidate = parseFloat(
          candidate
            .replace("$", "")
            .replace(",", "")
            .replace("(", "")
            .replace(")", "")
        ).toFixed(2);
        if (!candidates.includes(parsedCandidate)) {
          candidates.push(parsedCandidate);
        }
      });
    }
  });

  return (
    <div className="flex items-center">
      <div className="w-4/12">
        <Title level={5}>Amount</Title>
      </div>
      <div className="w-8/12">
        <Form.Item
          name="amount"
          key="amount"
          initialValue={candidates[0] || null}
        >
          {/* <Select>
            {candidates.map(candidate => (
              <Option value={candidate}> {candidate} </Option>
            ))}
          </Select> */}
          <Select
            placeholder="Select amount"
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div style={{ padding: 8 }}>
                  <p>Enter manually</p>
                  <div style={{ display: "flex", flexWrap: "nowrap" }}>
                    <Input
                      placeholder="Enter amount..."
                      value={name}
                      onChange={onNameChange}
                      style={{
                        border: "1px solid #ccc",
                        flex: "auto",
                        marginRight: 5
                      }}
                    />
                    <Button onClick={addItem} type="primary">
                      Ok
                    </Button>
                  </div>
                </div>
              </div>
            )}
          >
            {candidates.map(item => {
              return (
                <Option key={item} value={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};

export default AmountItem;
