import React, { useContext, useEffect } from 'react';
import { Form, Row, Button, message } from 'antd';
import { states } from 'src/utils/States';
import EntityNameItem from './FormItems/EntityNameItem';
import FEINItem from './FormItems/FEINItem';
import LocalIDItem from './FormItems/LocalIDItem';
import StateItems from './FormItems/StateItems';
import CustomDrawer from 'src/components/shared/Drawer';
import FetchContext from 'src/services/FetchService/FetchContext';
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

const Update = ({ entityData, visibility, setVisibility }) => {
    const { entitymanagement: { postUpdateEntity } } = useContext(FetchContext);
    const { updateEntityState } = useContext(GlobalStateContext);
    const formRef = React.createRef();
    const [ form ] = Form.useForm();

    useEffect(() => {
        if (formRef.current) {
            const data = handleFormData(entityData);
            
            form.setFieldsValue(data);
        }
    }, [formRef]);

    const handleFormData = data => {
        data.states = [];
        for (var key in data) {
            if (data.hasOwnProperty(key) && states.includes(key)) {
                data.states.push({
                    state: key,
                    stateid: data[key]
                });
            }
        }

        return data;
    };

    const handleUpdateEntity = () => {
        form
        .validateFields()
        .then(values => {
            updateEntity(values);
            setVisibility(false);
        })
        .catch(info => {
            console.log("Validate Failed:", info);
        });
    };

    const updateEntity = async (values) => {
        const data = handleRequestData(values);

        data.old_entity_name = entityData.entity;
        Object.keys(data).forEach((key) => (data[key] === '') && delete data[key]);

        try {
            await postUpdateEntity(data);
            updateEntityState(data);

            message.success(
                "Entity has been updated successfully"
            );
        } catch (err) {
            console.log(err);
            message.error("Something went wrong. Please try again later");
        }
    };

    const handleRequestData = values => {
        Object.keys(values).forEach(
            key => values[key] === undefined && delete values[key]
        );
        
        if('states' in values) {
            values['states'].forEach(function (name) {
                let key = name.state;
                let value = name.stateid;
                values[key] = value;
            });

            delete values['states'];
        }

        return values;
    };

    return (
        <>
            <CustomDrawer width={720} visibility={visibility} handleOnClose={() => setVisibility(false)}>
                <div className="p-20">
                    <Form
                        className="compact pt-8 px-6 pb-0"
                        layout="vertical"
                        form={form}
                        ref={formRef}
                        onFinish={handleUpdateEntity}
                        hideRequiredMark
                    >
                        <EntityNameItem />

                        <Row gutter={15}>
                            <FEINItem />
                            <LocalIDItem />
                        </Row>

                        <StateItems />

                        <Row justify="end" className="mt-8">
                            <Button type="primary" htmlType="submit" size="large">
                                Save Entries
                            </Button>
                        </Row>
                    </Form>
                </div>
            </CustomDrawer>
        </>
    );
};

export default Update;
