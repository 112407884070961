import React from "react";
import { Card } from "antd";

const Paper = ({ children, className, dark, ...rest }) => {
  return (
    <Card
      bordered={false}
      className={`paper ${className} ${dark ? "dark" : ""}`}
      {...rest}
    >
      {children}
    </Card>
  );
};

export default Paper;
