import React from 'react';
import moment from 'moment';
import * as chrono from "chrono-node";
import { Col, Form, DatePicker } from 'antd';
import { mappingTable } from "src/utils/mappingTable";

const NoticeDateItem = ({ currentBacklog }) => {
    const keys = mappingTable.noticeDate;
    const options = [];

    Object.keys(currentBacklog).map(key => {
        if (keys.includes(key.slice(0, -1))) {
            options.push(currentBacklog[key].options[0]);
        }
    });

    const proposedNoticeDate = moment(chrono.parseDate(options[0]));

    return (
        <Col span={12}>
            <Form.Item
                label="Notice Date"
                name="noticeDate"
                key="noticeDate"
                initialValue={proposedNoticeDate.isValid() ? proposedNoticeDate : null}
            >
                <DatePicker
                    style={{ width: 180 }}
                />
            </Form.Item>
        </Col>
    );
}

export default NoticeDateItem;
