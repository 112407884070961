import React, { useState, useRef } from "react";
import { Typography, Form, Select, Input, Divider, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { mappingTable } from "src/utils/mappingTable";

const { Title } = Typography;
const { Option } = Select;

const oldShit = ["Value not found", "Not a data element"];

const NoticeDateItem = props => {
  const { currentNotice } = props;
  const keys = mappingTable.noticeID;
  const candidates = [];

  const [name, setName] = useState("");

  const selectRef = useRef();
  const onNameChange = event => {
    setName(event.target.value);
  };
  const addItem = () => {
    console.log(selectRef.current);
    selectRef.current.focus();
    setName("");
    props.onCreate(name);
  };

  Object.keys(currentNotice).map(key => {
    if (keys.includes(key.slice(0, -1))) {
      currentNotice[key].options.map(candidate => {
        if (!candidates.includes(candidate) && !oldShit.includes(candidate)) {
          candidates.push(candidate);
        }
      });
    }
  });

  return (
    <div className="flex items-center">
      <div className="w-4/12">
        <Title level={5}>Notice ID</Title>
      </div>
      <div className="w-8/12">
        <Form.Item
          name="noticeID"
          key="noticeID"
          initialValue={candidates[0] || null}
        >
          <Select
            placeholder="Select ID..."
            ref={selectRef}
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div style={{ padding: 8 }}>
                  <p>Enter manually</p>
                  <div style={{ display: "flex", flexWrap: "nowrap" }}>
                    <Input
                      placeholder="Enter ID..."
                      value={name}
                      onChange={onNameChange}
                      style={{
                        border: "1px solid #ccc",
                        flex: "auto",
                        marginRight: 5
                      }}
                    />
                    <Button onClick={addItem} type="primary">
                      Ok
                    </Button>
                  </div>
                </div>
              </div>
            )}
          >
            {candidates.map(item => {
              return (
                <Option key={item} value={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};

export default NoticeDateItem;
