import React, { useState, useEffect } from 'react';
import { Breadcrumb, Modal } from 'antd';
import { Storage } from 'aws-amplify';
import ShowScrollablePDF from './ShowScrollablePdf';

const generateFileName = (raw) => {
    if (!raw) return '';
    const rawName = raw.split('/')[1];
    if (!rawName) return '';
    return decodeURI(rawName);
};

const LeftPane = (props) => {
    const {
        currentNotice,
    } = props;
    const imagePath = `${currentNotice.original_filename.split('/')[1]}/${currentNotice.original_filename.split('/')[2]}&&${currentNotice.unique_id}.pdf`;
    const [image, setImage] = useState(null);
    const [visible, setVisible] = useState(false);

    const fetchImage = () => {
        Storage.get(imagePath, { level: 'public' })
            .then((data) => setImage(data))
            .catch((err) => console.log(err));
    };
    useEffect(() => {
        fetchImage();
    }, [imagePath]);
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item href="" />
                <Breadcrumb.Item>
                    Backlog
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {decodeURI(currentNotice.original_filename).split('.')[0].split('/')[2]}
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="notice-pdf" style={{ paddingTop: '1em' }}>
                <ShowScrollablePDF
                    onClick={() => {
                        setVisible(true);
                    }}
                    image={image}
                    currentNotice={currentNotice}
                />
            </div>
            <Modal
                title="Enhanced Notice View"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width="100vh"
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Done"
                maskClosable
            >
                <LeftPane
                    onClick={() => {
                        setVisible(true);
                    }}
                    currentNotice={currentNotice}
                />
            </Modal>
        </>
    );
};

export default LeftPane;
