import React, { useState, useContext } from "react";
import { Button, Drawer, message } from "antd";
import StatusTable from './StatusTable/StatusTable';
import Upload from './Upload';
import FetchContext from 'src/services/FetchService/FetchContext';
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

import {
  	CloudUploadOutlined
} from "@ant-design/icons";

const Import = () => {
	let intervalFn;
	const [ visibility, setVisibility ] = useState(false);
	const [ drawerWidth, setDrawerWidth ] = useState(368);
	const { reFetch } = useContext(GlobalStateContext);
	const { noticeStatus: { getNoticeStatus } } = useContext(FetchContext);
	const [ tableData, setTableData ] = useState(null);

	const handleOpenDrawer = () => {
		setVisibility(true);
		getData();
	}

	const handleCloseDrawer = () => {
		setVisibility(false);
		setDrawerWidth(368);
		setTableData(null);
	}

	const interval = () => {
		if(!intervalFn) {
			intervalFn = setInterval(() => checkNoticeStatus(), 3000);
		}
	};

	const getData = async () => {
		try {
			const results = await getNoticeStatus();

			if(results.length) {
				setTableData(results);
				setDrawerWidth(1200);
			} else {
				setTableData(null);
				setDrawerWidth(368);
			}
		} catch (err) {
			console.log(err);
		}
    };

	const checkNoticeStatus = async () => {
		try {
			const results = await getNoticeStatus();

			if(results.length) {
				setTableData(results);
				setDrawerWidth(1200);				
			} else {
				clearInterval(intervalFn);
				setTableData(null);
				setDrawerWidth(368);
				await reFetch();
				message.success('Notice(s) imported successfully');
			}
		} catch (err) {
			console.log(err);
		}
    };

	return (
		<div className="hidden inline-flex lg-block">
			<Button className="p-5" type="primary" onClick={() => handleOpenDrawer()}>
				<CloudUploadOutlined />
				Upload notices
			</Button>
			<Drawer
				visible={visibility}
				width={drawerWidth}
				onClose={() => handleCloseDrawer()}
				bodyStyle={{ padding: 0 }}
			>
				{ tableData 
				? <div className="notice-status-wrapper inline-flex h-full">
					<StatusTable
						tableData={tableData}
					/>
				</div>
				: null }
				<Upload startInterval={interval} />
			</Drawer>
		</div>
	);
};

export default Import;
