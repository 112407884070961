import React, { useState, useRef } from 'react';
import { Col, Form, Input, Select, Divider, Button } from 'antd';
import { mappingTable } from "src/utils/mappingTable";

const { Option } = Select;
const oldShit = ["Value not found", "Not a data element"];

const NoticeIDItem = ({ currentBacklog, onCreate }) => {
    const [name, setName] = useState("");
    const keys = mappingTable.noticeID;
    const candidates = [];

    const selectRef = useRef();

    const onNameChange = event => {
        setName(event.target.value);
    };

    const addItem = () => {
        selectRef.current.focus();
        setName("");
        onCreate(name);
    };

    Object.keys(currentBacklog).map(key => {
        if (keys.includes(key.slice(0, -1))) {
            currentBacklog[key].options.map(candidate => {
                if (!candidates.includes(candidate) && !oldShit.includes(candidate)) {
                    candidates.push(candidate);
                }
            });
        }
    });

    return (
        <Col span={12}>
            <Form.Item
                label="Notice ID"
                name="noticeID"
                key="noticeID"
                initialValue={candidates[0] || null}
            >
                <Select
                    placeholder="Select ID..."
                    ref={selectRef}
                    dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: "4px 0" }} />
                            <div style={{ padding: 8 }}>
                                <p>Enter manually</p>
                                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                                    <Input
                                        placeholder="Enter ID..."
                                        value={name}
                                        onChange={onNameChange}
                                        style={{
                                            border: "1px solid #ccc",
                                            flex: "auto",
                                            marginRight: 5
                                        }}
                                    />
                                    <Button onClick={addItem} type="primary">Ok</Button>
                                </div>
                            </div>
                        </div>
                    )}
                >
                    {candidates.map(item => {
                        return (
                            <Option key={item} value={item}>
                                {item}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </Col>
    );
};

export default NoticeIDItem;
