import { find, get } from 'lodash';

/* Actions */
const READ = 'read';
const DELETE = 'delete';
const WRITE = 'write';
const ADMIN = 'admin';
const DOWNLOAD = 'download';

/* Categories */
const NOTICE_MANAGEMENT = 'notice_management';
const PERMISSION = 'permission';
const BACKLOG = 'backlog';
const REMARK = 'remark';
const REMARK_ATTACHMENT = 'remark_attachment';
const USER = 'user';
const ORGANIZATION = 'organization';

/* Helper attributes */
export const categories = {
    NOTICE_MANAGEMENT, PERMISSION, BACKLOG, REMARK, REMARK_ATTACHMENT, USER, ORGANIZATION,
};
export const actions = {
    READ,
    DELETE,
    WRITE,
    ADMIN,
    DOWNLOAD,
};

export const WritePermissionScope = {
    category: categories.PERMISSION,
    action: actions.WRITE,
};

export const ReadPermissionScope = {
    category: categories.PERMISSION,
    action: actions.READ,
};

export const ReadOrganizationScope = {
    category: categories.ORGANIZATION,
    action: actions.READ,
};

export const WriteOrganizationScope = {
    category: categories.ORGANIZATION,
    action: actions.WRITE,
};

export const RemarkDownloadScope = {
    category: categories.REMARK_ATTACHMENT,
    action: actions.DOWNLOAD,
};

export const UploadAttachmentRemarkScope = {
    category: categories.REMARK_ATTACHMENT,
    action: actions.WRITE,
};

export const AdminMenuScope = {
    category: categories.PERMISSION,
    action: actions.ADMIN,
};

export const DownloadNoticeScope = {
    category: categories.NOTICE_MANAGEMENT,
    action: actions.DOWNLOAD,
};

export const ReadNoticeScope = {
    category: categories.NOTICE_MANAGEMENT,
    action: actions.READ,
};

export const UploadNoticeScope = {
    category: categories.BACKLOG,
    action: actions.WRITE,
};

export const ReadBacklogScope = {
    category: categories.BACKLOG,
    action: actions.READ,
};

export const UpdateBacklogScope = {
    category: categories.BACKLOG,
    action: actions.WRITE,
};

export const DeleteBacklogScope = {
    category: categories.BACKLOG,
    action: actions.DELETE,
};

export const DownloadBacklogScope = {
    category: categories.BACKLOG,
    action: actions.DOWNLOAD,
};

export const ReadRemarks = {
    category: categories.REMARK,
    action: actions.READ,
};

export const WriteRemarks = {
    category: categories.REMARK,
    action: actions.WRITE,
};

/* Permission checker function */
export function has(scope) {
    if (!scope || !scope.action || !scope.category) return false;
    const { permissions, name } = this;

    if (name === ADMIN) return true;
    const permission = find(permissions, (item) => item.section === scope.category);
    const permissionActions = get(permission, 'actions', []);
    return !!(permissionActions.includes(ADMIN)
      || permissionActions.includes(scope.action));
}
