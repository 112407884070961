import React from "react";
import { Menu,	Badge } from "antd";
import { COLORS } from "src/theme/colors";

const Tabs = ({ tableTab, setTableTab, getCount }) => {
  	return (
			<Menu selectedKeys={[tableTab]} className="notice-menu" mode="horizontal" onClick={e => setTableTab(e.key)}>
			<Menu.Item key="backlog" style={{ color: COLORS.danger, borderRadius: 0 }}>
				NEW <Badge size="small" count={getCount("backlog")}></Badge>
			</Menu.Item>
			<Menu.Item key="confirm" style={{ color: COLORS.text, borderRadius: 0 }}>
				NOTICES <Badge size="small" count={getCount("confirm")} style={{ backgroundColor: COLORS.backgroundDark }}></Badge>
			</Menu.Item>
			<Menu.Item key="review" style={{ color: COLORS.primary, borderRadius: 0 }}>
				REVIEW <Badge size="small" count={getCount("review")} style={{ backgroundColor: COLORS.primary }}></Badge>
			</Menu.Item>
			<Menu.Item key="deposit" style={{ color: COLORS.primary, borderRadius: 0 }}>
				DEPOSIT <Badge size="small" count={getCount("deposit")} style={{ backgroundColor: COLORS.primary }}></Badge>
			</Menu.Item>
			<Menu.Item key="payment" style={{ color: COLORS.primary, borderRadius: 0 }}>
				PAYMENT <Badge size="small" count={getCount("payment")} style={{ backgroundColor: COLORS.primary }}></Badge>
			</Menu.Item>
			<Menu.Item key="done" style={{ color: COLORS.dark, borderRadius: 0 }}>
				DONE <Badge size="small" count={getCount("done")} style={{ backgroundColor: COLORS.dark }}></Badge>
			</Menu.Item>
		</Menu>
  	);
};

export default Tabs;
