import { css } from '@emotion/core';

export const PocketViewCSS = css`
    background: 
        linear-gradient(#F9FAFC 33%, rgba(255,255,255, 0)),
        linear-gradient(rgba(255,255,255, 0), #F9FAFC 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.5), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.5), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
    max-height: 60vh;
`;
