import React from 'react';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { getPermission } from 'src/services/FetchService/APIs/permission';
import { has } from 'src/services/PermissionService';
import { AuthContext } from './Authentication';

class AuthProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            token: '',
            loading: true,
            permissions: {},
        };
        this.awsAuth = Auth;
    }

    componentDidMount() {
        let provider
        this.awsAuth.currentAuthenticatedUser().then((userInfo) => {
            const { signInUserSession } = userInfo;
            // if(userInfo.attributes['custom:access']=='SuperAdmin'){
                //     this.setState({
                    //         loading: false,
                    //         userInfo,
                    //     });
                    //     return userInfo;
                    // } else {

            if (!userInfo.attributes['custom:permission']) {
                provider = userInfo.username.split('_')[0]
                this.handleSingleSignOn(provider);
            }
            return this.fetchPermissions({ token: get(signInUserSession, 'idToken.jwtToken') }).then((permissions) => {
                this.setState({
                    userInfo,
                    isAuthenticated: true,
                    token: get(signInUserSession, 'idToken.jwtToken'),
                    permissions,
                    loading: false,
                });
              setInterval(() => {
                this.refreshTokens();
              }, 1000 * 60 * 15); // Check token expiration every 15 minutes
            });
            // }
        }).catch((e) => {
            console.log('componentDidMount failed:', e)
            this.handleErrorAuthAndPermission();
        });
    }

        refreshTokens() {
            const { token } = this.state;
            if (token) {
                this.awsAuth.currentAuthenticatedUser().then((userInfo) => {
                    const { signInUserSession } = userInfo;
                    this.setState({
                        token: get(signInUserSession, "idToken.jwtToken"),
                    });
                },
                ).catch((e) => {
                    this.handleErrorAuthAndPermission();
                }
                );
            }
        }

    componentWillUnmount() {
        this.isAuthenticated = null;
    }

    handleErrorAuthAndPermission() {
        this.setState({
            loading: false,
            isAuthenticated: false,
            permissions: {},
        }, () => this.handleLogout());
    }

    async fetchPermissions({ token }) {
        try {
            return await getPermission({ token })();
        } catch (e) {
            // @TODO Handle so that if a user doesnt have permission object, they get redirected to login screen or a alert page.
            console.log('fetchPermissions failed', e)
            return Promise.reject(e);
        }
    }

    async handleLogin({ username, password }) {
        try {
            let userInfo = await this.awsAuth.signIn(username, password);
            let { signInUserSession } = userInfo;
            // if(userInfo.attributes['custom:access']=='SuperAdmin'){
            //     this.setState({
            //         userInfo,
            //     });
            //     if(!userInfo.attributes['custom:permission']){
            //         try {
            //             const permissions = await this.fetchPermissions({ token: get(signInUserSession, 'idToken.jwtToken') });
            //             console.log('permissions', permissions)
            //         } catch (error) {
            //             await this.awsAuth.signOut();
            //             userInfo = await this.awsAuth.signIn(username, password);
            //             signInUserSession   = userInfo.signInUserSession;
            //         }
            //     }
            //     const permissions = await this.fetchPermissions({ token: get(signInUserSession, 'idToken.jwtToken') });
            //     console.log('permissions', permissions)
            //     return Promise.resolve();
            // } else {
                if(!userInfo.attributes['custom:permission']){
                    try {
                        await this.fetchPermissions({ token: get(signInUserSession, 'idToken.jwtToken') });
                    } catch (error) {
                        await this.awsAuth.signOut();
                        userInfo = await this.awsAuth.signIn(username, password);
                        signInUserSession   = userInfo.signInUserSession;
                    }
                }
                const permissions = await this.fetchPermissions({ token: get(signInUserSession, 'idToken.jwtToken') });
                userInfo = await this.awsAuth.signIn(username, password);
                signInUserSession = userInfo.signInUserSession;
                this.setState({
                    isAuthenticated: true,
                    permissions,
                    token: get(signInUserSession, 'idToken.jwtToken'),
                    userInfo,
                });
                return Promise.resolve();
            // }
        } catch (e) {
            console.log('error: handleLogin', e)
            this.handleErrorAuthAndPermission();
            return Promise.resolve(e);
        }
    }

    async handleLogout() {
        try {
            await this.awsAuth.signOut();
            this.setState({
                isAuthenticated: false,
                token: ""
            });
            localStorage.removeItem('company');
            return Promise.resolve();
        } catch (e) {
            console.log('error signing out: ', e);
            return Promise.reject(e);
        }
    }

    async handleSignup(email, password) {

    }

    async handleSingleSignOn(provider){
        try {
            await this.awsAuth.federatedSignIn(
            {
                provider: provider
            }
             );
            return Promise.resolve();
        } catch (e) {
            this.handleErrorAuthAndPermission();
            return Promise.resolve(e);
        }
    }

    render() {
        this.state.permissions.has = has;
        const providerValue = {
            token: this.state.token,
            permissions: this.state.permissions,
            isAuthenticated: this.state.isAuthenticated,
            login: this.handleLogin.bind(this),
            ssoLogin: this.handleSingleSignOn.bind(this),
            logout: this.handleLogout.bind(this),
            signup: this.handleSignup.bind(this),
            loading: this.state.loading,
            userInfo: this.state.userInfo,
        };
        return <AuthContext.Provider value={providerValue}>{this.props.children}</AuthContext.Provider>;
    }
}

export { AuthContext, AuthProvider };
