import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import {
    Row, Col, Form, Input, Button, message
} from 'antd';
import Paper from 'src/components/shared/Paper';
import Logo from 'src/components/shared/logo';

const ForgotPasswordSubmit = () => {
    const locationSearch = useLocation().search;
    const history = useHistory();
    const [error, setError] = useState(null);
    const [errorStatus, setErrorStatus] = useState(null);
    const handlePasswordSubmit = (values) => {
        const queryParams = parse(locationSearch);
        Auth.forgotPasswordSubmit(
            queryParams.username,
            queryParams.code,
            values.newpassword,
        ).then(
            (response) => {
                message.info('Your password has been updated successfully!');
                history.push('/login');
            },
        )
            .catch((err) => {
                setErrorStatus('error');
                setError(err.message);
            });
    };
    return (
        <Row align="middle" justify="center" style={{ height: "100vh" }}>
            <Col md={14} xl={10} xxl={10} xs={20}>
                <Paper>
                    <Row gutter={15}>
                        <Col md={12} xs={24}>
                            <Row align="middle" justify="center" className="h-full">
                                <Logo width={163} />
                            </Row>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form
                                name="forgotPasswordSubmit"
                                layout="vertical"
                                style={{ paddingTop: 55 }}
                                onFinish={handlePasswordSubmit}
                                hideRequiredMark
                            >
                                <Form.Item
                                    name="newpassword"
                                    validateStatus={errorStatus}
                                    help={error}
                                    rules={[
                                        { required: true, message: "Please input your new password!" },
                                    ]}
                                    >
                                    <Input.Password />
                                </Form.Item>

                                <Row justify="center">
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                    >
                                        Submit new password
                                    </Button>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Paper>
            </Col>
        </Row>
    );
};

export default ForgotPasswordSubmit;
