import React, { useEffect } from 'react';
import { API } from 'aws-amplify';
import {
    Form, Row, Button, message
} from 'antd';
import CustomDrawer from 'src/components/shared/Drawer';
// import NameItem from './FormItems/NameItem';
import AccessLevelItem from './FormItems/AccessLevelItem';

const Update = (props) => {
    const {
        visibility, setVisibility, userData, onDone
    } = props;

    const formRef = React.createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldsValue(userData);
        }
    }, [formRef]);

    const handleUpdate = (values) => {
        const apiName = 'stream';
        const path = '/update_user';
        const myInit = {
            body: JSON.stringify(values),
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods': 'POST',
            },
        };
        API
            .post(apiName, path, myInit)
            .then(() => {
                message.success(
                    "User has been updated successfully"
                );
                onDone();
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    const onSubmit = () => {
        form
        .validateFields()
        .then((values) => {
            values.username = userData.username;
            handleUpdate(values);
            setVisibility(false);
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });
    };

    return (
        <>
            <CustomDrawer
                width={720}
                visibility={visibility}
                handleOnClose={() => setVisibility(false)}
            >
                <div className="p-20">
                    <Form
                        className="compact pt-8 px-6 pb-0"
                        layout="vertical"
                        form={form}
                        ref={formRef}
                        onFinish={onSubmit}
                        hideRequiredMark
                    >
                        <Row gutter={15}>
                            {/* <NameItem /> */}
                            <AccessLevelItem />
                        </Row>
                        <Row justify="end" className="mt-8">
                            <Button type="primary" htmlType="submit" size="large">
                                Save Entries
                            </Button>
                        </Row>
                    </Form>
                </div>
            </CustomDrawer>
        </>
    );
};

export default Update;
