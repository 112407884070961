import React from "react";
import icon from "../../assets/img/angle-down.svg";

const AngleDown = () => {
  return (
    <img src={icon} alt="angle-down" width="16px" height="16px" />
  );
};

export default AngleDown;
