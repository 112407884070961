import React, { useState } from 'react';
import { Form, Select, Col, Avatar } from 'antd';
import { states, abbreviation } from 'src/utils/States';

const { Option } = Select;

const TaxAuthorityItem = ({ currentBacklog, onChange }) => {
    const [selectedState, setSelectedState] = useState(abbreviation[states.indexOf(currentBacklog.taxauthority)]);

    const renderStateImg = () => {
        try {
            return (
                <>
                    <img
                        src={require(`../../../../../../assets/img/states/${selectedState.toLowerCase()}.svg`)}
                        alt={selectedState}
                    />
                    <Avatar size="large" className="state-avatar">
                        <span>
                            {selectedState[0]}
                            <br />
                            {selectedState[1]}
                        </span>
                    </Avatar>
                </>
            );
        } catch (e) {
            console.log(e)
            return "";
        }
    };

    const handleStateChange = (value) => {
        setSelectedState(abbreviation[states.indexOf(value)]);
        onChange(value);
    }
    
    return (
        <>
            <Col flex="auto">
                <Form.Item
                    label="Tax Authority"
                    name="taxauthority"
                    key="taxauthority"
                    initialValue={currentBacklog.taxauthority}
                >
                    <Select showSearch onChange={handleStateChange}>
                        {states.map(item => (
                            <Option key={item} value={item}>
                                {item}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col className="state-img-container" flex="250px">
                {renderStateImg()}
            </Col>
        </>
    );
};
export default TaxAuthorityItem;
