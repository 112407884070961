import React from "react";
import { Drawer } from "antd";

const CustomDrawer = ({ width, visibility, handleOnClose, children }) => {
	return (
		<Drawer
			visible={visibility}
			className="dark-theme"
			width={width}
			onClose={() => handleOnClose()}
		>
			{children}
		</Drawer>
	);
};

export default CustomDrawer;
