import React from 'react';
import {
    Col, Form, Select
} from 'antd';

const { Option } = Select;

const TaxPeriodItem = (props) => {
    const { currentNotice } = props;

    const taxYears = ['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'];

    return (
        <Col span={12}>
            <Form.Item
                label="Tax Period"
                name="taxPeriod"
                key="taxPeriod"
                initialValue={currentNotice.taxyear_selected}
            >
                <Select>
                    {
                        taxYears.map((candidate) => (
                            <Option value={candidate} key={candidate}>
                                {' '}
                                {candidate}
                                {' '}
                            </Option>
                        ))
                    }
                </Select>
            </Form.Item>
        </Col>
    );
};

export default TaxPeriodItem;
