import React from "react";
import { Select } from "antd";

import { years } from "src/utils/Years";

const { Option } = Select;

const yearChildren = [];
years.map(year => yearChildren.push(<Option key={year}>{year}</Option>));

const TaxYear = ({ cachedValues, handleYearChange }) => (
  	<Select
		mode="multiple"
		placeholder="Tax Year"
		maxTagCount="responsive"
		className="w-full"
		onChange={handleYearChange}
		defaultValue={cachedValues}
  	>
    	{yearChildren}
  	</Select>
);

export default TaxYear;
