import React from 'react';
import { Storage } from 'aws-amplify';
import { Button } from 'antd';

export function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

const DownloadNotice = ({ currentNotice }) => {
    const imagePath = `${currentNotice.original_filename.split('/')[1]}/${currentNotice.original_filename.split('/')[2]}&&${currentNotice.unique_id}.pdf`;
    const downloadFile = () => {
        Storage.get(imagePath, { download: true, level: 'public' })
            .then((res) => downloadBlob(res.Body, decodeURI(currentNotice.original_filename.split('/')[2])))
            .catch((err) => console.log(err));
    };
    return (
        <Button onClick={downloadFile}>
            Download File
        </Button>
    );
};

export default DownloadNotice;
