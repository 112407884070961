import React from 'react';
import { useDropzone } from 'react-dropzone';
import Paper from '../../components/shared/Paper';
import UploadInfo from './UploadInfo';
import { Auth, Storage } from 'aws-amplify';

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

const Upload = ({ startInterval }) => {
    const onDrop = async (acceptedFiles) => {
        const authcontext = await Auth.currentAuthenticatedUser();
        const { username } = authcontext;
        const customer = authcontext.attributes['custom:customer'];

        acceptedFiles.forEach((file) => {
            const uuid = uuidv4();
            let cleanUsername = authcontext.attributes['sub']
            let filename = `${process.env.REACT_APP_USER_BRANCH || 'dev'}/${encodeURIComponent(file.path)}&&${cleanUsername}_${customer}_${uuid}.pdf`;
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsBinaryString(file);
            reader.onload = () => {
                Storage.put(filename, file, {
                    level: 'public',
                })
                .then(() => {
                    startInterval();
                }).catch((err) => {
                    console.log('error uploading files!', err);
                });
            };
        });
    };

    const {
        getRootProps, getInputProps,
    } = useDropzone({ onDrop, accept: '.pdf' });

    return (
        <Paper dark style={{ maxWidth: 360 }} className="notice-upload inline-flex h-full">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="p-6 items-center justify-center text-center border-dashed border-2 rounded-25 ant-upload ant-upload-drag dark text-success">
                    <p>Drag and drop notices here, or click to select files</p>
                    <em>(Only notices in PDF format will be accepted)</em>
                </div>
            </div>

            <UploadInfo />
        </Paper>
    );
};

export default Upload;
