import React, { useState } from 'react';
import {
    Row, Col, Form, InputNumber, Select, Divider, Button
} from 'antd';
import { mappingTable } from "src/utils/mappingTable";
import dollarImg from "../../../../../../assets/img/dollar-icon.svg";
import { numFormat } from "../../../../../../utils/numFormat"

const { Option } = Select;

const AmountItem = props => {
    const { currentBacklog } = props;
    const [ amounts, setAmounts ] = useState([]);
    const [ manualAmount, setManualAmount ] = useState("");
    const noticeDateKeys = mappingTable.amount;
    const candidates = [];

    const onManualAmountChange = value => {
        setManualAmount(value);
    };

    const addItem = () => {
        setManualAmount("");
        props.onCreate(manualAmount);
    };

    for (const key of Object.keys(currentBacklog)) {
        if (noticeDateKeys.includes(key.slice(0, -1))) {
            currentBacklog[key].options.map(candidate => {
                const parsedCandidate = parseFloat(
                    candidate
                    .replace("$", "")
                    .replace(",", "")
                    .replace("(", "")
                    .replace(")", "")
                ).toFixed(2);
                if (!candidates.includes(parsedCandidate) && !isNaN(parsedCandidate)) {
                    candidates.push(parsedCandidate);
                }
            });
        }
    }

    return (
        <div className="notice-edit-summary mt-8" style={{ backgroundImage: `url('${dollarImg}')` }}>
            <Row gutter={20}>
                <Col span={12}>
                    <Row
                        className="h-full flex-col"
                        justify="space-between"
                    >
                    </Row>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Amount"
                        name="amount"
                        key="amount"
                        initialValue={candidates[0] || null}
                    >
                        <Select
                            placeholder="Select amount"
                            dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <div style={{ padding: 8 }}>
                                        <p>Enter manually</p>
                                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                                            <InputNumber
                                                placeholder="Enter amount..."
                                                value={manualAmount}
                                                onChange={onManualAmountChange}
                                                formatter={numFormat}
                                                min={0}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{
                                                    border: "1px solid #ccc",
                                                    flex: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            <Button onClick={addItem} type="primary">Ok</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        >
                            {candidates.map(item => {
                                return (
                                    <Option key={item} value={item}>
                                        {item.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

export default AmountItem;
