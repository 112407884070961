import React from 'react';
import { AuthContext } from 'src/services/AuthService/Authentication';
import FetchContext from './FetchContext';
import * as comments from './APIs/comments';
import * as backlog from './APIs/backlog';
import * as noticeStatus from './APIs/noticeStatus';
import * as backlogconfirmation from './APIs/backlogconfirmation';
import * as noticemanagement from './APIs/noticemanagement';
import * as entitymanagement from './APIs/entitymanagement';
import * as deleteBacklog from './APIs/deleteBacklog';
import * as permission from './APIs/permission';

const buildApis = (authContext, api) => {
    const parsed = {};
    Object.keys(api).forEach((func) => typeof (api[func]) === 'function' && (parsed[func] = api[func](authContext)));
    return parsed;
};

function AuthProvider(props) {
    return (
        <AuthContext.Consumer>
            {
                (authContext) => (
                    <FetchContext.Provider
                        value={{
                            comments: buildApis(authContext, comments),
                            backlog: buildApis(authContext, backlog),
                            backlogconfirmation: buildApis(authContext, backlogconfirmation),
                            noticeStatus: buildApis(authContext, noticeStatus),
                            noticemanagement: buildApis(authContext, noticemanagement),
                            entitymanagement: buildApis(authContext, entitymanagement),
                            deleteBacklog: buildApis(authContext, deleteBacklog),
                            permission: buildApis(authContext, permission),
                        }}
                    >
                        { props.children }
                    </FetchContext.Provider>
                )
            }

        </AuthContext.Consumer>
    );
}

export default AuthProvider;
