import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
    Form, Row, Col, Button, Typography, notification
} from 'antd';
import CustomDrawer from 'src/components/shared/Drawer';
import OldPasswordItem from './FormItems/OldPasswordItem';
import NewPasswordItem from './FormItems/NewPasswordItem';
import RepeatPasswordItem from './FormItems/RepeatPasswordItem';
import lockImg from '../../../assets/img/lock-icon.svg';

const { Text } = Typography;

const ChangePassword = (props) => {
    const {
        visibility, setVisibility
    } = props;

    const [passWordErrorText, setPassWordErrorText] = useState(null);

    const [form] = Form.useForm();

    const handleUpdate = (values) => {
        let oldPassword = values.oldpassword
        let newPassword = values.newpassword

        Auth.currentAuthenticatedUser()
        .then((user) => {
                return Auth.changePassword(user, oldPassword, newPassword)
        })
        .then((data) => {
                console.log('changePassword:', data)
                handleDrawerClose();
                notification.success({
                    message: 'Password change successful',
                    description:
                        'Your password has been changed succesfully'
                    });
        })
        .catch((err) => {
            handleDrawerClose();
            notification.error({
                message: 'Password change failed',
                description:
                    err.message
                });
        })
    };

    const handleDrawerClose = () => {
        form.resetFields();
        setVisibility(false);
    };

    const onSubmit = () => {
        form
        .validateFields()
        .then((values) => {
            handleUpdate(values);
            handleDrawerClose();
        })
        .catch((info) => {
            console.log('Validate Failed:', info);
        });
    };

    return (
        <>
            <CustomDrawer
                width={720}
                className="dark-theme"
                visibility={visibility}
                handleOnClose={() => handleDrawerClose()}
            >
                <div className="p-20">
                    <Form
                        className="compact pt-8 px-6 pb-0"
                        layout="vertical"
                        form={form}
                        onFinish={onSubmit}
                        hideRequiredMark
                    >
                        <div
                            className="notice-edit-summary my-8"
                            style={{ backgroundImage: `url('${lockImg}')` }}
                            >
                            <Row gutter={20}>
                                <Col span={12}>
                                    <Row
                                        justify="space-between"
                                        style={{ height: "100%" }}
                                    >
                                        <Text>PASSWORD</Text>
                                        <Text className="text-sm">
                                            Password should be minimum 8 characters.{" "}
                                        </Text>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <OldPasswordItem help = {passWordErrorText} />
                                    <NewPasswordItem help = {passWordErrorText} />
                                    <RepeatPasswordItem />
                                </Col>
                            </Row>
                        </div>

                        <Row justify="end">
                            <Button type="primary" htmlType="submit" size="large">
                                Save Entries
                            </Button>
                        </Row>
                    </Form>
                </div>
            </CustomDrawer>
        </>
    );
};

export default ChangePassword;
