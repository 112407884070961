import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Row, Button, message, Popconfirm } from 'antd';
import moment from "moment";
import { COLORS } from "src/theme/colors";
import { DeleteOutlined } from 'src/components/shared/Icons';
import FetchContext from 'src/services/FetchService/FetchContext';
import { AuthContext } from "src/services/AuthService/AuthProvider";
import { GlobalStateContext } from "src/services/GlobalState/GlobalState";
import PermissionService, { DeleteBacklogScope, UpdateBacklogScope } from "src/services/PermissionService";

import TaxAuthorityItem from './FormItems/TaxAuthorityItem';
import TaxPayerItem from './FormItems/TaxPayerItem';
import TaxPeriodItem from './FormItems/TaxPeriodItem';
import NoticeDateItem from './FormItems/NoticeDateItem';
import NoticeTypeItem from './FormItems/NoticeTypeItem';
import NoticeIDItem from './FormItems/NoticeIDItem';
import DueDateItem from './FormItems/DueDateItem';
import AmountItem from './FormItems/AmountItem';
import AssignedUserItem from './FormItems/AssignedUserItem';
import ActionItem from './FormItems/ActionItem';

const Details = ({ currentBacklog }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [taxAuthority, setTaxAuthority] = useState(currentBacklog.taxauthority);
    const { reFetch } = useContext(GlobalStateContext);
    const { backlogconfirmation: { confirmBacklog }, deleteBacklog: { deleteBacklog } } = useContext(FetchContext);
    const [form] = Form.useForm();
    const history = useHistory();
    const { permissions } = useContext(AuthContext);

    /*
    *  Validate user permission.
    *   Info: Third party users do not have permission to change values in Backlog.
    */

    const noAccess = !permissions.has(UpdateBacklogScope);

    const processForm = async (values) => {
        try {
            setIsSubmitting(true);
            await confirmBacklog(postConfirmation(values, currentBacklog));
            await reFetch();
            message.success(
                "Backlog has been confirmed"
            );
            history.push(`/noticemanagement?tab=${window.btoa('confirm')}`);
        } catch (e) {
            setIsSubmitting(false);
            console.log({ e });
        }
    };

    const postConfirmation = (values, currentBacklog) => {
        try {
            values.amount = values.amount.toString();
            values.taxPeriod = values.taxPeriod.toString();
        } catch (err) {
            console.log(err);
        }
        values.confirm_time = moment().format();

        return {
            backlog_items: currentBacklog,
            confirmed_values: values
        };
    };

    const handleDeleteBacklog = async () => {
        const { unique_id } = currentBacklog;

        deleteBacklog({ unique_id })
            .then(res => {
                if (res === "OK") {
                    message.success("Notice has been deleted");
                }
                reFetch();
                history.push('/noticemanagement');
            })
            .catch(e => {
                message.error(
                    "There was an error deleting this notice. Please try again later."
                );
            });
    };

    const handleEntityCreate = data => {
        form.setFieldsValue({ entity: data });
    };

    const handleAmountCreate = data => {
        form.setFieldsValue({ amount: data });
    };

    const handleIdCreate = data => {
        form.setFieldsValue({ noticeID: data });
    };

    const handleTaxAuthorityChange = data => {
        setTaxAuthority(data);
    };

    return (
        <>
            <Form form={form} onFinish={processForm} className="compact px-4 backlog-edit" layout="vertical">
                <Row gutter={15} align="bottom">
                    <TaxAuthorityItem currentBacklog={currentBacklog} onChange={handleTaxAuthorityChange} />
                </Row>
                <TaxPayerItem currentBacklog={currentBacklog} taxAuthority={taxAuthority} onCreate={handleEntityCreate} />
                <Row gutter={15} className="my-4">
                    <TaxPeriodItem currentBacklog={currentBacklog} />
                    <NoticeDateItem currentBacklog={currentBacklog} />
                </Row>
                <Row gutter={15}>
                    <NoticeTypeItem currentBacklog={currentBacklog} />
                    <NoticeIDItem currentBacklog={currentBacklog} onCreate={handleIdCreate} />
                </Row>
                <Row gutter={15} className="mt-4">
                    <DueDateItem currentBacklog={currentBacklog} />
                </Row>
                <AmountItem currentBacklog={currentBacklog} onCreate={handleAmountCreate} />
                <Row gutter={15} className="mt-6">
                    <AssignedUserItem currentBacklog={currentBacklog} />
                    <ActionItem currentBacklog={currentBacklog} />
                </Row>

                <Row justify="space-between" className="mt-12">
                    <div className="inline-flex">
                        <PermissionService scope={DeleteBacklogScope}>
                            <Popconfirm
                                title="Are you sure you want to delete this notice?"
                                okText="Yes"
                                onConfirm={() => handleDeleteBacklog()}
                                disabled={noAccess}
                            >
                                <Button type="link" htmlType="button" size="large" className="pl-0" icon={<DeleteOutlined style={{ color: COLORS.danger }} />}>
                                    Delete
                                </Button>
                            </Popconfirm>
                        </PermissionService>
                    </div>
                    <div className="inline-flex">
                        <Button type="link" size="large" htmlType="button" onClick={history.goBack}>Cancel</Button>
                        <Button type="primary" size="large" htmlType="submit" disabled={noAccess} loading={isSubmitting} style={{ minWidth: 120 }}>Save</Button>
                    </div>
                </Row>
            </Form>
        </>
    );
};

export default Details;
