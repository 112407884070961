import React, { useState } from "react";
import { Select, Divider, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const CreatableSelect = ({ value, onChange, onCreate, options, taxauthority, loading }) => {
	const [ name, setName ] = useState("");
	const [ items, setItems ] = useState(options);

	const onNameChange = event => {
		setName(event.target.value);
	};

	const filterFunction = (inputValue, option) => {
		let federalBoolean;
		try {
			federalBoolean = option.fein.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
		} catch (err) {
			federalBoolean = false;
		}

		let localBoolean;
		try {
			localBoolean = option.localid.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
		} catch (err) {
			localBoolean = false;
		}

		return (
			option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
			federalBoolean ||
			localBoolean
		);
	};

	const addItem = () => {
		onCreate(name);

		setName("");
		setItems([{ entity: name }, ...items]);
	};

	return (
		<Select
			disabled={loading}
			loading={loading}
			showSearch
			value={value}
			onChange={onChange}
			placeholder="Search to Select"
			optionFilterProp="children"
			optionLabelProp="visibleValue"
			showArrow={false}
			filterOption={(inputValue, option) => filterFunction(inputValue, option)}
			dropdownRender={menu => (
			<div>
				{menu}
				<Divider style={{ margin: "4px 0" }} />
				<div style={{ padding: 8 }}>
					<p>Add new taxpayer</p>
					<div style={{ display: "flex", flexWrap: "nowrap" }}>
						<Input
							placeholder="Enter taxpayer name"
							value={name}
							onChange={onNameChange}
							style={{
								border: "1px solid #ccc",
								flex: "auto",
								marginRight: 5
							}}
						/>
						<Button onClick={addItem} type="primary">
						<PlusOutlined /> Add
						</Button>
					</div>
				</div>
			</div>
			)}
		>
			{items.map((item, index) => {
				const visibleValue = `${item.entity}, ${item.fein || 'Not Specified'}, ${item[taxauthority] || 'Not Specified'}`;
				return (
					<Option key={index} value={item.entity} fein={item.fein} localid={item[taxauthority] || null } visibleValue={visibleValue}>
					<p>{item.entity}</p>
					<p style={{ opacity: 0.6 }}>
						FEIN: {item.fein || null}
						<br />
						Local ID: {item[taxauthority] || null }
					</p>
					</Option>
				);
			})}
		</Select>
	);
};

export default CreatableSelect;
