/** @jsx jsx */
import React, { useContext, useState } from 'react';
import {
    Comment, Form, Button, Input, Upload, message, Row
} from 'antd';
import { get, find } from 'lodash';
import moment from 'moment';
import { css, jsx } from '@emotion/core';
import { AuthContext } from 'src/services/AuthService/AuthProvider';
import { LinkOutlined, FileOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Storage } from 'aws-amplify';

const { TextArea } = Input;

const AttachmentButton = ({ onFilesAttached, theme }) => (
    // IF the file upload is rejected, check the respective AWS S3 bucket policy.
    // DOCUMENTATION: https://docs.aws.amazon.com/AmazonS3/latest/userguide/s3-arn-format.html
    <Upload
        accept=".pdf,.png,.jpg,.jpeg,.xls,.xlsx,.doc,.docx,.dot"
        showUploadList={false}
        customRequest={(args) => {
            onFilesAttached(args);
        }}
        multiple
    >
        <Button icon={<LinkOutlined className={theme === 'light' ? 'text-white' : 'text-black'} />} type="link" className={`${theme === 'dark' ? 'btn-black' : null} pl-0`}>
            Attach Document
        </Button>
    </Upload>
);

const Editor = ({
    onChange, onSubmit, submitting, value, onFilesAttached, attachedFiles,
    onAttachedCommentSend, theme
}) => (
    <>
        <Form.Item>
            <TextArea
                className={`${theme === 'light' ? 'text-white' : null} hover-border-transparent focus-border-transparent focus-shadow-none`}
                autoSize={{ minRows: 3, maxRows: 7 }}
                css={theme === 'light' ? css`
                      border-bottom: 1px solid white !important;
                ` : css`border-bottom: 1px solid black !important;`}
                placeholder={(attachedFiles.length > 0 ? 'Add a comment for the attached file/s.' : 'Add a comment')}
                rows={2}
                onChange={onChange}
                value={value}
            />
        </Form.Item>
        <Form.Item>
            <Row justify="space-between">
                <AttachmentButton
                    onFilesAttached={onFilesAttached}
                    theme={theme}
                />
                {(attachedFiles.length > 0 ? (
                    <Button
                        onClick={() => {
                            onAttachedCommentSend();
                        }}
                        type="primary"
                    >
                        Upload
                    </Button>
                ) : (
                    <Button
                        disabled={value.length === 0}
                        htmlType="submit"
                        loading={submitting}
                        onClick={onSubmit}
                        type="primary"
                        size="large"
                        style={{ minWidth: 120 }}
                    >
                        Save
                    </Button>
                ))}
            </Row>
        </Form.Item>
    </>
);

export default ({ submit, theme }) => {
    const [commentValue, setCommentValue] = useState('');
    const [submitting, updateSubmitStatus] = useState(false);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const { userInfo } = useContext(AuthContext);
    const name = get(userInfo, 'attributes.name', 'USER NOT FOUND');

    function handleUpload() {
        const { username } = userInfo;
        Promise.all(attachedFiles.map((file) => {
            const customer = userInfo.attributes['custom:customer'];
            const filename = `${process.env.REACT_APP_USER_BRANCH || 'dev'}/attachments_${username}_${customer}/${new Date().getTime()}/${get(file, 'file.name', 'not_found')}`;
            return Storage.put(filename, file.file, {
                level: 'public',
            }).then(() => filename);
        })).then((files) => {
            updateSubmitStatus(true);
            submit({
                author: { name },
                content: commentValue,
                datetime: moment().fromNow(),
                attachments: files,
            }).then(() => {
                updateSubmitStatus(false);
                setCommentValue('');
                setAttachedFiles([]);
            });
        }).catch((err) => {
            console.log('error uploading files!', err);
        });
    }

    function renderUploadFilesThumbnails() {
        return (
            <div className="flex flex-row space-x-5">
                {attachedFiles.map((item, index) => (
                    <div key={`selected-file-${index}`} className="relative" style={{ width: 100, padding: 10 }}>
                        <FileOutlined style={{ fontSize: 50 }} />
                        <div className="truncate ">
                            {item.file.name}
                        </div>
                        <CloseCircleOutlined
                            onClick={() => {
                                setAttachedFiles(attachedFiles.filter((file) => file.file.name !== item.file.name));
                            }}
                            css={css`
                              position: absolute;
                              top: 0;
                              left: 0;
                              font-size: 16px;
                              z-index: 10;
                            `}
                        />
                    </div>
                ))}
            </div>
        );
    }

    const handleSubmit = async () => {
        updateSubmitStatus(true);
        try {
            await submit({
                author: { name },
                content: commentValue,
                datetime: moment().fromNow(),
            });
                updateSubmitStatus(false);
                setCommentValue('');
            } catch (error) {
                setTimeout(() => {
                updateSubmitStatus(false);
                message.error("Something went wrong. Please refresh and try again")
            }, 1000);
        };
    };

    return (
        <Comment
            css={css`width: 100%;`}
            content={(
                <div>
                    {renderUploadFilesThumbnails()}
                    <Editor
                        attachedFiles={attachedFiles}
                        onFilesAttached={(files) => {
                            /* Handle duplicate files. */
                            if (find(attachedFiles, (item) => item.file.name === files.file.name)) {
                                message.info('File already exists.');
                                return null;
                            }
                            setAttachedFiles([...attachedFiles, files]);
                        }}
                        onAttachedCommentSend={() => {
                            handleUpload();
                        }}
                        onChange={(e) => setCommentValue(e.target.value)}
                        onSubmit={handleSubmit}
                        submitting={submitting}
                        value={commentValue}
                        theme={theme}
                    />
                </div>
            )}
        />
    );
};
