import React from "react";
import { Typography, Form, Select } from "antd";
import { states } from "src/utils/States";

const { Title } = Typography;

const { Option } = Select;

const AddTaxLevelItem = ({ currentNotice }) => {
  return (
    <>
      <div className="flex items-center">
        <div className="w-4/12">
          <Title level={5}>Tax Authority</Title>
        </div>
        <div className="w-8/12">
          <Form.Item
            name="taxauthority"
            key="taxauthority"
            initialValue={currentNotice.taxauthority}
          >
            <Select showSearch>
              {states.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
    </>
  );
};
export default AddTaxLevelItem;
