import React from "react";
import { Select } from "antd";

const { Option } = Select;

const NoticeType = ({ noticeTypes, cachedValues, handleNoticetypeChange }) => (
	<Select
		mode="multiple"
		placeholder="Categories"
		maxTagCount="responsive"
		className="w-full"
		onChange={handleNoticetypeChange}
		defaultValue={cachedValues}
	>
		{noticeTypes.map(type => (
			<Option value={type.frontendName} key={type.frontendName}>{type.frontendName}</Option>
		))}
	</Select>
);

export default NoticeType;
