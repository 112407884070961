import React from 'react';
import {
    Col, Form, Select
} from 'antd';
import { noticeTypesConversion } from 'src/utils/Categories';

const { Option } = Select;

const NoticeTypeItem = ({ currentNotice }) => (
    <Col span={12}>
        <Form.Item
            label="Notice Type"
            name="noticetype"
            key="noticetype"
            initialValue={currentNotice.noticetype}
        >
            <Select>
                {
                    noticeTypesConversion.map((type) => <Option key={type.databaseName} value={type.frontendName}>{type.frontendName}</Option>)
                }
            </Select>
        </Form.Item>
    </Col>
);

export default NoticeTypeItem;
