import React from "react";
import {
	Grid,
	Row,
	Col,
	Typography,
	Collapse,
	Select
} from "antd";

import TaxAuthority from './TaxAuthority';
import Entity from './Entity';
import NoticeType from './NoticeType';
import User from './User';
import TaxYear from './TaxYear';
import Status from './Status';
import Filter from 'src/assets/img/filter.svg';

const { Text } = Typography;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

function Label () {
    return (
		<>
			<img src={Filter} alt="Search" className="inline-flex pb-1 mr-2" />
			<Text
				type="secondary"
				style={{ letterSpacing: "0.1em" }}
			>
				FILTER NOTICES
			</Text>
		</>
	);
}

function MobileLayout () {
    return (
		<Row align="middle" justify="center" gutter={{ xs: 8, sm: 16 }}>
			<Collapse ghost>
				<Panel header={<Label />} key="1" showArrow={false}>
					<p>Filters</p>
				</Panel>
			</Collapse>
		</Row>
	);
}

const Filters = (props) => {
	const {
		users,
		noticeTypes,
		statesCachedValues,
		handleStateChange,
		entitiesCachedValues,
		handleEntityChange,
		typesCachedValues,
		handleNoticetypeChange,
		usersCachedValues,
		handleUserChange,
		yearsCachedValues,
		handleYearChange,
		statusesCachedValues,
		handleStatusChange
	} = props;

	const screens = useBreakpoint();

	const breakPoint = Object.entries(screens).filter(screen => !!screen[1]).map(screen => screen[0]);

	const FilterItems = () => (
		<>
			<Col xs={24} md={8} lg={3}>
				<TaxAuthority
					cachedValues={statesCachedValues}
					handleStateChange={handleStateChange}
				/>
			</Col>
			<Col xs={24} md={15} lg={6}>
				<Entity
					cachedValues={entitiesCachedValues}
					handleEntityChange={handleEntityChange}
				/>
			</Col>
			<Col xs={24} md={12} lg={5}>
				<NoticeType
					noticeTypes={noticeTypes}
					cachedValues={typesCachedValues}
					handleNoticetypeChange={handleNoticetypeChange}
				/>
			</Col>
			<Col xs={24} md={6} lg={3}>
				<TaxYear
					cachedValues={yearsCachedValues}
					handleYearChange={handleYearChange}
				/>
			</Col>
			<Col xs={24} md={6} lg={3}>
				<Status
					cachedValues={statusesCachedValues}
					handleStatusChange={handleStatusChange}
				/>
			</Col>
			<Col xs={24} md={6} lg={3}>
				<User
					users={users}
					cachedValues={usersCachedValues}
					handleUserChange={handleUserChange}
				/>
			</Col>
		</>
	);

	if (breakPoint.includes('xs') && !breakPoint.includes('sm')) {
		return (
			<Collapse ghost className="text-center">
	 			<Panel header={<Label />} key="1" showArrow={false}>
	 				<FilterItems />
	 			</Panel>
	 		</Collapse>
		);
	}

	return (
		<Row align="middle" justify="center" gutter={{ xs: 8, sm: 16 }}>
			<Col span={1}>
 				<img src={Filter} alt="Search" className="inline-flex pb-1 mr-2" />
 			</Col>
 			<FilterItems />
		</Row>
	);
};

export default Filters;
