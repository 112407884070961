import React from 'react';
import { Col, Form, Select } from 'antd';
import { noticeTypesConversion } from 'src/utils/Categories';

const { Option } = Select;

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

const NoticeTypeItem = ({ currentBacklog }) => (
    <Col span={12}>
        <Form.Item
            label="Notice Type"
            name="noticetype"
            key="noticetype"
            initialValue={currentBacklog.noticetype !== "announcement" ? capitalize(currentBacklog.noticetype) : null}
        >
            <Select>
                {
                    noticeTypesConversion.map((type) => <Option key={type.databaseName} value={type.frontendName}>{type.frontendName}</Option>)
                }
            </Select>
        </Form.Item>
    </Col>
);

export default NoticeTypeItem;
