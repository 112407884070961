import moment from 'moment';

export const convertTimeStamp = (timestamp) => {
    if (!timestamp) return moment();
    const test = Date.parse(timestamp);
    const offset = new Date().getTimezoneOffset();
    if (offset >= 0) {
        return moment(test).subtract(offset, 'minutes');
    }
    return moment(test).add(Math.abs(offset), 'minutes');
};
