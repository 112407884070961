import { getApiUrl } from 'src/utils/Helper';
import { axiosGet, axiosPost } from './utils';

const API_ENDPOINT = getApiUrl();

export const getBacklogs = (authContext) => () => {
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosGet(`${API_ENDPOINT}/backlog`, config);
};

export const deleteBacklog = (authContext) => (values) => {
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosPost(`${API_ENDPOINT}/update_notice`, values, config);
};
