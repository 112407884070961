import React from "react";
import logo from "../../assets/img/microsoft_logo.svg";

const MicrosoftLogo = ({ width, ...rest }) => {
  return (
    <img src={logo} alt="microsoft-logo" {...rest} width={width ? width : 25} style={{position: 'absolute', left: 14}}/>
  );
};

export default MicrosoftLogo;
