import React from 'react';

import {
    UPDATE_NOTICE_MANAGEMENT_PAGE_COUNT,
    UPDATE_USERNAME,
} from './Constants';

export const LocalStoreStateContext = React.createContext({});
export const LocalStoreDispatchContext = React.createContext({});

const validateKey = (key) => {
    if (!key || typeof key !== 'string') throw new Error('Invalid key for LocalStore service.');
};

function reducer(state, action) {
    validateKey(action.type);
    switch (action.type) {
    case UPDATE_NOTICE_MANAGEMENT_PAGE_COUNT: {
        if (action.persist) {
            localStorage.setItem(UPDATE_NOTICE_MANAGEMENT_PAGE_COUNT, action.payload);
        }
        return { ...state, noticeManagementPageCount: action.payload };
    }
    case UPDATE_USERNAME: {
        return { ...state, userName: action.payload };
    }
    default: {
        throw new Error('Unhandled action type');
    }
    }
}

const LocalStoreProvider = ({ children }) => {
    const initialState = {
        noticeManagementPageCount: localStorage.getItem(UPDATE_NOTICE_MANAGEMENT_PAGE_COUNT) || 1,
        userName: '',
    };

    const [state, dispatch] = React.useReducer(
        reducer,
        initialState,
    );
    return (
        <LocalStoreStateContext.Provider value={state}>
            <LocalStoreDispatchContext.Provider value={dispatch}>
                {children}
            </LocalStoreDispatchContext.Provider>
        </LocalStoreStateContext.Provider>
    );
};

export { LocalStoreProvider };
