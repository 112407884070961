import React from 'react'
import {Result, Button} from 'antd'
import {FileTextOutlined} from '@ant-design/icons'
import { useHistory } from "react-router-dom";

const ResultBacklogCompleted = (props) => {
    const history = useHistory();
    
    const handleComplete = (props) => {
        history.push('/noticemanagement')
    }
    
    return <Result
                status="success"
                title="You have completed confirmation of all notices in the backlog"
                subTitle="The notices are now in Notice Management"
                extra={[
                    <Button type="primary" 
                            key="console" 
                            onClick = {handleComplete}
                    >
                        <FileTextOutlined /> Notice management
                    </Button>
                ]}
            />
}
export default ResultBacklogCompleted