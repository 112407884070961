import { API } from 'aws-amplify';
import { axiosGet, axiosPost } from './utils';
import { getApiUrl } from 'src/utils/Helper';

// @TODO Fix .env reading.
// const API_ENDPOINT = process.env.API_ENDPOINT || 'http://localhost:8080';
const API_ENDPOINT = getApiUrl();
export const getComments = (authContext) => (noticeId) => {
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosGet(`${API_ENDPOINT}/comments/${noticeId}`, config);
};

export const addComment = (authContext) => ({ noticeId, body }) => {
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosPost(`${API_ENDPOINT}/comment/${noticeId}`, body, config);
};
