import React, { useState, useContext } from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import { API } from 'aws-amplify';
import Add from './Add';
import UsersTable from './Table';
import Update from './Update';
import ChangePassword from './ChangePassword';
import { LockOutlined } from '@ant-design/icons';
import { COLORS } from 'src/theme/colors';
import { ToolOutlined, DeleteOutlined } from 'src/components/shared/Icons';
import { AuthContext } from 'src/services/AuthService/AuthProvider';
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';
import { AdminMenuScope } from 'src/services/PermissionService';
import { WritePermissionScope, ReadPermissionScope } from 'src/services/PermissionService';

const Users = () => {
    const [updateUserVisibility, setUpdateUserVisibility] = useState(false);
    const [changePasswordVisibility, setChangePasswordVisibility] = useState(false);
    const [userData, setUserData] = useState(null);
    const { userInfo, permissions } = useContext(AuthContext);
    const { users, reFetch } = useContext(GlobalStateContext);

    /*
     *  Validate user permission.
     *  Info: Third party users do not have permission to change values in Backlog.
    */
    const isAdmin = !permissions.has(AdminMenuScope);
    const writeAccess = permissions.has(WritePermissionScope);
    const readAccess = permissions.has(ReadPermissionScope);

    const columns = [
        {
            title: 'Username',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Access',
            dataIndex: 'access',
            key: 'access',
            render: (access) => readAccess && (
                access === 'regular' ? 'Regular' : access === 'admin' ? 'Administrator' : 'Restricted access'
            ),
        },
        {
            title: 'Status',
            dataIndex: 'userstatus',
            key: 'userstatus',
            render: (userstatus) => readAccess && (
                <span style=
                    {{
                        color: userstatus === 'CONFIRMED' || userstatus === 'EXTERNAL_PROVIDER' ? COLORS.primary : COLORS.warning,
                    }}
                >
                    {userstatus === 'CONFIRMED' || userstatus === 'EXTERNAL_PROVIDER' ? 'Confirmed' : 'Pending'}
                </span>
            ),
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => writeAccess && (
                <Row gutter={16}>
                    <Col span={9}>
                        {userInfo.username === record.username
                            ? <Button
                                type="link"
                                icon={<LockOutlined />}
                                onClick={() => changePassword(record)}
                                className="px-0"
                            >
                                {' '}
                                Password
                            </Button>
                            : null
                        }
                    </Col>
                    <Col span={7}>
                        <Button
                            type="link"
                            icon={<ToolOutlined />}
                            onClick={() => updateUser(record)}
                            className="px-0"
                        >
                            {' '}
                            Update
                        </Button>
                    </Col>
                    <Col span={7}>
                        <Popconfirm
                            title="Are you sure you want to delete this user?"
                            okText="Yes"
                            onConfirm={() => deleteUser(record)}
                        >
                            <Button
                                type="link"
                                icon={<DeleteOutlined style={{ color: COLORS.danger }} />}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            ),
        },
    ];

    const data = [];

    users.forEach((user) => {
        const nameIndex = user.attributes.findIndex((element) => element.Name === 'name');
        const emailIndex = user.attributes.findIndex((element) => element.Name === 'email');
        const accessIndex = user.attributes.findIndex((element) => element.Name === 'custom:access');
        // If user does not have access attribute, do not show it.
        if (accessIndex === -1) {
            return;
        }
        const user_object = {
            key: user.username,
            username: user.username,
            name: user.attributes[nameIndex].Value,
            email: user.attributes[emailIndex].Value,
            access: user.attributes[accessIndex].Value,
            userstatus: user.userstatus,
        };
        data.push(user_object);
    });

    const changePassword = (record) => {
        setChangePasswordVisibility(true);
        setUserData(record);
    };

    const updateUser = (record) => {
        setUpdateUserVisibility(true);
        setUserData(record);
    };

    const deleteUser = (record) => {
        const apiName = 'stream';
        const path = '/delete_user';
        const myInit = {
            body: JSON.stringify(record),
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods': 'POST',
            },
        };

        API
            .post(apiName, path, myInit)
            .then(() => {
                reFetch();
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    return (
        <>
            <Row justify="end">
                <Add isAdmin={isAdmin} />
            </Row>

            <UsersTable columns={columns} data={data} />

            <Update
                visibility={updateUserVisibility}
                setVisibility={setUpdateUserVisibility}
                userData={userData}
                onDone={reFetch}
            />

            <ChangePassword
                visibility={changePasswordVisibility}
                setVisibility={setChangePasswordVisibility}
            />
        </>
    );
};

export default Users;
