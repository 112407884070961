import React from "react";

import { Typography, Form, DatePicker } from "antd";
import * as chrono from "chrono-node";
import moment from "moment";

import { mappingTable } from "src/utils/mappingTable";

const { Title } = Typography;

const DueDateItem = props => {
  const { currentNotice } = props;
  const keys = mappingTable.dueDate;
  const candidates = [];

  Object.keys(currentNotice).map(key => {
    if (keys.includes(key.slice(0, -1))) {
      candidates.push(currentNotice[key].options[0]);
    }
  });
  const proposedNoticeDate = moment(chrono.parseDate(candidates[0]));
  return (
    <div className="flex items-center">
      <div className="w-4/12">
        <Title level={5}>Due Date</Title>
      </div>
      <div className="w-8/12">
        <Form.Item
          name="dueDate"
          key="dueDate"
          initialValue={
            proposedNoticeDate.isValid() ? proposedNoticeDate : null
          }
        >
          <DatePicker
            style={{ width: 180 }}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default DueDateItem;
