import React from 'react';
import { Col, Form, Input } from 'antd';

const NoticeIDItem = ({ currentNotice }) => (
    <Col span={12}>
        <Form.Item
            label="Notice ID"
            name="noticeID"
            key="noticeID"
            initialValue={currentNotice.noticeID}
        >
            <Input />
        </Form.Item>
    </Col>
);

export default NoticeIDItem;
