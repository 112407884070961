import React, { useRef, useState } from 'react';
import { Button, Divider, Form, Input, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const CompanyItem = ({ companies }) => {
    const [items, setItems] = useState(companies);
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        name && setItems([...items, name]);
        setName('');
    };
    return (
        <Form.Item
            name="company"
            label="select company"
            rules={[{ required: true, message: 'Please indicate company' }]}
        >
            <Select placeholder="Indicate access rights"
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                                placeholder="Please enter item"
                                ref={inputRef}
                                value={name}
                                onChange={onNameChange}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                Add item
                            </Button>
                        </Space>
                    </>
                )}
                options={items.map((item) => ({ label: item, value: item }))}
            />
        </Form.Item>
    );
};
export default React.memo(CompanyItem);