import React from "react";
import { Switch, Redirect } from "react-router-dom";

import Backlog from "src/ProtectedComponents/Backlog/Backlog";
import NoticeManagement from "src/ProtectedComponents/NoticeManagement";
import NoticeViewer from "src/ProtectedComponents/NoticeManagement/NoticeViewer";
import BacklogViewer from "src/ProtectedComponents/NoticeManagement/BacklogViewer";
import Settings from "src/ProtectedComponents/Settings";
import SuperAdmin from "src/ProtectedComponents/Settings/SuperAdmin";
import PermissionService, {
	ReadOrganizationScope
} from "src/services/PermissionService";
import { GlobalStateProvider } from "src/services/GlobalState/GlobalState";
import { ProtectedRoute } from "./utils";

export default () => {
	return (
		<GlobalStateProvider>
			<Switch>
				<ProtectedRoute exact path="/backlog" component={Backlog} />
				<ProtectedRoute
					exact
					path="/noticemanagement"
					component={NoticeManagement}
				/>
				<ProtectedRoute
					path="/noticeviewer/:noticeId"
					component={NoticeViewer}
				/>
				<ProtectedRoute
					path="/backlogviewer/:backlogId"
					component={BacklogViewer}
				/>
				<ProtectedRoute
					exact
					path="/settings"
					component={() => (
					<PermissionService scope={ReadOrganizationScope}>
						<Settings />
					</PermissionService>
					)}
				/>
				<ProtectedRoute
					path="/super-admin"
					component={SuperAdmin}
				/>
				<Redirect to="/noticemanagement" />
			</Switch>
		</GlobalStateProvider>
	);
};
