import React from 'react';
import {
    Form, Input
} from 'antd';

const EntityNameItem = () => {
    return (
        <Form.Item
            name="entity"
            label="Entity Name"
            rules={[{ required: true, message: 'Please enter entity name' }]}
        >
            <Input placeholder="Please enter entity name" />
        </Form.Item>
    );
};

export default EntityNameItem;
