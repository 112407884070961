import React from "react";
import * as chrono from "chrono-node";
import moment from "moment";
import { Typography, Form, Select } from "antd";
import { mappingTable } from "src/utils/mappingTable";

const { Option } = Select;
const { Title } = Typography;

const oldShit = ["Value not found", "Not a data element"];

const TaxPeriodItem = props => {
  const { currentNotice } = props;
  const keys = mappingTable.taxPeriod;
  const candidates = [];

  Object.keys(currentNotice).map(key => {
    if (keys.includes(key.slice(0, -1))) {
      currentNotice[key].options.map(candidate => {
        try {
          const parsedCandidate = chrono.parseDate(candidate).getFullYear();

          if (
            !candidates.includes(parsedCandidate) &&
            !oldShit.includes(candidate)
          ) {
            candidates.push(parsedCandidate);
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  });

  const taxYears = [
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
  ];

  return (
    <div className="flex items-center">
      <div className="w-4/12">
        <Title level={5}>Tax Period</Title>
      </div>
      <div className="w-8/12">
        <Form.Item
          name="taxPeriod"
          key="taxPeriod"
          initialValue={candidates[0] || null}
        >
          <Select style={{ width: 180 }} placeholder="Select year">
            {taxYears.map(candidate => (
              <Option value={candidate} key={candidate}>
                {" "}
                {candidate}{" "}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};

export default TaxPeriodItem;
