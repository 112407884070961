import React from "react";
import { Route } from "react-router-dom";
import ProtectedHeader from "src/ProtectedComponents/Header/Header";
import ProtectedAdminHeader from "src/ProtectedComponents/Header/SuperAdminHeader";
import Feedback from "src/Feedback";
import { LocalStoreProvider } from "src/services/LocalStore/LocalStoreProvider";

export const PublicRouteWrapper = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <div>
        <Component {...props} />
      </div>
    )}
  />
);

export const ProtectedAdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <LocalStoreProvider>
        <ProtectedAdminHeader />
        <div className="relative">
          <Component {...props} />
        </div>
      </LocalStoreProvider>
    )}
  />
);


export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <LocalStoreProvider>
        <ProtectedHeader />
        <div className="relative">
          <Component {...props} />
          <div className="absolute right-0 bottom-0">
            <Feedback />
          </div>
        </div>
      </LocalStoreProvider>
    )}
  />
);
