import React from 'react';
import {
    Col, Form, DatePicker
} from 'antd';

import moment from 'moment';

const DueDateItem = ({ currentNotice }) => (
    <Col span={12}>
        <Form.Item
            label="Due Date"
            name="dueDate"
            key="dueDate"
            initialValue={moment(currentNotice.dueDate).isValid() ? moment(currentNotice.dueDate) : null}
        >
            <DatePicker
                style={{ width: 180 }}
            />
        </Form.Item>
    </Col>
);

export default DueDateItem;
