import React, { useState, useContext } from 'react';
import { Space, Form, Row, Col, Typography, Button, notification } from 'antd';
import { Auth } from 'aws-amplify';
import lockImg from 'src/assets/img/lock-icon.svg';
import { UserAddOutlined } from '@ant-design/icons';
import CustomDrawer from 'src/components/shared/Drawer';
import NameItem from './FormItems/NameItem';
import AccessLevelItem from './FormItems/AccessLevelItem';
import EmailItem from './FormItems/EmailItem';
import TemporaryPasswordItem from './FormItems/TemporaryPasswordItem';
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

const { Text } = Typography;

const Add = ({ isAdmin }) => {
    const [form] = Form.useForm();
    const [customer, setCustomer] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [visibility, setVisibility] = useState(false);
    const { reFetch } = useContext(GlobalStateContext);

    const evaluateAdmin = async () => {
        const userInfo = await Auth.currentAuthenticatedUser();
        setCustomer(userInfo.attributes['custom:customer']);
    };

    evaluateAdmin();

    const handleDrawerClose = () => {
        setVisibility(false);
        form.resetFields();
    }

    const addUser = async (values) => {
        const username = values.email;
        const { password } = values;
        try {
            await Auth.signUp({
                username,
                password,
                attributes: {
                    name: values.name,
                    'custom:customer': customer,
                    'custom:access': values.access,

                },
                validationData: [], // optional
            });

            handleDrawerClose();

            notification.open({
                message: `${username} has been added as a new user`,
                description: `Login details have been sent to ${values.email}`,
                placement: 'topLeft',
            });
            reFetch();
        } catch (err) {
            setEmailError(err.message);
        }
    };

    return (
        <>
            <Space>
                <Button type="link" className="pl-0 my-4" icon={<UserAddOutlined />} onClick={() => setVisibility(true)} disabled={isAdmin}>
                    ADD NEW
                </Button>
            </Space>

            <CustomDrawer width={720} visibility={visibility} handleOnClose={() => handleDrawerClose()}>
                <div className="p-20">
                    <Form
                        className="compact pt-8 px-6 pb-0"
                        layout="vertical"
                        form={form}
                        onFinish={addUser}
                        hideRequiredMark
                    >
                        <Row gutter={15}>
                            <NameItem />
                            <AccessLevelItem />
                        </Row>

                        <EmailItem emailError={emailError} />

                        <div
                            className="notice-edit-summary my-8"
                            style={{ backgroundImage: `url('${lockImg}')` }}
                            >
                            <Row gutter={20}>
                                <Col span={12}>
                                    <Row
                                        justify="space-between"
                                        style={{ height: "100%" }}
                                    >
                                        <Text>PASSWORD</Text>
                                        <Text className="text-sm">
                                            Password should be minimum 8 characters.{" "}
                                        </Text>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <TemporaryPasswordItem />
                                </Col>
                            </Row>
                        </div>

                        <Row justify="end">
                            <Button type="primary" htmlType="submit" size="large">
                                Save Entries
                            </Button>
                        </Row>
                    </Form>
                </div>
            </CustomDrawer>
        </>
    );
};

export default Add;
