import { getApiUrl } from 'src/utils/Helper';
import { axiosPut, axiosDelete, axiosPost } from './utils';

const API_ENDPOINT = getApiUrl();

export const postUpdateEntity = (authContext) => (values) => {
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosPost(`${API_ENDPOINT}/update_entity`, values, config);
};

export const deleteEntity = (authContext) => (entity) => {
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosDelete(`${API_ENDPOINT}/delete_entity/${entity}`, config);
};

export const postAddEntity = (authContext) => (values) => {
    const { token } = authContext;
    const config = { headers: { Authorization: token, 'Content-Type': 'application/json' } };
    return axiosPut(`${API_ENDPOINT}/add_entity`, values, config);
};
