import React from 'react';

import {
    Row, Col, Typography,
} from 'antd';

const { Title } = Typography;

const HeaderDynamic = () => (
    <>
        <div className="flex item-center">
            <div className="w-4/12">
                <Title level={5}>
                    Key
                </Title>
            </div>
            <div className="w-8/12">
                <Title level={5}>
                    Value
                </Title>
            </div>
        </div>
    </>
);

export default HeaderDynamic;
