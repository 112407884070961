import React from 'react';
import {
    Form, Input
} from 'antd';
const EmailItem = (props) => {
    return (
        <Form.Item
            name="email"
            label="Email"
            help={props.emailError}
            rules={[{ required: true, message: 'Please enter your email' }]}
            className="add-user-password mt-4"
        >
            <Input placeholder="Please enter email address" />
        </Form.Item>
    );
};
export default EmailItem;