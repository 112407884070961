import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { Drawer } from 'antd';

import ShowScrollablePDF from 'src/ProtectedComponents/Backlog/Form/ShowScrollablePdf';

const DocumentViewer = (props) => {
    const { imagePath, setNoticeVisibility, noticeVisibility } = props;
    const [image, setImage] = useState(null);
    const fetchImage = async () => {
        const S3filePath = await imagePath;
        Storage.get(S3filePath, { level: 'public' })
            .then((data) => setImage(data))
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchImage();
    }, [imagePath]);

    return (
        <Drawer
            className="dark-theme"
            placement="right"
            onClose={() => setNoticeVisibility(false)}
            visible={noticeVisibility}
            width="50%"
        >
            <ShowScrollablePDF image={image} />
        </Drawer>
    );
};

export default DocumentViewer;
