/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useContext } from 'react';
import CommentInput from 'src/components/Comments/CommentInput';
import FetchContext from 'src/services/FetchService/FetchContext';
import { constructCommentData } from 'src/utils/NoticeManagement';

const Add = ({ noticeId, updateComments }) => {
    const { comments: { getComments, addComment } } = useContext(FetchContext);

    return (
        <CommentInput
            submit={async (data) => {
                try {
                    const { content: comment, attachments } = data;
                    await addComment({
                        noticeId,
                        body: {
                            comment,
                            attachments,
                        },
                    });
                    const results = await getComments(noticeId);
                    updateComments(constructCommentData(results));
                    return Promise.resolve('res');
                } catch (e) {
                    return Promise.reject();
                }
            }}
            theme="light"
        />
    );
};
export default Add;
