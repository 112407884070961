import React, { useContext } from "react";

import { GlobalStateContext } from "src/services/GlobalState/GlobalState";

import FormGenerator from "./Form/FormGenerator";
import ResultBacklogCompleted from "./ResultBacklogCompleted";

const Backlog = props => {
  // count context
  const { backlogs, count } = useContext(GlobalStateContext);
  let content;

  // define a Boolean that will determine whether the result backlog screen will be shown.
  let backlog_notices = 0;
  backlogs.map(item => {
    if (item.notice_status === "notice parsed") {
      backlog_notices += 1;
    }
  });

  if (count < backlog_notices) {
    content = <FormGenerator key={Math.random()} />;
  } else {
    content = <ResultBacklogCompleted />;
  }

  return <div className="container">{content}</div>;
};

export default Backlog;
