import React from 'react';
import {
    Form, Input
} from 'antd';

const NewPasswordItem = (props) => {
    return (
        <Form.Item name="newpassword" label="Temporary password" rules={[{ required: true, message: 'Please input new password' }]} help={props.help}>
            <Input.Password></Input.Password>
        </Form.Item>
    );
};

export default NewPasswordItem;
