import React, { useState, useContext } from 'react';
import { Space, Form, Row, Button, message } from 'antd';
import EntityNameItem from './FormItems/EntityNameItem';
import FEINItem from './FormItems/FEINItem';
import LocalIDItem from './FormItems/LocalIDItem';
import StateItems from './FormItems/StateItems';
import CustomDrawer from 'src/components/shared/Drawer';
import { AddEntityOutlined } from 'src/components/shared/Icons';
import FetchContext from "src/services/FetchService/FetchContext";
import { GlobalStateContext } from "src/services/GlobalState/GlobalState";

const Add = ({ noAccess }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visibility, setVisibility] = useState(false);
    const { addEntityState } = useContext(GlobalStateContext);
    const { entitymanagement: { postAddEntity } } = useContext(FetchContext);

    const handleDrawerClose = () => {
        setLoading(false);
        setVisibility(false);
        form.resetFields();
    };

    const handleAddEntity = () => {
        form
        .validateFields()
        .then(values => {
            addEntity(values);
        })
        .catch(info => {
            console.log("Validate Failed:", info);
        });
    }

    const addEntity = async (values) => {
        setLoading(true);
        const data = handleRequestData(values);

        try {
            await postAddEntity(data);
            addEntityState(data);

            handleDrawerClose();

            message.success(
                "The entity has been added to the database"
            );
        } catch (err) {
            setLoading(false);
            console.log(err);
            message.error("Something went wrong. Please check your values and try again later");
        }
    };

    const handleRequestData = values => {
        Object.keys(values).forEach(
            key => values[key] === undefined && delete values[key]
        );
        
        if('states' in values) {
            values['states'].forEach(function (name) {
                let key = name.state;
                let value = name.stateid;
                values[key] = value;
            });

            delete values['states'];
        }

        return values;
    };

    return (
        <>
            <Space>
                <Button type="link" className="pl-0 my-4" icon={<AddEntityOutlined />} onClick={() => setVisibility(true)} disabled={noAccess} >
                    ADD NEW
                </Button>
            </Space>
            <CustomDrawer width={720} visibility={visibility} handleOnClose={() => handleDrawerClose()}>
                <div className="p-20">
                    <Form
                        className="compact pt-8 px-6 pb-0"
                        layout="vertical"
                        form={form}
                        onFinish={handleAddEntity}
                        hideRequiredMark
                    >
                        <EntityNameItem />

                        <Row gutter={15}>
                            <FEINItem />
                            <LocalIDItem />
                        </Row>

                        <StateItems />

                        <Row justify="end" className="mt-8">
                            <Button type="primary" htmlType="submit" size="large" loading={loading}>
                                Save Entries
                            </Button>
                        </Row>
                    </Form>
                </div>
            </CustomDrawer>
        </>
    );
};

export default Add;
