import React from 'react';
import {
    Form, Input, Col
} from 'antd';
const UsernameItem = () => {
    return (
        <Col span={12}>
            <Form.Item
                name="name"
                label="Username"
                rules={[{ required: true, message: 'Please enter username' }]}
            >
                <Input placeholder="Please enter username" />
            </Form.Item>
        </Col>
    );
};
export default UsernameItem;
