// @flow
import React, { useState, useEffect, createContext } from "react";
import { API, Auth } from "aws-amplify";

import { produce } from "immer";

const GlobalStateContext = createContext();

const GlobalStateProvider = props => {
  const [data, setData] = useState(null);
  const [entities, setEntities] = useState(null);
  const [users, setUsers] = useState(null);
  const [backlogs, setBacklogs] = useState(null);
  const [notices, setNotices] = useState(null);
  const [count, setCount] = useState(0);
  
  
  const fetchData = async () => {
    const apiName = "stream";
    const path = `/application_context?customer=${JSON.parse(localStorage.getItem('company'))}`;
    const userInfo = await Auth.currentAuthenticatedUser();
    const superAdminPath = userInfo && userInfo.attributes['custom:access'] == 'SuperAdmin' ? path : "/application_context?customer=";
    const myInit = {
      headers: {}
    };
    try {
      const result = await API.get(apiName, superAdminPath, myInit);
      setEntities(result.entities);
      setUsers(result.users);
      setBacklogs(result.backlog);
      setNotices(result.clientside);
      setData(result);
    } catch (err) {
      console.log("no auth yet");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (data === null) {
    return null;
  }

  const updateEntityState = values => {
    // update local state

    const index = entities.findIndex(x => x.entity === values.old_entity_name);
    const nextEntityState = produce(entities, draftState => {
      draftState[index] = values;
    });
    setEntities(nextEntityState);
  };

  const deleteEntityState = record => {
    const index = entities.findIndex(x => x.entity === record.entity);
    const nextEntityState = produce(entities, draftState => {
      draftState.splice(index, 1);
    });
    setEntities(nextEntityState);
  };

  const addEntityState = record => {
    setEntities([record, ...entities]);
  };

  const increaseCount = () => {
    setCount(count + 1);
  };

  const decreaseCount = () => {
    setCount(count - 1);
  };

  return (
    <GlobalStateContext.Provider
      value={{
        entities,
        updateEntityState,
        deleteEntityState,
        addEntityState,
        users,
        backlogs,
        notices,
        reFetch: fetchData,
        count,
        decreaseCount,
        increaseCount
      }}
    >
      {props.children}
    </GlobalStateContext.Provider>
  );
};

export { GlobalStateContext, GlobalStateProvider };
