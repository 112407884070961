const mappingTable = {
    entityID: ['correspid', 'corporationnumber', 'taxaccountnumber',
        'employeridnumber', 'businesspartnernumber',
        'louisianataxnumber', 'taxpayernumber',
        'taxaccountid', 'mataxpayerid', 'tin',
        'entitynumber', 'federaltaxpayeridnumberortrnumber', 'federaleinnumber',
        'ssneinnumber', 'corporateaccountno', 'mdid',
        'accountno', 'custacctno', 'ein',
        'taxpayeridnumber', 'adanumber', 'federalemployeeidnumberortrnumber',
        'employeeidentificationnumber', 'acountno', 'paaccountid',
        'corporationnumber', 'franchisetaxid', 'sid',
        'federalemployeridnumberortrnumber', 'cttaxregistrationno', 'fedidno',
        'entityid', 'sseinnumber', 'fidnumberorsocialsecuritynumber',
        'wisconsintaxnumber', 'employedidnumber', 'taxidnumber',
        'tpsid', 'indetificationnumber', 'fein',
        'paacctid', 'ssnfeinifany', 'accountnumber',
        'id', 'accountid', 'customerid',
        'feissn', 'employeridentificationnumber', 'taxpayerid',
        'fidnumber', 'idnumber', 'fid',
        'ssnfei', 'recipientsfeinidnumber', 'entityid',
        'taxpayertid'],
    taxPeriod: ['taxableyearend', 'periods', 'period',
        'periodenddate', 'filingperiod', 'taxperiodending',
        'liabilityperiod', 'taxperiodbegindate',
        'taxyearsend', 'incomeyearending', 'taxyearending',
        'periodending', 'corporateincometaxfileperiod', 'fiscalyearend',
        'taxperiodbegan', 'determinationperiod', 'taxyearendingyyyymm',
        'taxableyears', 'incomeyear', 'taxableyear',
        'incomeyearsended', 'taxyr', 'incomeyearsended',
        'accountperiodbeginning', 'incomeperiod', 'accountperiodending',
        'reportyear', 'taxperiodbegindate', 'taxperiodsended',
        'taxperiod', 'periodend', 'taxperiodenddate',
        'reportingperiod', 'taxyear', 'taxperiodendeddate',
        'taxyearend', 'taxyearperiod', 'endpd',
        'franchisetaxfileperiod', 'taxperiodended', 'taxyearsending',
        'taxperiodend', 'periodcoveredbyclaim', 'calculatedthrough',
        'years', 'taxableyearformyear', 'accountperiod',
        'assessmentfortaxyearending', 'taxduedate', 'taxyrsend',
        'periodended', 'taxperiods', 'periodcovered',
        'fiscalyearending', 'periodfrom', 'taxaccountperiod',
        'yearending'],
    amount: ['paythisamount', 'assessmenttotal', 'checkamount',
        'amountdueuponreceipt', 'amountnowdue', 'totalamountdue',
        'payamount', 'totalamountyouowe', 'totalrefund',
        'totalamount', 'totalbalance', 'amountoflien',
        'taxamountdue', 'totaldue', 'amountdue',
        'amountofrefund', 'amountdueimmediately', 'currentbalancedue',
        'ssnfeinifany', 'amountowed', 'amount',
        'totalbalancedue', 'balance', 'amounttoberefundedtoyou',
        'totaltaxamount', 'taxamount', 'amountyouowe',
        'paymentamount', 'balancedue', 'amtdue',
        'refundamount', 'taxyears', 'adjustedrefund'],
    noticeDate: ['date', 'letterdate', 'maildate',
        'documentdate', 'noticedate', 'issued',
        'dateofnotice', 'dateissued', 'issueddate',
        'checkdate'],
    taxType: ['taxtype', 'taxsubject', 'taxessubject'],
    dueDate: ['paymentduedate', 'amountdueby', 'finalduedateforpayment',
        'duedate', 'datedue', 'extendedduedate', 'payamountdueby',
        'payby', 'finaldateforpayment'],
    noticeID: ['documentnumber', 'certificatenumber', 'documentlocatorno',
        'letterno', 'collectioncaseid', 'case',
        'inreplyreferto', 'documentloc', 'caseid',
        'refundid', 'filenumber', 'collectioncase',
        'casenumber', 'referto', 'documentlocatornumber',
        'referencenumber', 'lienid', 'noticeid',
        'returncontrolnumber', 'contractobjectnumber', 'webfilenumber',
        'letterid', 'notice', 'account',
        'warrantnumber', 'scfilenumber', 'warrantno',
        'noticenumber', 'indentificationno', 'warrantid',
    ],

};

const madMapper = (inputKey) => {
    const outputKeys = Object.keys(mappingTable);
    let replacementKey = '';

    outputKeys.forEach(function (outputKey) {
        if (mappingTable[outputKey].includes(inputKey.slice(0, -1))) {
            replacementKey = outputKey;
        }
    });
    
    return replacementKey;
};

export { mappingTable, madMapper };
