/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useState, useContext } from 'react';
import { Row, Col, Modal, Button } from "antd";

import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';
import { formatNotices } from "src/utils/NoticeManagement";
import PermissionService, { DownloadNoticeScope } from 'src/services/PermissionService';

import DownloadNotice from 'src/utils/DownloadNotice';

import UpdateNotice from './Update';
import LeftPane from './LeftPane';

const NoticeViewer = (props) => {
    const [ visible, setVisible ] = useState(false);
    const { notices, entities } = useContext(GlobalStateContext);

    const index = notices.findIndex((notice) => notice.unique_id === props.location.pathname.split('/')[2]);
    const currentNotice = formatNotices([notices[index]], entities)[0];

    const generateFileName = (raw) => {
        if (!raw) return '';
        const rawName = raw.split('/')[2];
        if (!rawName) return '';
        return decodeURI(rawName);
    };

    return (
        <Row className="notice-view-container w-full">
            <Col lg={12} md={24} xs={24} className="notice-img-container">
                <LeftPane
                    onClick={() => {
                        setVisible(true);
                    }}
                    currentNotice={currentNotice}
                />
            </Col>
            <Col lg={12} md={24} xs={24} className="notice-view-details dark-theme">
                <Row justify="center">
                    <UpdateNotice
                        currentNotice={currentNotice}
                    />
                </Row>
            </Col>
            <Modal
                title={generateFileName(currentNotice.original_filename)}
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width="100vh"
                cancelButtonProps={{ style: { display: 'none' } }}
                maskClosable
                footer={[
                    <PermissionService scope={DownloadNoticeScope}>
                        <DownloadNotice currentNotice={currentNotice} />
                    </PermissionService>,
                    <Button
                        key="done"
                        type="primary"
                        onClick={() => setVisible(false)}
                    >
                        Done
                    </Button>,
                ]}
            >
                <LeftPane
                    onClick={() => {
                        setVisible(true);
                    }}
                    currentNotice={currentNotice}
                />
            </Modal>
        </Row>
    );
};

export default NoticeViewer;
