import React, { useContext } from 'react';
import { get } from 'lodash';
import { Layout } from 'antd';
import { AuthContext } from 'src/services/AuthService/Authentication';
import Logo from './Logo';
import User from './User/User';
import 'src/App.less';
const { Header } = Layout;

const ProtectedAdminHeader = () => {
    const { userInfo } = useContext(AuthContext);
    const userName = get(userInfo, 'attributes.name', 'User');
    const customerName = get(userInfo, 'attributes.custom:customer', 'User');
    let access = get(userInfo, 'attributes.custom:access', 'Normal');
    access = access.charAt(0).toUpperCase() + access.slice(1);
    return (
        <Header className="bg-white py-0 px-4">
            <Logo />
            <div className="flex float-right">
                <User username={userName} customerName={customerName} />
            </div>
        </Header>
    );
};
export default ProtectedAdminHeader;
