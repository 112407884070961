const noticeTypesConversion = [
    { frontendName: 'Payment', databaseName: 'payment' },
    { frontendName: 'Refund', databaseName: 'refund' },
    { frontendName: 'Return Correction Notice', databaseName: 'returncorrectionname' },
    { frontendName: 'Apportionment Issue', databaseName: 'apportionmentissue' },
    { frontendName: 'Net Operating Losses', databaseName: 'netoperatinglosses' },
    { frontendName: 'Rejection of Prior Year Credits', databaseName: 'rejectionofprioryearcredits' },
    { frontendName: 'Information Request', databaseName: 'informationrequest' },
    { frontendName: 'Late Filing Penalty', databaseName: 'latefilingpenalty' },
    { frontendName: 'Notice of Assessment', databaseName: 'noticeofassessement' },
    { frontendName: 'Notice of Adjustment', databaseName: 'noticeofadjustment' },
    { frontendName: 'Statement of Account', databaseName: 'statementofaccount' },
    { frontendName: 'Audit/Review', databaseName: 'auditreview' },
    { frontendName: 'Demand for Final Payment', databaseName: 'demandforfinalpayment' },
    { frontendName: 'Notice of State Tax Lien', databaseName: 'noticeofstatetaxlien' },
    { frontendName: 'E-Filing Waiver', databaseName: 'efilingwaiver' },
    { frontendName: 'Other', databaseName: 'other' },
    { frontendName: 'Not Specified', databaseName: 'notspecified' },
];

export { noticeTypesConversion };
