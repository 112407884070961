import React from 'react';
import {
    Form, Col, Select
} from 'antd';

const { Option } = Select;

const AccessLevelItem = () => {
    return (
        <Col span={12}>
            <Form.Item
                name="access"
                label="Access level"
                rules={[{ required: true, message: 'Please indicate access rights' }]}
            >
                <Select placeholder="Indicate access rights">
                    <Option value="admin">Administrator access</Option>
                    <Option value="regular">Regular</Option>
                    <Option value="3rdparty">Third party</Option>
                </Select>
            </Form.Item>
        </Col>
    );
};

export default AccessLevelItem;
