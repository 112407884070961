import React, { useState, useEffect } from "react";
import ReactExport from "react-data-export";
import { Button, Modal, Typography } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import moment from "moment";
import { uniq } from "lodash";

import {
	selectAmount,
	selectBacklogAmount,
	selectDueDate,
	selectFEIN,
	extractTaxYear
} from "src/utils/NoticeManagement";

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { Paragraph } = Typography;

const columns = [
	{ title: "Tax Authority", width: { wch: 20 } },
	{ title: "Entity Name", width: { wch: 50 } },
	{ title: "Notice Type", width: { wch: 20 } },
	{ title: "Tax Year", width: { wch: 20 } },
	{ title: "FEIN", width: { wch: 20 } },
	{ title: "Amount in US$", width: { wch: 20 } },
	{ title: "Due Date", width: { wch: 20 } },
	{ title: "Upload Time", width: { wch: 20 } }
];

const Export = props => {
	const { tableData, entities } = props;
	const [ exportData, setExportData ] = useState([]);
	const [ modalStatus, toggleModal ] = useState(false);
	
	const prepareExportData = tableData => {
		const orderedDocuments = [];

		tableData.forEach(function (item) {
			const currentKeys = Object.keys(item);
			const orderedItem = {
				taxauthority: item.taxauthority,
				entity: item.entity && typeof item.entity === 'object' ? item.entity.recognized_entity : (item.entity || ''),
				notice_type: item.noticetype,
				tax_year: extractTaxYear(item) || 0,
				fein: selectFEIN(item, currentKeys, entities),
				amount: item.backlog ? selectBacklogAmount(item, currentKeys) : selectAmount(item, currentKeys),
				due_date: selectDueDate(item, currentKeys),
				upload_time: item.upload_time
			};

			const row = [];
			const values = Object.values(orderedItem);

			for (let i = 0; i < values.length; i++) {
				const element = { value: values[i] };
				row.push(element);
			}
			orderedDocuments.push(row);
		});
		
		setExportData(orderedDocuments);
	};

	useEffect(() => {
		tableData !== null && prepareExportData(tableData);
	}, [ tableData ]);

	const documentsExport = [
		{
			columns,
			data: exportData
		}
	];

	const today = moment().format("YYYY-MM-DD");

	function renderModal() {
		const taxAuths = uniq(documentsExport[0].data.map(item => item[0].value));
		const years = uniq(
			documentsExport[0].data.map(item => item[3] && item[3].value)
		)
		.filter(item => item)
		.map(item => parseInt(item, 10) || false);

		return (
			<Modal
				title="Current export selection"
				visible={modalStatus}
				onCancel={() => toggleModal(false)}
				cancelText="Cancel"
				okText={
					<div className="">
						<ExcelFile
							filename={`StreamDownload_${today}`}
							element={<div>Export</div>}
						>
							<ExcelSheet dataSet={documentsExport} name="Organization" />
						</ExcelFile>
					</div>
				}
			>
				<div>
					<Paragraph>
					<ul>
						<li>{`Tax Authorities: ${taxAuths.length} `}</li>
						{years.length 
						? <li>
							Tax years:
							<ul>
								{years.map(year => (
									year ? <li key={year}>{year}</li> : null
								))}
							</ul>
						</li>
						: null
						}
					</ul>
					</Paragraph>
				</div>
			</Modal>
		);
	}

	return (
		<>
			<Button
				type="link"
				onClick={() => {
					toggleModal(true);
				}}
			>
				<FileExcelOutlined />
				Export
			</Button>
			{renderModal()}
		</>
	);
};

export default Export;
