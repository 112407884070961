import React from 'react';
import moment from 'moment';
import {
    Col, Form, DatePicker
} from 'antd';

const NoticeDateItem = ({ currentNotice }) => {
    return (
        <Col span={12}>
            <Form.Item
                label="Notice Date"
                name="noticeDate"
                key="noticeDate"
                initialValue={moment(currentNotice.noticeDate).isValid() ? moment(currentNotice.noticeDate) : null}
            >
                <DatePicker
                    style={{ width: 180 }}
                />
            </Form.Item>
        </Col>
    )
}

export default NoticeDateItem;
