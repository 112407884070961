/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useContext } from 'react';
import { Space } from 'antd';
import Comments from 'src/components/Comments/Comments';
import FetchContext from 'src/services/FetchService/FetchContext';
import { PocketViewCSS } from 'src/ProtectedComponents/NoticeManagement/NoticeViewer/styles';

const Show = ({ noticeId, comments, updateComments }) => {
    const { comments: { addComment } } = useContext(FetchContext);

    const renderComments = ({ commentData, index }) => (
        <Comments
            key={`LIST_COMMENTS_${index}`}
            onReplyUpdate={async (data) => {
                const { content: comment, attachments } = data;
                const { unique_id: reply } = commentData;
                try {
                    await addComment({
                        noticeId,
                        body: {
                            comment,
                            reply,
                            attachments,
                        },
                    });
                    const updatedReply = [...comments];
                    const { replyComments: prevReplyComments = [] } = updatedReply[index];

                    updatedReply[index].replyComments = [...prevReplyComments, data];
                    updateComments(updatedReply);
                } catch (e) {
                    console.log({ e });
                }
            }}
            replyAction
            {...commentData}
        />
    );

    return (
        <Space direction="vertical" css={PocketViewCSS} className="w-full overscroll-contain overflow-y-scroll overflow-hidden scrollbox">
            {comments.map((commentData, index) => (
                renderComments({ commentData, index })
            ))}
        </Space>
    );
};

export default Show;
