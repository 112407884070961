import React from 'react';
import {
    Form, Col, Input
} from 'antd';

const StateIDItem = ({ name, fieldKey, isListField, withRemoveButton }) => {
    return (
        <Col span={withRemoveButton ? 11 : 12}>
            <Form.Item
                name={name}
                fieldKey={fieldKey}
                isListField={isListField}
                label="State ID"
                className="mt-4"
                rules={[{ required: true, message: 'Please enter state ID' }]}
            >
                <Input placeholder="Please enter state ID" />
            </Form.Item>
        </Col>
    );
};

export default StateIDItem;
