import React, { useState } from 'react';
import { Auth } from 'aws-amplify';

import {
    Form, Input, Button, Row, Col, Typography,
} from 'antd';

import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

const { Title } = Typography;

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const formItemLayout = {
    labelCol: {
        sm: { span: 8 },
    },

};

const ConfirmSignup = (props) => {
    const locationSearch = useLocation().search;

    const confirmSignUp = (locationSearch) => {
        const queryParams = parse(locationSearch);
        Auth.confirmSignUp(queryParams.username, queryParams.code, {
            // Optional. Force user confirmation irrespective of existing alias. By default set to True.
            forceAliasCreation: false,
        }).then((data) => {
            console.log(data);
            props.history.push('/login');
        })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="items-center">
            <Title level={4}>
                Welcome to STREAM!
            </Title>
            <Button onClick={() => confirmSignUp(locationSearch)}>
                Confirm signup
            </Button>
        </div>

    );
};

export default ConfirmSignup;
