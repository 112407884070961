import React from 'react';
import {
    Form, Input
} from 'antd';

const RepeatPasswordItem = () => {
    return (
        <Form.Item name="repeatpassword" label="Repeat password" dependencies={['newpassword']} rules={[{ required: true, message: 'Please confirm your password' }]}>
            <Input.Password></Input.Password>
        </Form.Item>
    );
};

export default RepeatPasswordItem;
