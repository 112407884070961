import React from 'react';
import {
    Form, Col, Input
} from 'antd';

const LocalIDItem = () => {
    return (
        <Col span={12}>
            <Form.Item
                name="portfoliocode"
                label="Local ID"
                className="mt-4"
                tooltip="You can add entity classification codes, for example like the one you use within your financial administration"
            >
                <Input placeholder="Please enter local ID" />
            </Form.Item>
        </Col>
    );
};

export default LocalIDItem;
