/** @jsx jsx */

import { css, jsx } from "@emotion/core";
import React, { useContext, useState } from "react";
import {
  Form,
  Button,
  Typography,
  Row,
  Col,
  Space,
  Modal,
  message,
  Dropdown,
  Menu
} from "antd";
import {
  CheckOutlined,
  InfoCircleOutlined,
  DownOutlined
} from "@ant-design/icons";

import FetchContext from "src/services/FetchService/FetchContext";
import { filter } from "lodash";
import { GlobalStateContext } from "src/services/GlobalState/GlobalState";

import PermissionService, {
  DeleteBacklogScope,
  UpdateBacklogScope
} from "src/services/PermissionService";
import { AuthContext } from "src/services/AuthService/AuthProvider";
import AddAssignedUserItem from "./Fixed/AddAssignedUserItem";
import AddTaxLevelItem from "./Fixed/AddTaxLevelItem";
import AddEntityItem from "./Fixed/AddEntityItem";
import AddNoticeCategoryItem from "./Fixed/AddNoticeCategoryItem";
import DueDateItem from "./Fixed/DueDateItem";
import NoticeDateItem from "./Fixed/NoticeDateFormItem";
import NoticeIDItem from "./Fixed/NoticeIDFormItem";
import AmountItem from "./Fixed/AmountFormItem";
import TaxPeriodItem from "./Fixed/TaxPeriodFormItem";

import DynamicFormItems from "./Dynamic/DynamicFormItems";
import postConfirmation from "./postConfirmation";

import HeaderDynamic from "./Dynamic/HeaderDynamic";
import LeftPane from "./LeftPane";
import { formItemLayout } from "../BacklogUtils";

import InfoProgress from "../InfoProgress";
import AddEntityItem2 from "./Fixed/AddEntityItem2";

const { Title } = Typography;

const FormGenerator = () => {
  const {
    backlogconfirmation: { confirmBacklog },
    deleteBacklog: { deleteBacklog }
  } = useContext(FetchContext);
  const { users, backlogs, count, increaseCount } = useContext(
    GlobalStateContext
  );
  const { permissions } = useContext(AuthContext);

  /*
   *  Validate user permission.
   *   Info: Third party users do not have permission to change values in Backlog.
   */

  const noAccess = !permissions.has(UpdateBacklogScope);

  const [infoModalVisbility, setInfoModalVisibility] = useState(false);
  const [deleteModalVisible, setDeleteModal] = useState(false);

  const backlogNotices = filter(backlogs, ["notice_status", "notice parsed"]);
  const currentNotice = backlogNotices[count];

  // state hooks

  const imagePath = `${currentNotice.original_filename.split("/")[1]}/${
    currentNotice.original_filename.split("/")[2]
  }&&${currentNotice.unique_id}.pdf`;

  // Form-related

  const [form] = Form.useForm();

  const handleSubmit = async values => {
    try {
      await confirmBacklog(postConfirmation(values, currentNotice));
      increaseCount();
    } catch (e) {
      console.log({ e });
    }
    form.resetFields();
  };

  function handleDeleteBacklog() {
    const { unique_id } = currentNotice;
    deleteBacklog({ unique_id })
      .then(res => {
        if (res === "OK") {
          message.success("Notice has  been deleted");
        }
        setDeleteModal(false);
        increaseCount();
        form.resetFields();
      })
      .catch(e => {
        setDeleteModal(false);
        message.error(
          "There was an error deleting this notice. Please try again later."
        );
        //    Eat the error!
      });
  }

  const handleEntityCreate = data => {
    form.setFieldsValue({ entity: data });
  };

  const handleIdCreate = data => {
    form.setFieldsValue({ noticeID: data });
  };

  const handleAmountCreate = data => {
    form.setFieldsValue({ amount: data });
  };

  function renderConfirmationModal() {
    return (
      <Modal
        title="Please confirm to continue."
        centered
        visible={deleteModalVisible}
        onOk={() => handleDeleteBacklog()}
        onCancel={() => setDeleteModal(false)}
        width="100vh"
        okButtonProps={{
          style: { backgroundColor: "red", borderColor: "transparent" }
        }}
        okText="DELETE"
        cancelText="Cancel"
        maskClosable
      >
        <div>
          Are you sure you want to permanently delete this notice and the data?
        </div>
      </Modal>
    );
  }
  function renderDeleteButton() {
    return (
      <Button
        className="hover-bg-red-500 hover-text-white hover-border-transparent justify-self-center content-between"
        onClick={() => setDeleteModal(true)}
      >
        DELETE
      </Button>
    );
  }

  return (
    <>
      <div className="flex pt-10">
        <div className="w-1/2">
          <LeftPane currentNotice={currentNotice} imagePath={imagePath} />
        </div>
        <div className="w-1/2 pl-10">
          <div className="flex w-full">
            <div className="w-11/12">
              <Title level={4}> Notice Data </Title>
            </div>

            <div className="w-1/12">
              <Button
                icon={<InfoCircleOutlined />}
                shape="round"
                onClick={() => setInfoModalVisibility(true)}
              />
              <InfoProgress
                infoModalVisbility={infoModalVisbility}
                setInfoModalVisibility={setInfoModalVisibility}
              />
            </div>
          </div>
          <Form
            {...formItemLayout}
            form={form}
            name="backlog"
            onFinish={values => handleSubmit(values)}
            className="w-full pb-24"
          >
            <div className="bg-white py-2 px-6 rounded-lg space-y-2">
              <AddTaxLevelItem
                disabled={noAccess}
                key="AddTaxLevel"
                currentNotice={currentNotice}
              />
              {/* <AddEntityItem
                disabled={noAccess}
                key="AddEntityItem"
                currentNotice={currentNotice}
              /> */}
              <AddEntityItem2
                disabled={noAccess}
                onCreate={handleEntityCreate}
                key="AddEntityItem"
                currentNotice={currentNotice}
              ></AddEntityItem2>

              <TaxPeriodItem
                disabled={noAccess}
                key="TaxPeriodItem"
                currentNotice={currentNotice}
              />
              <AddNoticeCategoryItem
                disabled={noAccess}
                key="AddNoticeCategory"
                noticeType={currentNotice.noticetype}
              />
              <NoticeDateItem
                disabled={noAccess}
                key="NoticeDateItem"
                currentNotice={currentNotice}
              />
              <NoticeIDItem
                disabled={noAccess}
                key="NoticeIDItem"
                onCreate={handleIdCreate}
                currentNotice={currentNotice}
              />
              <DueDateItem
                disabled={noAccess}
                key="DueDateItem"
                currentNotice={currentNotice}
              />
              <AmountItem
                disabled={noAccess}
                onCreate={handleAmountCreate}
                key="AmountItem"
                currentNotice={currentNotice}
              />
            </div>
            <Title level={4} className="my-6">
              Workflow
            </Title>
            <div className="bg-white p-2 rounded-lg">
              <AddAssignedUserItem
                key="AddUserItem"
                users={users}
                currentNotice={currentNotice}
                disabled={noAccess}
              />
            </div>

            <div className="flex flex-row justify-between pt-8 items-center">
              <Button
                disabled={noAccess}
                type="primary"
                htmlType="submit"
                size="large"
              >
                <CheckOutlined />
                Submit Entries
              </Button>
              <PermissionService scope={DeleteBacklogScope}>
                {renderDeleteButton()}
              </PermissionService>
            </div>
          </Form>
        </div>
        {renderConfirmationModal()}
      </div>
    </>
  );
};
export default FormGenerator;
