import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import ShowScrollablePDF from 'src/ProtectedComponents/Backlog/Form/ShowScrollablePdf';

const LeftPane = (props) => {
    const { currentBacklog } = props;
    const [image, setImage] = useState(null);

    const fileNameArray = currentBacklog.original_filename.split('/');
    let imagePath = `${currentBacklog.original_filename.split('/')[1]}/${currentBacklog.original_filename.split('/')[2]}&&${currentBacklog.unique_id}.pdf`;

    if (fileNameArray.length === 2) {
        imagePath = `${currentBacklog.original_filename.split('/')[1]}&&${currentBacklog.unique_id}.pdf`;
    }

    const fetchImage = () => {
        Storage.get(imagePath, { level: 'public' })
            .then((data) => setImage(data))
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchImage();
    }, []);

    return (
        <ShowScrollablePDF
            {...props}
            image={image}
        />
    );
};

export default LeftPane;
