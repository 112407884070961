import React from 'react';
import {
    Row, Col, Form, InputNumber
} from 'antd';
import dollarImg from "../../../../../../assets/img/dollar-icon.svg";
import { numFormat } from "../../../../../../utils/numFormat"

const AmountItem = ({ currentNotice }) => (
    <div className="notice-edit-summary mt-8" style={{ backgroundImage: `url('${dollarImg}')` }}>
        <Row gutter={20}>
            <Col span={12}>
                <Row
                    className="h-full flex-col"
                    justify="space-between"
                >
                    {/* <Text>Amount</Text>
                    <Text className="text-sm">
                        Lorem ipsum dolor sit{" "}
                        <TagFilled style={{ color: COLORS.warning }} /> amet,
                        consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua.
                    </Text> */}
                </Row>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Amount"
                    name="amount"
                    key="amount"
                    initialValue={currentNotice.amount_selected}
                >
                    <InputNumber
                        className="w-full"
                        size="large"
                        min={0}
                        formatter={numFormat}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                </Form.Item>
            </Col>
        </Row>
    </div>
);

export default AmountItem;
