import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, notification, Row, Popconfirm, message } from 'antd';
import UsersTable from '../../Users/Table';
import { getUsers, deleteUser } from 'src/utils/getUserList'
import { COLORS } from 'src/theme/colors';
import Add from './Add';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'src/services/AuthService/AuthProvider';
import Update from './Update';
import { ToolOutlined, DeleteOutlined } from 'src/components/shared/Icons';
import { GlobalStateContext } from '../../../../services/GlobalState/GlobalState';
import {CloudDownloadOutlined, CloudUploadOutlined} from '@ant-design/icons';
import ReactFileReader from 'react-file-reader';
import { Auth } from 'aws-amplify';

const Users = () => {
    const history = useHistory();
    const [User, rfetch] = useState({});
    const [ userData, setUserData ] = useState(null);
    const [ updateUserVisibility, setUpdateUserVisibility ] = useState(false);
    const { userInfo } = useContext(AuthContext);
    const { reFetch } = useContext(GlobalStateContext);
    let reader = null

    const columns = [
        {
            title: 'Username',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Access',
            dataIndex: 'access',
            key: 'access',
        },
        {
            title: 'Company',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer) => (
                <div onClick={() => redirect(customer)} className="company-hover">
                    {customer}
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'userstatus',
            key: 'userstatus',
            render: (userstatus) => (
                <span style={{ color: userstatus === 'CONFIRMED' ? COLORS.primary : COLORS.warning }}>
                    {userstatus}
                </span>
            ),
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <Row gutter={16}>
                    <Col span={9}>
                        <Button
                            type="link"
                            icon={<ToolOutlined />}
                            onClick={() => updateUser(record)}
                            className="px-0"
                        >
                            Update
                        </Button>
                    </Col>
                    <Col span={9}>
                        <Popconfirm
                            title="Are you sure you want to delete this user?"
                            okText="Yes"
                            onConfirm={() => delete_user(record)}
                        >
                            <Button 
                                type="link"
                                icon={<DeleteOutlined style={{ color: COLORS.danger }} />}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            ),
        },
    ];
    const handleDownload = () => {
        let data = User.data.map((elem)=>{
            return ({name: elem.name, customer: elem.customer, access:elem.access, email: elem.email, userStatus: elem.userstatus})
        })

        // // Converting data to csv
        const csvData = data.map(row => Object.values(row).join(',')).join('\n');
      
        // Creating downloadable link 
        const csvUrl = URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
      
        //triggering download
        const link = document.createElement('a');
        link.href = csvUrl;
        link.download = 'data.csv';
        document.body.appendChild(link);
        link.click();


    }

    //upload csv    
     const handleFiles = files => {
        let readerData;
        reader = new FileReader();
        reader.onload = function(e) {
            readerData = reader.result

            let csvArray = JSON.stringify(reader.result).split('\\n')
            csvArray.map((e,index)=>{
                signUp(e)
            })
        
        }
        reader.readAsText(files[0]);

    }

    const signUp = async (e) => {
        const signUpData = e.split(',')
        try {
            await Auth.signUp({
                username:signUpData[3].trim(),
                password:'password123',
                attributes: {
                    name: signUpData[1],
                    'custom:customer': signUpData[4].replace('"',''),
                    'custom:access': signUpData[3],
                    email:signUpData[3].trim()
                },
                validationData: [], // optional
            });
        }
        catch (err) {
            console.log(err)
        }
    }
    const fetchData = useCallback( async () => {
        try {
            let fetch = await getUsers();
            const data = fetch.users_list.reduce((initialData, user) => {
                const nameIndex = user.Attributes.findIndex((element) => element.Name === 'name');
                const emailIndex = user.Attributes.findIndex((element) => element.Name === 'email');
                const accessIndex = user.Attributes.findIndex((element) => element.Name === 'custom:access');
                const customerIndex = user.Attributes.findIndex((element) => element.Name === 'custom:customer');
                user.Attributes[customerIndex] && initialData.companies.push(user.Attributes[customerIndex].Value);
                const user_object = {
                    key: user.Username,
                    name: user.Attributes[nameIndex] ? user.Attributes[nameIndex].Value : '',
                    email: user.Attributes[emailIndex] ? user.Attributes[emailIndex].Value : '',
                    access: user.Attributes[accessIndex] ? user.Attributes[accessIndex].Value : '',
                    customer: user.Attributes[customerIndex] ? user.Attributes[customerIndex].Value : '',
                    userstatus: user.UserStatus,
                };
                initialData.data.push(user_object);
                return initialData;
            }, { data: [], companies: [] });

            rfetch(data);
        } catch (error) {
            console.error(error)
        }
    },[])

    const updateUser = (record) => {
        setUpdateUserVisibility(true);
        setUserData(record);
    };

    const delete_user = async (record) => {
        try {
            await deleteUser(record.key)
            message.success(
                "User has been deleted successfully"
            );
            fetchData()
        } catch (error) {
            message.error(
                "Failed to delete user"
              );
            console.log(error)
        }
    };

    const redirect = async (value) => {
        localStorage.setItem('company', JSON.stringify(value));
        await reFetch(value);
        history.push('/noticemanagement');
    }
    
    useEffect(() => {
        if (userInfo && userInfo.attributes['custom:access'] !== 'SuperAdmin') {
            history.push('/login');
            notification.open({
                message: `You are unauthorized`,
                placement: 'topLeft',
            });
        }
        // fetchData();
    }, [])

    return (
        <>
            <Row justify="end" 
            style={{
                // border:'1px solid black',
                display:'flex',
                justifyContent:'flex-end',
                alignItems:'center'
            
            }}
            >
                {/* upload button */}
                <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
                    <span
                    style={{
                        marginRight:20,
                        color:'#8998ac',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        cursor:'pointer'
                    }}
                    >
                    <CloudUploadOutlined 
                    style={{marginRight:10,fontSize:25,color:'#0BA6B3'}}
                    />
                    UPLOAD CSV
                </span> 
                </ReactFileReader>

                {/* download button */}
                <span  onClick={handleDownload}
                style={{
                    marginRight:20,
                    color:'#8998ac',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    cursor:'pointer'
                }}
                className="pl-0 my-4"
                >
                    <CloudDownloadOutlined 
                    style={{marginRight:10,fontSize:25,color:'#0BA6B3'}}
                    />
                    DOWNLOAD CSV
                </span>
                <Add companies={[...new Set(User.companies)]} fetchData={fetchData}/>
                </Row>
            <UsersTable columns={columns} data={User.data} />
            <Update 
                visibility={updateUserVisibility} 
                setVisibility={setUpdateUserVisibility} 
                userData={userData} 
                // onDone={fetchData}
            />
        </>
    );
};
export default Users;