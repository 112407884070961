export const COLORS = {
  primary: "#0BA6B3",
  secondary: "#8998AC",
  dark: "#8392A7",
  text: "#57616C",
  success: "#5FC8BF",
  warning: "#F0BB00",
  danger: "#FF6060",
  backgroundLight: "#EFF2F7",
  backgroundDark: "#57616C",
};
