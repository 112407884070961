import React from 'react';
import {
    Typography, Space, Row
} from 'antd';
import {
    FileExcelOutlined,
    MachineOutlined,
    CircleOutlined,
    ScanOutlined,
    EyeOutlined
} from '../../components/shared/Icons';
import { COLORS } from '../../theme/colors';

const { Paragraph, Text } = Typography;

const UploadInfo = () => {
    return (

        <div className="dark-theme">
            <Paragraph type="secondary" style={{ marginTop: 50 }}>
                An upload marks the start of the lifecycle of a state tax notice within
                STREAM. The current status of notices that have been uploaded today is
                shown in the table below.
            </Paragraph>
            <Paragraph type="secondary">There are various stages:</Paragraph>
            <table style={{ fontSize: "0.8em" }}>
                <tbody>
                    <tr>
                        <td>
                            <FileExcelOutlined style={{ color: COLORS.secondary }} />
                        </td>
                        <td>
                            <Text type="secondary">NOTICE</Text>
                        </td>
                        <td>
                            <Text type="warning">UPLOAD</Text>
                        </td>
                        <td>
                            <CircleOutlined style={{ color: COLORS.warning }} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ScanOutlined style={{ color: COLORS.secondary }} />
                        </td>
                        <td>
                            <Text type="secondary">OCR</Text>
                        </td>
                        <td>
                            <Text type="secondary">IN PROGRESS</Text>
                        </td>
                        <td>
                            <CircleOutlined style={{ color: COLORS.secondary }} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ScanOutlined style={{ color: COLORS.secondary }} />
                        </td>
                        <td>
                            <Text type="secondary">OCR</Text>
                        </td>
                        <td>
                            <Text className="text-success">COMPLETED</Text>
                        </td>
                        <td>
                            <CircleOutlined style={{ color: COLORS.success }} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <MachineOutlined style={{ color: COLORS.secondary }} />
                        </td>
                        <td>
                            <Text type="secondary">MACHINE LEARNING</Text>
                        </td>
                        <td>
                            <Text type="secondary">IN PROGRESS</Text>
                        </td>
                        <td>
                            <CircleOutlined style={{ color: COLORS.secondary }} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <FileExcelOutlined style={{ color: COLORS.secondary }} />
                        </td>
                        <td>
                            <Text type="secondary">NOTICE</Text>
                        </td>
                        <td>
                            <Text className="text-primary">CLASSIFIED</Text>
                        </td>
                        <td>
                            <CircleOutlined style={{ color: COLORS.primary }} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <Row className="notice-upload-footer" justify="center">
                <Space>
                    <EyeOutlined
                        style={{
                            color: COLORS.success,
                            fontSize: "1.2em",
                            display: "block",
                        }}
                    />
                    <Text style={{ textTransform: "uppercase", fontSize: "0.8em" }}>
                        Clicking on a row will show the notice.
                    </Text>
                </Space>
            </Row>
        </div>
    );
};
export default UploadInfo;
