import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, Button, Space, Popconfirm, message } from 'antd';
import { filter } from 'lodash';
import Add from './Add';
import Update from './Update';
import EntitySearch from './Search';
import EntitiesTable from './Table';
import { COLORS } from 'src/theme/colors';
import { ToolOutlined, DeleteOutlined } from 'src/components/shared/Icons';
import FetchContext from 'src/services/FetchService/FetchContext';
import { AuthContext } from 'src/services/AuthService/Authentication';
import { WriteOrganizationScope } from 'src/services/PermissionService';
import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

const Entities = () => {
    const { permissions } = useContext(AuthContext);
    const { entities, notices, deleteEntityState } = useContext(GlobalStateContext);
    const { entitymanagement: { deleteEntity } } = useContext(FetchContext);
    const [ data, setData ] = useState(null);
    const [ entityData, setEntityData ] = useState(null);
    const [ visibility, setVisibility ] = useState(false);
    const searchQuery = useRef('');

    /*
     *  Validate user permission.
     *  Info: Employee role can only see the entity list but can not edit anything.
    */
    const noAccess = !permissions.has(WriteOrganizationScope);

    useEffect(() => {
        handleSearch(searchQuery.current);
    }, [entities, notices]);

    const entityLinked = (record) => {
        return notices.some(e => e.entity === record.entity);
    };

    const updateEntity = (record) => {
        setEntityData(record);
        setVisibility(true);
    };

    const columns = [
        {
            title: 'Entity',
            dataIndex: 'entity',
            width: '36%',
        },
        {
            title: 'FEIN',
            dataIndex: 'fein',
        },
        {
            title: 'Local ID',
            dataIndex: 'portfoliocode',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, record) => (
                <Space>
                    <Button type="link" icon={<ToolOutlined />} disabled={noAccess} onClick={() => updateEntity(record)} className="px-0">
                        {' '}
                        Update
                    </Button>
                        {!entityLinked(record) 
                        ? <Popconfirm
                            title="Are you sure you want to delete this entity?"
                            okText="Yes"
                            onConfirm={() => handleDeleteEntity(record)}
                            disabled={noAccess}
                        >
                            <Button 
                                disabled={noAccess}
                                type="link"
                                icon={<DeleteOutlined style={{ color: COLORS.danger }} />}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                        : <Button
                            disabled={true} 
                            type="link"
                            icon={<DeleteOutlined style={{ color: COLORS.danger }} />}
                        >
                            Delete
                        </Button>
                        }
                </Space>
            ),
        },
    ];

    const handleSearch = (query) => {
        searchQuery.current = query;
        const searchResult = filter(
            entities, 
            (o) => o.entity.toLowerCase().includes(query.toLowerCase()) 
                || (o.fein && o.fein.toLowerCase().includes(query.toLowerCase()))
                || (o.portfoliocode && o.portfoliocode.toLowerCase().includes(query.toLowerCase()))
        );
        setData(searchResult);
    };

    const handleDeleteEntity = async (record) => {
        try {
            await deleteEntity(record.entity);
            deleteEntityState(record);
            message.success('Entity has been deleted.');
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Row justify="space-between">
                <EntitySearch handleSearch={handleSearch} />
                <Add noAccess={noAccess} />
            </Row>
            <EntitiesTable columns={columns} data={data} />

            <Update 
                entityData={entityData} 
                visibility={visibility} 
                setVisibility={setVisibility} 
            />
        </>
    );
};

export default Entities;
