import React from 'react';
import {
    Select,
} from 'antd';

const { Option } = Select;

const User = ({ handleUserChange, cachedValues, users }) => {
    const userChildren = [];
    users.map((user) => {
        const nameIndex = user.attributes.findIndex((attribute) => attribute.Name === 'name');
        const name = user.attributes[nameIndex].Value;
        userChildren.push(<Option key={name}>{name}</Option>);

        return userChildren;
    });

    userChildren.push(<Option key="unassigned">Unassigned</Option>);

    return (
        <Select
            mode="multiple"
            placeholder="Assigned User"
            maxTagCount="responsive"
            className="w-full"
            onChange={handleUserChange}
            defaultValue={cachedValues}
        >
            {userChildren}
        </Select>

    );
};

export default User;
