import React from "react";
import { Row, Tabs } from "antd";
import { UsersOutlined, EntitiesOutlined } from 'src/components/shared/Icons';
import Users from './Users/Users';
import Entities from './Entities/Entities';

const { TabPane } = Tabs;

const Settings = () => (
	<div className="container">
		<Row className="page">
			<Tabs style={{ width: "100%" }}>
				<TabPane
					key="users"
					tab={
					<span>
						<UsersOutlined className="mr-1" />
						Users
					</span>
					}
				>
					<Users />
				</TabPane>
				<TabPane
					key="entities"
					tab={
					<span>
						<EntitiesOutlined className="mr-1" />
						Entities
					</span>
					}
				>
					<Entities />
				</TabPane>
			</Tabs>
		</Row>
	</div>
);

export default Settings;
