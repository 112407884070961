import React, { useContext, useState } from 'react'
import {
    Form, Select, Typography, Input, Row, Col, Switch,
} from 'antd';
import tidyName from '../../../../utils/tidyName';
import { AuthContext } from 'src/services/AuthService/Authentication'
import { UpdateBacklogScope } from 'src/services/PermissionService'

const { Option } = Select;
const { Title } = Typography;

const ChangeButton = ({ onChange, disabled }) => (
    <Switch
        disabled={disabled}
        checkedChildren="Auto"
        unCheckedChildren="Edit"
        defaultChecked
        onChange={(status) => onChange(status)}
    />
);

const replaceName = (oldName) => {
    const transformer = {
        entityID: 'Entity ID',
        amount: 'Amount',
        taxPeriod: 'Tax Period',
        noticeDate: 'Notice Date',
        taxType: 'Tax Type',
        dueDate: 'Due Date',
        noticeDate: 'Notice Date',
        noticeID: 'Notice ID',
    };
    return transformer[oldName];
};

export default ({ itemKey: key, options }) => {
    const [staticMode, setStaticMode] = useState(true);
    const { permissions } = useContext(AuthContext);
    /* Get user access level to change values. */
    const noAccess = !permissions.has(UpdateBacklogScope);
    return (
        staticMode ? (
            <>
                <div className="flex space-x-4 items-center">
                    <div className="w-4/12">
                        <Title level={5}>
                            {replaceName(key)}
                        </Title>
                    </div>
                    <div className="w-7/12">

                        <Form.Item
                            name={key}
                            key={Math.random()}
                            initialValue={options[0]}
                        >
                            <Select
                              disabled={noAccess}
                              size="large">
                                {
                                    options.map((item) => <Option value={item} key={item}>{item}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="w-1/12">
                        <ChangeButton
                            disabled={noAccess}
                            onChange={(status) => setStaticMode(status)}
                        />
                    </div>
                </div>
            </>
        ) : (
            <>
                <div className="flex space-x-4 items-center">
                    <div className="w-4/12">
                        <Form.Item
                            key={Math.random()}
                            name={`altered_key_${key}`}
                            initialValue={replaceName(key)}
                        >
                            <Input disabled={noAccess} size="large" />
                        </Form.Item>
                    </div>
                    <div className="w-7/12">
                        <Form.Item
                            key="Value"
                            name={`altered_value_${key}`}
                            initialValue={options[0]}
                        >
                            <Input disabled={noAccess} placeholder="Value" size="large" />
                        </Form.Item>
                    </div>
                    <div className="w-1/12">
                        <ChangeButton
                            disabled={noAccess}
                            onChange={(status) => setStaticMode(status)}
                        />
                    </div>
                </div>

            </>
        )
    );
};
