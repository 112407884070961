import React, { useContext } from 'react';
import {
    Tabs
} from 'antd';
import { FileTextOutlined } from '../../../../components/shared/Icons';
import { withRouter } from 'react-router-dom';
import Remarks from './Remarks';
import { ReadRemarks } from 'src/services/PermissionService';
import { AuthContext } from 'src/services/AuthService/Authentication';
import Details from './Details';

const { TabPane } = Tabs;

const UpdateNotice = (props) => {
    const { currentNotice } = props;
    const { permissions } = useContext(AuthContext);
    const remarksPermission = permissions.has(ReadRemarks);
    const renderRemarks = () => remarksPermission && (
        <TabPane
            tab={
                <span>
                    <FileTextOutlined /> Remarks
                </span>
            }
            key="2"
        >
            <Remarks
                noticeId={currentNotice.unique_id}
            />
        </TabPane>
    );

    return (
        <Tabs
            defaultActiveKey="1"
            style={{ width: 590 }}
        >
            <TabPane
                tab={
                    <span>
                        <FileTextOutlined /> Details
                    </span>
                }
                key="1"
            >
                <Details currentNotice={currentNotice} />
            </TabPane>
            {renderRemarks()}
        </Tabs>
    );
};

export default withRouter(UpdateNotice);
