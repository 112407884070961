import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import {
    Row, Col, Form, Input, Button, message
} from 'antd';
import Paper from 'src/components/shared/Paper';
import Logo from 'src/components/shared/logo';

const ForgotPassword = () => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [errorStatus, setErrorStatus] = useState(null);

    const updatePassword = async (values) => {
        try {
            await Auth.forgotPassword(values.username);
            message.info('You will be sent an email with a link');
            history.push('/forgotpasswordsubmit');
        } catch (err) {
            console.log(err);
            setErrorStatus('error');
            setError(err.message);
        }
    };
    return (
        <Row align="middle" justify="center" style={{ height: "100vh" }}>
            <Col md={14} xl={10} xxl={10} xs={20}>
                <Paper>
                    <Row gutter={15}>
                        <Col md={12} xs={24}>
                            <Row align="middle" justify="center" className="h-full">
                                <Logo width={163} />
                            </Row>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form
                                name="forgotPassword"
                                layout="vertical"
                                style={{ paddingTop: 55 }}
                                onFinish={updatePassword}
                                hideRequiredMark
                            >
                                <Form.Item
                                    label="Email"
                                    name="username"
                                    rules={[
                                        { required: true, message: "Please provide your email!" },
                                    ]}
                                    validateStatus={errorStatus}
                                    help={error}
                                    >
                                    <Input />
                                </Form.Item>

                                <Row justify="center">
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                    >
                                        Send me a new password
                                    </Button>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Paper>
            </Col>
        </Row>
    );
};

export default ForgotPassword;
