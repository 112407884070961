import React from "react";
import { hot } from "react-hot-loader/root";
import Amplify, { Auth } from "aws-amplify";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";
import Authentication from "src/services/AuthService/Authentication";
import { AuthProvider } from "src/services/AuthService/AuthProvider";
import FetchProvider from "src/services/FetchService/FetchProvider";
import PrivateRoutes from "src/routes/PrivateRoutes";
import { getApiUrl } from "src/utils/Helper";
import Rollbar from "rollbar";
import "./App.less";
import awsconfig from "./aws-exports";

ReactGA.initialize("G-MXJECME7VT");
ReactGA.pageview(window.location.pathname + window.location.search);

Amplify.configure(process.env.AMPLIFY_CONFIG || awsconfig);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "stream",
        endpoint: getApiUrl(),
        custom_header: async () => ({
          Authorization: (await Auth.currentSession()).idToken.jwtToken,
        }),
      },
    ],
  },
  oauth: {
    "domain": "taxology-stream.auth.eu-west-1.amazoncognito.com",
    "scope": [
        "aws.cognito.signin.user.admin",
        "email",
        "openid",
        "phone",
        "profile"
    ],
    "redirectSignIn": "https://app.stream.tax",
    "redirectSignOut": "https://app.stream.tax",
    "responseType": "code"
  }
});

class App extends React.Component {
  constructor() {
    super();
    process.env.NODE_ENV === "production" &&
      (this.rollbar = new Rollbar({
        accessToken: "56783ef4be494e45a985894ebfcf44e5",
        captureUncaught: true,
        captureUnhandledRejections: true,
      }));
  }

  render() {
    return (
      <Router>
        <AuthProvider>
          <Authentication>
            <FetchProvider>
              <PrivateRoutes />
            </FetchProvider>
          </Authentication>
        </AuthProvider>
      </Router>
    );
  }
}

export default hot(App);
