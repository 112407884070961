import React, { useMemo, useEffect, useContext } from "react";
import { Tag } from "antd";
// import { GlobalStateContext } from 'src/services/GlobalState/GlobalState';

import { useTable, useFlexLayout, useSortBy } from "react-table";
import { useHistory } from "react-router-dom";
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import classNames from "classnames";
import { formatDate } from 'src/utils/formatDate';

const NoticeTable = ({ items }) => {
	// const { users } = useContext(GlobalStateContext); Check if we want to use this
	const data = items;
	const skipPageResetRef = React.useRef();
	const history = useHistory();

	const columns = useMemo(
		() => [
			{
				Header: "Tax authority",
				accessor: "taxauthority",
				width: 150
			},
			{
				Header: "Entity",
				accessor: "taxpayer",
				width: 160
			},
			{
				Header: "Notice Type",
				accessor: "noticetype",
				width: 150
			},
			{
				Header: "Tax Year",
				accessor: "taxyear_selected",
				width: 80
			},
			{
				Header: "Amount",
				accessor: "amount_selected",
				width: 90,
				className: 'text-right truncate td bg-white',
				Cell: ({ value }) => {
					if (value) {
						return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
					}
					return '-';
				}
			},
			{
				Header: "Due date",
				accessor: "duedate_selected",
				width: 100,
				Cell: ({ value }) => {
					const formattedValue = value//.replace(/-/g, '/');
					const isDate = (date) => {
						return !isNaN(new Date(date));
					};
					if (!isDate(value)) {
						return <div>{value}</div>;
					}
					return <div>{formatDate(formattedValue)}</div>;
				}
			},
			{
				Header: "Status",
				accessor: "noticestatus",
				width: 90,
				Cell: props => {
					switch (props.cell.value) {
						case "inprogress":
							return <Tag color="processing">In progress</Tag>;
						case "completed":
							return <Tag color="success">Completed</Tag>;
						default:
							return <Tag>Unknown</Tag>;
					}
				}
			},
			{
				Header: "User",
				width: 100,
				accessor: "assigneduser",
				className: 'truncate td bg-white'
			},
			{
				Header: "Upload date",
				width: 100,
				accessor: "upload_time",
				className: 'truncate td bg-white',
				Cell: ({ row }) => {
					const { upload_time } = row.original;
					return <div>{formatDate(upload_time)}</div>;
				}
			},

			// TODO: Check if we want to have an 'Uploaded by' column in the table
			// {
			// 	Header: "Uploaded by",
			// 	width: 100,
			// 	accessor: "unique_id",
			// 	className: 'truncate td bg-white',
			// 	Cell: ({ value }) => {
			// 		let nameValue = '';

			// 		// Find the index of the underscore character
			// 		const underscoreIndex = value.indexOf("_");
			// 		// Extract the desired substring before the underscore
			// 		const identifier = value.substring(0, underscoreIndex);

			// 		for (const user of users) {
			// 			const subAttribute = user.attributes.find(attribute => attribute.Name === "sub" && attribute.Value === identifier);
			// 			if (subAttribute) {
			// 				const nameAttribute = user.attributes.find(attribute => attribute.Name === "name");
			// 				if (nameAttribute) {
			// 					nameValue = nameAttribute.Value;
			// 					break;
			// 				}
			// 			}
			// 		}
			// 		return <div>{nameValue}</div>;
			// 	}
			// }
		],
		[]
	);

	const tableInstance = useTable(
		{
			columns,
			data,
			autoResetSortBy: false,
		},
		useSortBy,
		useFlexLayout
	);

	// Applying the sorting from the url to the table
	useEffect(() => {
		const url = new URL(window.location.href)
		const params = new URLSearchParams(url.searchParams)
		const sortFromParams = params.get('sort')

		if (sortFromParams) {
			try {
				//atob is used to decode the base64 string to an object
				tableInstance.setSortBy(JSON.parse(window.atob(sortFromParams)));
			} catch (err) {
				console.warn(`Invalid sort value: "${sortFromParams}": ${err}`)
			}
		}

	}, [history.location.search]);

	// Adding the sorting parameters active on the table to the url
	useEffect(() => {
		const currentURL = new URL(window.location.href);
		if (tableInstance.state.sortBy.length > 0) {
			//btoa is used to encode the object to base64
			currentURL.searchParams.set('sort', window.btoa(JSON.stringify(tableInstance.state.sortBy)));
		} else {
			currentURL.searchParams.delete('sort');
		}

		window.history.replaceState(null, '', currentURL);
	}, [tableInstance.state.sortBy]);


	const handleRowClick = (row) => {
		let route = row.original.backlog ? "/backlogviewer" : "/noticeviewer";
		history.push(`${route}/${row.original.unique_id}`)
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows
	} = tableInstance;

	React.useEffect(() => {
		skipPageResetRef.current = true;
	}, [items]);

	return (
		<>
			<div className="ant-table-wrapper notice-table pb-4" {...getTableProps()}>
				<div className="ant-table-thead">
					{// Loop over the header rows
						headerGroups.map(headerGroup => (
							// Apply the header row props
							<div className="tr" {...headerGroup.getHeaderGroupProps()}>
								{// Loop over the headers in each row
									headerGroup.headers.map(column => {
										return (
											// Apply the header cell props
											<div
												className="th"
												{...column.getHeaderProps(column.getSortByToggleProps())}
											>
												<div className="ant-table-column-sorters">
													<span>{column.render("Header")}</span>
													<span className="ant-table-column-sorter ant-table-column-sorter-full">
														<span className="ant-table-column-sorter-inner">
															<CaretUpFilled
																className={classNames(
																	"ant-table-column-sorter-up",
																	{
																		active: column.isSorted && !column.isSortedDesc
																	}
																)}
															></CaretUpFilled>
															<CaretDownFilled
																className={classNames(
																	"ant-table-column-sorter-down",
																	{
																		active: column.isSorted && column.isSortedDesc
																	}
																)}
															></CaretDownFilled>
														</span>
													</span>
												</div>
											</div>
										);
									})}
							</div>
						))}
				</div>
				{/* Apply the table body props */}
				<div className="ant-table-tbody" {...getTableBodyProps()}>
					{// Loop over the table rows
						rows.map(row => {
							// Prepare the row for display
							prepareRow(row);
							return (
								// Apply the row props
								<div
									className="tr"
									onClick={() => handleRowClick(row)}
									{...row.getRowProps()}
								>
									{// Loop over the rows cells
										row.cells.map(cell => {
											// Apply the cell props
											return (
												<div className="td bg-white" {...cell.getCellProps([
													{
														className: cell.column.className
													}
												])}>
													{// Render the cell contents
														cell.render("Cell")}
												</div>
											);
										})
									}
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};

export default NoticeTable;
