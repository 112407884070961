/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, {
    useState, useRef, useEffect,
} from 'react';

import { pdfjs, Document, Page } from 'react-pdf';
import {
    animateScroll as scroll, Element
} from 'react-scroll';
import './pdfViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const NoticePDF = (props) => {
    const {
        image, onDocumentLoadSuccess, pageNumber, onClick,
    } = props;
    const [width, setWidth] = useState(200);
    const ref = useRef(null);
    const RESET_TIMEOUT = 1000;
    let movementTimer = null;

    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
        ref.current.onclick = () => {
            onClick();
        };
    }, [ref.current, width]);

    window.addEventListener('resize', () => {
        clearInterval(movementTimer);
        movementTimer = setTimeout(() => {
            setWidth(ref.current ? ref.current.offsetWidth : 0);
        }, RESET_TIMEOUT);
    });

    return (
        <div ref={ref}>
            <Document
                file={image}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {
                    Array.from({ length: pageNumber }, (v, k) => k + 1).map((page, index) => (
                        <Page
                            width={width}
                            pageNumber={page}
                            key={index}
                        />
                    ))
                }
            </Document>
        </div>
    );
};

const ShowScrollablePDF = ({ image, onClick, currentNotice }) => {
    const [pageNumber, setPageNumber] = useState(null);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(numPages);
    };

    return (
        <>
            <Element
                name="pdfscroller"
                className="element"
                id="containerElement"
                style={{
                    position: 'relative',
                    height: '750px',
                    overflow: 'scroll',
                }}
            >
                <NoticePDF image={image} onDocumentLoadSuccess={onDocumentLoadSuccess} pageNumber={pageNumber} onClick={onClick} />
            </Element>
        </>
    );
};

export default ShowScrollablePDF;
