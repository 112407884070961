import axios from 'axios';

export const axiosGet = async (url, options) => {
    try {
        const { data } = await axios.get(url, options);
        return data;
    } catch (e) {
        return Promise.reject(new Error(`Error fetching ${url}.`, e));
    }
};

export const axiosPost = async (url, body, options) => {
    try {
        const { data } = await axios.post(url, body, options);
        return data;
    } catch (e) {
        return Promise.reject(new Error(`Error post ${url}.`, e));
    }
};

export const axiosPut = async (url, body, options) => {
    try {
        const { data } = await axios.put(url, body, options);
        return data;
    } catch (e) {
        return Promise.reject(new Error(`Error put ${url}.`, e));
    }
};

export const axiosDelete = async (url, options) => {
    try {
        const { data } = await axios.delete(url, options);
        return data;
    } catch (e) {
        return Promise.reject(new Error(`Error delete ${url}.`, e));
    }
};
