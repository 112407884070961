import React from 'react';
import { Table } from 'antd';

const UsersTable = ({ columns, data }) => {
    return (
        <>
            <div className="pt-5">
                <Table dataSource={data} columns={columns} />
            </div>
        </>
    );
};

export default UsersTable;
